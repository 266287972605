import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ApiUrl,
  ApiKey,
  AlertTypes
} from "../../util/Constant";
import ApiEngine from '../../util/ApiEngine';
import {
  showMessage
} from "../../redux/AppAction";
import CustomSpinWheel from "./CustomSpinWheel";

const CustomSpinWheelContainer = () => {
  var _dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.authState);

  const [freeSpinCount, setFreeSpinCount] = useState(0);
  const [showFreeSpin, setShowFreeSpin] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      checkMemberHasFreeSpin();
    }
  }, [isLoggedIn]);


  const checkMemberHasFreeSpin = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_CHECK_FREE_SPIN_COUNT);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (data != null && data > 0) {
        setFreeSpinCount(data);
        setShowFreeSpin(true);
      }
      else {
        setFreeSpinCount(0);
        setShowFreeSpin(false);
      }
    }
    else {
      setFreeSpinCount(0);
      setShowFreeSpin(false);
    }
  }


  const memoizedComponents = useMemo(() => {
    return (
      <CustomSpinWheel freeSpinCount={freeSpinCount} setShowFreeSpin={setShowFreeSpin} checkMemberHasFreeSpin={checkMemberHasFreeSpin} />
    );
  }, [freeSpinCount])


  const miniMemoComponent = useMemo(() => {
    return (
      <div className="spin-container-open" onClick={() => checkMemberHasFreeSpin()}></div>
    );
  }, [freeSpinCount])

  const miniMemoComponent2 = useMemo(() => {
    return (
      <div className="spin-container-open" onClick={() => _dispatch(showMessage({
        type: AlertTypes._INFO, content: t("DEPOSIT_FOR_FREE_SPIN"),
        onConfirm: () => { },
      })
      )}></div>
    );
  }, [freeSpinCount])



  return (
    <>
      {isLoggedIn && showFreeSpin && memoizedComponents}
      {isLoggedIn && !showFreeSpin && freeSpinCount && miniMemoComponent}
      {isLoggedIn && !showFreeSpin && !freeSpinCount && miniMemoComponent2}
    </>
  )
}

export default CustomSpinWheelContainer;
