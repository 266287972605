import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import {
  setBusy,
  setIdle,
  showResponseMessage,
} from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import Header from "../../components/custom/Header";
import { useTranslation } from "react-i18next";
import { createMultiPartFormBody } from "../../util/Util";
import PlusIcon from "../../assets/img/icon/ionic-ios-add-circle-outline.svg";
import MinusIcon from "../../assets/img/icon/ionic-ios-minus-circle-outline.svg";
import BluePlusIcon from "../../assets/img/icon/Add_Icon.svg";
import BlueMinusIcon from "../../assets/img/icon/Minus_Icon.svg";
import GoldPlusIcon from "../../assets/img/icon/goldPlus.svg";
import GoldMinusIcon from "../../assets/img/icon/goldMinus.svg";

const DownlineProductRateDetail = (props) => {
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var _history = useHistory();
  var _dispatch = useDispatch();
  let _location = useLocation();
  let _userData = useSelector((state) => state.authState.userData);
  const selectedGroup = _location.state.id;
  const { handleSubmit, errors, setValue, control } = useForm();
  const [initCheck, setInitCheck] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [valueList, setValueList] = useState([]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    } finally {
      _dispatch(setIdle());
    }
  }

  useEffect(() => {
    products.map((product, index) => {
      setValue("bonus-" + product.productId, "-");
      setValue("commission-" + product.productId, "-");
      setValue("clubFee-" + product.productId, "-");
    });
    categories.map((category, index) => {
      setValue("category-bonus-" + category.id, "-");
      setValue("category-commission-" + category.id, "-");
      setValue("category-clubFee-" + category.id, "-");
    });
  }, [initCheck]);

  function checkValueForLowest(value) {
    if (value !== "-") {
      if (value === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (value !== "-") {
      if (bonusOrComm === "bonus") {
        if (value === valueList[category.originalTitle].highestMaxBonus) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "commission") {
        if (value === valueList[category.originalTitle].highestMaxComm) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "clubFee") {
        if (value === valueList[category.originalTitle].highestMaxClubFee) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  function handleCatBtn(bonusOrComm, categoryId, categoryTitle, value) {

    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          let tempBonusOrComm =
            bonusOrComm.substring(0, 1).toUpperCase() +
            bonusOrComm.substring(1, bonusOrComm.length);
          if (value > product["max" + tempBonusOrComm]) {
            setValue(
              bonusOrComm + "-" + product.productId,
              product["max" + tempBonusOrComm]
            );

            if (document.getElementById("product-" + product.productId) !== null) {
              document.getElementById("product-" + product.productId).style.opacity = "0.4";
              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          } 
          else if (value == product["max" + tempBonusOrComm]){
            setValue(bonusOrComm + "-" + product.productId, value);

            if (document.getElementById("product-" + product.productId) !== null) {
              document.getElementById("product-" + product.productId).style.opacity = "0.4";
              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          }
          else {
            setValue(bonusOrComm + "-" + product.productId, value);

            if (document.getElementById("product-" + product.productId) !== null && document.getElementById("category-" + categoryId) !== null) {
              document.getElementById("product-" + product.productId).style.opacity = "1";
              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
              document.getElementById("category-" + categoryId).style.opacity = "1";
            }
           }
        });
    } else {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
            setValue(bonusOrComm + "-" + product.productId, "-");
        });
    }
  }

  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {

    let tempHighestBonus = 0.0;
    let tempHighestComm = 0.0;
    let tempHighestMaxClubFee = 0.0;
    products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {

        if (document.getElementById("product-" + product.productId) !== null && document.getElementById("category-" + categoryId) !== null ) {
          document.getElementById("product-" + product.productId).style.opacity = "0.4";
          document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
          document.getElementById("category-" + categoryId).style.opacity = "0.4";
        }

        if (bonusOrComm === "bonus") {
          if (product.maxBonus > tempHighestBonus) {
            tempHighestBonus = product.maxBonus;
          }
          if (100.0 > product.maxBonus) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
            
          } else {
            setValue("bonus-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "commission") {
          if (product.maxCommission > tempHighestComm) {
            tempHighestComm = product.maxCommission;
          }
          if (100.0 > product.maxCommission) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "clubFee") {
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (100.0 > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, 100.0);
          }
        }
      });

    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, tempHighestBonus);
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, tempHighestComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, tempHighestMaxClubFee);
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {

      document.getElementById("product-" + productId).style.opacity = "0.4";
      document.getElementById("div-product-" + productId).getElementsByTagName("img")[0].style.opacity = "0.4";
  
      if (bonusOrComm === "bonus") {
        setValue("bonus-" + productId, maxBonus);
      } else if (bonusOrComm === "commission") {
        setValue("commission-" + productId, maxComm);
      } else if (bonusOrComm === "clubFee") {
        setValue("clubFee-" + productId, maxClubFee);
      }
  }

  function handleSelectValue(bonusOrComm, productId, value, maxValue) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      if(value == maxValue){
        document.getElementById("product-" + productId).style.opacity = "0.4";
        document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
      }
      else {
        document.getElementById("product-" + productId).style.opacity = "1";
        document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
      }
    }

    setValue(bonusOrComm + "-" + productId, value);
  }

  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          let tempBonusOrComm =
            bonusOrComm.substring(0, 1).toUpperCase() +
            bonusOrComm.substring(1, bonusOrComm.length);
          if (value > product["max" + tempBonusOrComm]) {
            setValue(
              bonusOrComm + "-" + product.productId,
              product["max" + tempBonusOrComm]
            );

            if (document.getElementById("product-" + product.productId) !== null) {
              document.getElementById("product-" + product.productId).style.opacity = "0.4";
              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          } 
          else if (value == product["max" + tempBonusOrComm]) {
            setValue(bonusOrComm + "-" + product.productId, value);
            if (document.getElementById("product-" + product.productId) !== null) {
              document.getElementById("product-" + product.productId).style.opacity = "0.4";
              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          }
          else {
            setValue(bonusOrComm + "-" + product.productId, value);
            if (document.getElementById("product-" + product.productId) !== null) {
              document.getElementById("product-" + product.productId).style.opacity = "1";
              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
            }
          }
        });
    } else {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          setValue(bonusOrComm + "-" + product.productId, "-");
        });
    }
    setValue("category-" + bonusOrComm + "-" + categoryId, value);
  }

  async function getMemberDetails() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_PRODUCT_RATES
    );
    let uplineProductRates = [];
    let processedProducts = [];
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    var productResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT
    );
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
        let uplineProduct = uplineProductRates.filter(
          (i) => i.accountProductId == product.accountProductId
        )[0];
        processedProducts.push({
          id: product.id,
          productId: product.productId,
          accountProductId: product.accountProductId,
          productName: product.productName + (product.useGroupRate ? " *" : ""),
          assigned: product.assigned,
          bonus: product.bonus,
          commission: product.commission,
          clubFee: product.clubFee,
          categoryName: product.categoryName,
          maxBonus: uplineProduct != null ? uplineProduct.bonus : 0,
          maxCommission: uplineProduct != null ? uplineProduct.commission : 0,
          maxClubFee: uplineProduct != null ? uplineProduct.clubFee : 0,
          useGroupRate: product.useGroupRate,
        });
      });
    } else {
      throw productResponseJson[ApiKey._API_MESSAGE_KEY];
    }

    setProduct(
      products["length"] == 0
        ? processedProducts[0]
        : processedProducts.find((x) => x["id"] == product["id"])
    );
    setProducts(processedProducts);

    let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      let categoryData = categoryJson[ApiKey._API_DATA_KEY];
      setCategories(categoryData);
      let tempObj = {};
      let tempHighestMaxBonus = 0.0;
      let tempHighestMaxComm = 0.0;
      let tempHighestMaxClubFee = 0.0;
      categoryData.map((category, index) => {
        if (!tempObj[category.originalTitle]) {
          tempObj[category.originalTitle] = {
            bonusValues: [],
            commValues: [],
            clubFeeValues: [],
            highestMaxBonus: 0,
            highestMaxComm: 0,
            highestMaxClubFee: 0,
          };
        }
        processedProducts
          .filter((x) => x.categoryName === category.originalTitle)
          .map((product, i) => {
            if (i === 0) {
              tempHighestMaxBonus = product.maxBonus;
              tempHighestMaxComm = product.maxCommission;
              tempHighestMaxClubFee = product.maxClubFee;
            }
            if (!tempObj[category.originalTitle][product.productName])
              tempObj[category.originalTitle][product.productName] = {
                bonusValues: [],
                commValues: [],
                clubFeeValues: [],
              };
            if (product.maxBonus > tempHighestMaxBonus) {
              tempHighestMaxBonus = product.maxBonus;
            }
            if (product.maxCommission > tempHighestMaxComm) {
              tempHighestMaxComm = product.maxCommission;
            }
            if (product.maxClubFee > tempHighestMaxClubFee) {
              tempHighestMaxClubFee = product.maxClubFee;
            }
            if (product.maxBonus > 0) {
              for (
                var i = 0.0;
                parseFloat(i.toFixed(2)) <=
                parseFloat(product.maxBonus.toFixed(2));
                i += 0.1
              ) {
                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));

                if (i === 0.0) {
                  tempObj[category.originalTitle][
                    product.productName
                  ].bonusValues.push("-");
                }
                tempObj[category.originalTitle][
                  product.productName
                ].bonusValues.push(i);
              }
            }
            if (product.maxCommission > 0) {
              for (
                var i = 0.0;
                parseFloat(i.toFixed(2)) <=
                parseFloat(product.maxCommission.toFixed(2));
                i += 0.1
              ) {
                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));

                if (i === 0.0) {
                  tempObj[category.originalTitle][
                    product.productName
                  ].commValues.push("-");
                }
                tempObj[category.originalTitle][
                  product.productName
                ].commValues.push(i);
              }
            }
            if (product.maxClubFee > 0) {
              for (
                var i = 0.0;
                parseFloat(i.toFixed(2)) <=
                parseFloat(product.maxClubFee.toFixed(2));
                i += 5.0
              ) {
                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));

                if (i === 0.0) {
                  tempObj[category.originalTitle][
                    product.productName
                  ].clubFeeValues.push("-");
                }
                tempObj[category.originalTitle][
                  product.productName
                ].clubFeeValues.push(i);
              }
            }
          });

        tempObj[category.originalTitle]["highestMaxBonus"] =
          tempHighestMaxBonus;
        tempObj[category.originalTitle]["highestMaxComm"] = tempHighestMaxComm;
        tempObj[category.originalTitle]["highestMaxClubFee"] =
          tempHighestMaxClubFee;
        for (
          var i = 0.0;
          parseFloat(i.toFixed(2)) <=
          parseFloat(tempHighestMaxBonus.toFixed(2));
          i += 0.1
        ) {
          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));

          if (i === 0.0) {
            tempObj[category.originalTitle].bonusValues.push("-");
          }
          tempObj[category.originalTitle].bonusValues.push(i);
        }
        for (
          var i = 0.0;
          parseFloat(i.toFixed(2)) <= parseFloat(tempHighestMaxComm.toFixed(2));
          i += 0.1
        ) {
          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));

          if (i === 0.0) {
            tempObj[category.originalTitle].commValues.push("-");
          }
          tempObj[category.originalTitle].commValues.push(i);
        }
        for (
          var i = 0.0;
          parseFloat(i.toFixed(2)) <=
          parseFloat(tempHighestMaxClubFee.toFixed(2));
          i += 5.0
        ) {
          i = i * 100;
          i = Math.round(i);
          i = i / 100;
          i = parseFloat(i.toFixed(2));

          if (i === 0.0) {
            tempObj[category.originalTitle].clubFeeValues.push("-");
          }
          tempObj[category.originalTitle].clubFeeValues.push(i);
        }
      });
      setValueList(tempObj);
    }
    setInitCheck(true);
  }

  async function onSaveGroupSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      groupId: selectedGroup,
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_DOWNLINE_PRODUCT_RATE,
      formBody
    );
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setInitCheck(true);
    }
    _dispatch(setIdle());
  }

  const EditDownlineForm = () => {
    if (initCheck) {
      return (
        <div
          className="downline-form-container" id="referral-detail-modern"
        >
          {products.length > 0 ? (
            <>
              <table className="edit-referral-table">
                <thead></thead>
                <tbody>
                  {categories.map((category, index) => {
                    if (
                      products.filter(
                        (x) =>
                          x.categoryName === category.originalTitle &&
                          (x.maxBonus > 0 ||
                            x.maxCommission > 0 ||
                            x.maxClubFee > 0)
                      ).length > 0
                    ) {
                      return (
                        <Fragment key={index}>
                          {products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxBonus > 0
                          ).length > 0 && (
                            <>
                               {index % 2 == 0 ? 
                              (
                                <div className="hr-right mt-3 mb-3"></div>
                              ):(
                                <div className="hr-left mt-3 mb-3"></div>
                              )}
                              <tr className="data-row category">
                                <td
                                  className="row-data-1"
                                  style={{ color: "#ffc159" }}
                                >
                                  <b>{category.title}</b>
                                </td>
                                <td className="row-data-2">
                                  <div className="txt-1">{t("BONUS")}</div>
                                  <div className="txt-2">
                                    {t("MAX") +
                                      ":" +
                                      valueList[
                                        category.originalTitle
                                      ].highestMaxBonus.toFixed(2) +
                                      "%"}
                                  </div>
                                </td>
                                <td className="row-data-3">
                                  <Controller
                                    control={control}
                                    name={"category-bonus-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "bonus"
                                        );
                                      return (
                                        <>
                                        <div className="add-minus-box">
                                          <div
                                            onClick={() => {
                                              if (value !== "-") {
                                                if (valueNotLowest === true) {
                                                  value -= 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                }
                                              } else {
                                                value = 0.0;
                                              }
                                              setValue(
                                                "category-bonus-" + category.id,
                                                value
                                              );
                                              handleCatBtn(
                                                "bonus",
                                                category.id,
                                                category.originalTitle,
                                                value
                                              );
                                            }}
                                          >
                                            <img src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                          </div>
                                          <select
                                            className="bonusComm-option-select"
                                            value={value}
                                            placeholder={value}
                                            onChange={(e) => {
                                              handleSelectCatValue(
                                                "bonus",
                                                category.id,
                                                category.originalTitle,
                                                e.target.value
                                              );
                                            }}
                                          >
                                            {valueList[
                                              category.originalTitle
                                            ].bonusValues.map(
                                              (bonusValue, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    className="bonusComm-option-value"
                                                    style={{ textAlign: "center" }}
                                                    value={
                                                      bonusValue !== "-"
                                                        ? parseFloat(
                                                            bonusValue.toFixed(
                                                              2
                                                            )
                                                          )
                                                        : "-"
                                                    }
                                                  >
                                                    {bonusValue !== "-"
                                                      ? bonusValue.toFixed(2)
                                                      : "-"}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                          <div
                                            onClick={() => {
                                              if (value !== "-") {
                                                if (valueNotHighest === true) {
                                                  value += 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                }
                                              } else {
                                                value = 0.0;
                                              }
                                              setValue(
                                                "category-bonus-" + category.id,
                                                value
                                              );
                                              handleCatBtn(
                                                "bonus",
                                                category.id,
                                                category.originalTitle,
                                                value
                                              );
                                            }}
                                          >
                                            <img src={require("../../assets/img/v3/add.png")} alt="add" />
                                          </div>
                                        </div>
                                          {errors.editPercent && (
                                            <div className="invalid-feedback">
                                              {t(errors.editPercent.message)}
                                            </div>
                                          )}
                                        </>
                                      );
                                    }}
                                  />
                                </td>
                                <td className="row-data-4">
                                  <div
                                    id={"category-" + category.id}
                                    className="row-data-max"
                                    onClick={() =>
                                      handleMaxCatBtn(
                                        category.id,
                                        category.originalTitle,
                                        "bonus"
                                      )
                                    }
                                  >
                                    {t("MAX")}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                           {index % 2 == 0 ? 
                        (
                          <div className="hr-right mt-3 mb-3"></div>
                        ):(
                          <div className="hr-left mt-3 mb-3"></div>
                        )}
                          {products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxCommission > 0
                          ).length > 0 && (
                            <tr
                              className="data-row category"
                              style={{
                                marginTop:
                                  products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle && x.maxBonus > 0
                                  ).length > 0
                                    ? "0"
                                    : "25px",
                              }}
                            >
                              <td
                                className="row-data-1"
                              >
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxBonus > 0
                                ).length > 0 ? (
                                  ""
                                ) : (
                                  <b>{category.title}</b>
                                )}
                              </td>
                              <td className="row-data-2">
                                <div className="txt-1">
                                  {t("COMMISSION_SHORT")}
                                </div>
                                <div className="txt-2">
                                  {t("MAX") +
                                    ":" +
                                    valueList[
                                      category.originalTitle
                                    ].highestMaxComm.toFixed(2) +
                                    "%"}
                                </div>
                              </td>
                              <td className="row-data-3">
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxCommission > 0
                                ).length > 0 && (
                                  <>
                                    <Controller
                                      control={control}
                                      name={
                                        "category-commission-" + category.id
                                      }
                                      defaultValue={0}
                                      render={({ onChange, value }) => {
                                        let valueNotLowest =
                                          checkValueForLowest(value);
                                        let valueNotHighest =
                                          checkValueForHighest(
                                            value,
                                            category,
                                            "commission"
                                          );

                                        if(document.getElementById("category-" + category.id) !== null) {
                                          if (!valueNotHighest) {
                                            document.getElementById("category-" + category.id).style.opacity = "0.4";
                                          }
                                          else {
                                            document.getElementById("category-" + category.id).style.opacity = "1";
                                          }
                                        }

                                        return (
                                          <>
                                          <div className="add-minus-box">
                                            <div
                                              onClick={() => {
                                                if (valueNotLowest === true) {
                                                  if (value !== '-') {
                                                    value -= 0.10;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  }
                                                  else {
                                                    value = 0.00;
                                                  }
                                                  setValue(
                                                    "category-commission-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "commission",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                            >
                                              <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                            </div>
                                            <select
                                              className="bonusComm-option-select"
                                              value={value}
                                              placeholder={value}
                                              onChange={(e) => {
                                                handleSelectCatValue(
                                                  "commission",
                                                  category.id,
                                                  category.originalTitle,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {valueList[
                                                category.originalTitle
                                              ].commValues.map(
                                                (commValue, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      className="bonusComm-option-value"
                                                      style={{ textAlign: "center" }}
                                                      value={
                                                        commValue !== "-"
                                                          ? parseFloat(
                                                              commValue.toFixed(
                                                                2
                                                              )
                                                            )
                                                          : "-"
                                                      }
                                                    >
                                                      {commValue !== "-"
                                                        ? commValue.toFixed(2)
                                                        : "-"}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                            <div
                                              onClick={(e) => {
                                                if (valueNotHighest === true) {
                                                  if (value !== '-') {
                                                    value += 0.1;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  }
                                                  else {
                                                    value = 0.00;
                                                  }
                                                  setValue(
                                                    "category-commission-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "commission",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }

                                                // if (value !== "-") {
                                                //   if (
                                                //     valueNotHighest === true
                                                //   ) {
                                                //     value += 0.1;
                                                //     value *= 100;
                                                //     value = Math.round(value);
                                                //     value /= 100;
                                                //   }
                                                // } else {
                                                //   value = 0.0;
                                                // }
                                                // setValue(
                                                //   "category-commission-" +
                                                //     category.id,
                                                //   parseFloat(value.toFixed(2))
                                                // );
                                                // handleCatBtn(
                                                //   "commission",
                                                //   category.originalTitle,
                                                //   value
                                                // );
                                              }}
                                            >
                                               <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                            </div>
                                            </div>
                                            {errors.editPercent && (
                                              <div className="invalid-feedback">
                                                {t(errors.editPercent.message)}
                                              </div>
                                            )}
                                          </>
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </td>
                              <td className="row-data-4">
                                <div
                                  id={"category-" + category.id}
                                  className="row-data-max"
                                  onClick={() =>
                                    handleMaxCatBtn(
                                      category.id,
                                      category.originalTitle,
                                      "commission"
                                    )
                                  }
                                >
                                  {t("MAX")}
                                </div>
                              </td>
                            </tr>
                          )}
                          {category.originalTitle === "Poker" &&
                            products.filter(
                              (x) =>
                                x.categoryName === category.originalTitle &&
                                x.maxClubFee > 0
                            ).length > 0 && (
                              <tr
                                className="data-row category"
                                style={{
                                  marginTop:
                                    products.filter(
                                      (x) =>
                                        x.categoryName ===
                                          category.originalTitle &&
                                        x.maxCommission > 0
                                    ).length > 0
                                      ? "0"
                                      : "25px",
                                }}
                              >
                                <td
                                  className="row-data-1"
                                >
                                  {products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxCommission > 0
                                  ).length > 0 ? (
                                    ""
                                  ) : (
                                    <b>{category.title}</b>
                                  )}
                                </td>
                                <td className="row-data-2">
                                  <div className="txt-1">{t("CLUB_FEE")}</div>
                                  <div className="txt-2">
                                    {t("MAX") +
                                      ":" +
                                      valueList[
                                        category.originalTitle
                                      ].highestMaxClubFee.toFixed(2) +
                                      "%"}
                                  </div>
                                </td>
                                <td className="row-data-3">
                                  {products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxClubFee > 0
                                  ).length > 0 && (
                                    <>
                                      <Controller
                                        control={control}
                                        name={"category-clubFee-" + category.id}
                                        defaultValue={0}
                                        render={({ onChange, value }) => {
                                          let valueNotLowest =
                                            checkValueForLowest(value);
                                          let valueNotHighest =
                                            checkValueForHighest(
                                              value,
                                              category,
                                              "clubFee"
                                            );

                                            if(document.getElementById("category-" + category.id) !== null) {
                                              if (!valueNotHighest) {
                                                document.getElementById("category-" + category.id).style.opacity = "0.4";
                                              }
                                              else {
                                                document.getElementById("category-" + category.id).style.opacity = "1";
                                              }
                                            }
                                          return (
                                            <>
                                            <div className="add-minus-box">
                                              <div
                                                onClick={(e) => {
                                                  if (value !== "-") {
                                                    if (
                                                      valueNotLowest === true
                                                    ) {
                                                      value -= 5.0;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    }
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }}
                                              >
                                                 <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                              </div>
                                              <select
                                                className="bonusComm-option-select"
                                                value={value}
                                                placeholder={value}
                                                onChange={(e) => {
                                                  handleSelectCatValue(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                {valueList[
                                                  category.originalTitle
                                                ].clubFeeValues.map(
                                                  (clubFeeValue, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        className="bonusComm-option-value"
                                                        style={{ textAlign: "center" }}
                                                        value={
                                                          clubFeeValue !== "-"
                                                            ? parseFloat(
                                                                clubFeeValue.toFixed(
                                                                  2
                                                                )
                                                              )
                                                            : "-"
                                                        }
                                                      >
                                                        {clubFeeValue !== "-"
                                                          ? clubFeeValue.toFixed(
                                                              2
                                                            )
                                                          : "-"}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <div
                                                onClick={(e) => {
                                                  if (value !== "-") {
                                                    if (
                                                      valueNotHighest === true
                                                    ) {
                                                      value += 5.0;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    }
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }}
                                              >
                                                <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                              </div>
                                              </div>
                                              {errors.editPercent && (
                                                <div className="invalid-feedback">
                                                  {t(
                                                    errors.editPercent.message
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </td>
                                <td className="row-data-4">
                                  <div
                                    id={"category-" + category.id}
                                    className="row-data-max"
                                    onClick={() =>
                                      handleMaxCatBtn(
                                        category.id,
                                        category.originalTitle,
                                        "clubFee"
                                      )
                                    }
                                  >
                                    {t("MAX")}
                                  </div>
                                </td>
                              </tr>
                            )}

                          {products.filter(
                            (x) => x.categoryName === category.originalTitle
                          ).length > 0 &&
                            products
                              .filter(
                                (product) =>
                                  product.categoryName ===
                                    category.originalTitle &&
                                  (product.maxBonus > 0 ||
                                    product.maxCommission > 0 ||
                                    product.maxClubFee > 0)
                              )
                              .map((product, i) => {
                                return (
                                  <Fragment key={i}>
                                    {product.maxBonus > 0 && (
                                      <tr
                                        className={
                                          "data-row" +
                                          ((i + 1) % 2 !== 0
                                            ? " bg-color-1"
                                            : "")
                                        }
                                      >
                                        <td className="row-data-1">
                                          <div className="row-data-title">
                                            {product.productName}
                                          </div>
                                        </td>
                                        <td className="row-data-2">
                                          <div className="txt-1">
                                            {t("BONUS")}
                                          </div>
                                          <div className="txt-2">
                                            {t("MAX") +
                                              ":" +
                                              product.maxBonus.toFixed(2) +
                                              "%"}
                                          </div>
                                        </td>
                                        <td className="row-data-3">
                                          <Controller
                                            control={control}
                                            name={"bonus-" + product.productId}
                                            defaultValue={0}
                                            render={({ onChange, value }) => {
                                              return (
                                                <>
                                                  <div className="add-minus-box">
                                                  <div
                                                    onClick={() => {
                                                      if (value !== "-") {
                                                        if (
                                                          product.maxBonus >
                                                            0 &&
                                                          parseFloat(
                                                            value.toFixed(2)
                                                          ) > 0
                                                        ) {
                                                          value -= 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                        }
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }}
                                                  >
                                                    <img src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                  </div>
                                                  <select
                                                    className="bonusComm-option-select"
                                                    value={value}
                                                    placeholder={value}
                                                    onChange={(e) => {
                                                      handleSelectValue(
                                                        "bonus",
                                                        product.productId,
                                                        e.target.value,
                                                        product.maxBonus
                                                      );
                                                    }}
                                                  >
                                                    {valueList[
                                                      category.originalTitle
                                                    ][
                                                      product.productName
                                                    ].bonusValues.map(
                                                      (bonusValue, index) => {
                                                        return (
                                                          <option
                                                            key={index}
                                                            className="bonusComm-option-value"
                                                            style={{ textAlign: "center" }}
                                                            value={
                                                              bonusValue !== "-"
                                                                ? parseFloat(
                                                                    bonusValue.toFixed(
                                                                      2
                                                                    )
                                                                  )
                                                                : "-"
                                                            }
                                                          >
                                                            {bonusValue !== "-"
                                                              ? bonusValue.toFixed(
                                                                  2
                                                                )
                                                              : "-"}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </select>
                                                  <div
                                                    onClick={() => {
                                                      if (value !== "-") {
                                                        if (
                                                          product.maxBonus >
                                                            0 &&
                                                          parseFloat(
                                                            value.toFixed(2)
                                                          ) < product.maxBonus
                                                        ) {
                                                          value += 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                        }
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }}
                                                  >
                                                    <img src={require("../../assets/img/v3/add.png")} alt="add" />
                                                  </div>
                                                  </div>
                                                  {errors.editPercent && (
                                                    <div className="invalid-feedback">
                                                      {t(
                                                        errors.editPercent
                                                          .message
                                                      )}
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                        </td>
                                        <td className="row-data-4">
                                          <div
                                            className="row-data-max"
                                            onClick={() =>
                                              handleMaxBtn(
                                                product.productId,
                                                product.maxBonus,
                                                product.maxCommission,
                                                product.maxClubFee,
                                                "bonus"
                                              )
                                            }
                                          >
                                            {t("MAX")}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {product.maxCommission > 0 && (
                                      <tr
                                        className={
                                          "data-row" +
                                          ((i + 1) % 2 !== 0
                                            ? (product.maxBonus > 0
                                                ? product.maxClubFee > 0
                                                  ? " bg-color-2"
                                                  : " bg-color-3"
                                                : product.maxClubFee > 0
                                                ? " bg-color-1"
                                                : "")
                                            : "")
                                        }
                                      >
                                        <td className="row-data-1">
                                          {product.maxBonus > 0 ? (
                                            ""
                                          ) : (
                                            <div className="row-data-title">
                                              {product.productName}
                                            </div>
                                          )}
                                        </td>
                                        <td className="row-data-2">
                                          <div className="txt-1">
                                            {t("COMMISSION_SHORT")}
                                          </div>
                                          <div className="txt-2">
                                            {t("MAX") +
                                              ":" +
                                              product.maxCommission.toFixed(2) +
                                              "%"}
                                          </div>
                                        </td>
                                        <td className="row-data-3">
                                          {product.maxCommission > 0 && (
                                            <>
                                              <Controller
                                                control={control}
                                                name={
                                                  "commission-" +
                                                  product.productId
                                                }
                                                defaultValue={0}
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => {

                                                  let valueNotLowest = checkValueForLowest(value);
                                                  let valueNotHighest = checkValueForHighest(value, category, 'commission');

                                                  return (
                                                    <>
                                                    <div className="add-minus-box">
                                                      <div
                                                        onClick={() => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxCommission >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) > 0
                                                            ) {
                                                              value -= 0.1;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;
                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }

                                                            if(value == product.maxCommission) {
                                                              document.getElementById("product-" + product.productId).style.opacity = "0.4";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
                                                            }
                                                            else {
                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }
                                                          } else{
                                                            value = 0.0;
                                                            document.getElementById("product-" + product.productId).style.opacity = "1";
                                                            document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                          }

                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                      >
                                                        <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                      </div>
                                                      <select
                                                        className="bonusComm-option-select"
                                                        value={value}
                                                        placeholder={value}
                                                        onChange={(e) => {
                                                          handleSelectValue(
                                                            "commission",
                                                            product.productId,
                                                            e.target.value,
                                                            product.maxCommission
                                                          );
                                                        }}
                                                      >
                                                        {valueList[
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ].commValues.map(
                                                          (
                                                            commValue,
                                                            index
                                                          ) => {
                                                            return (
                                                              <option
                                                                key={index}
                                                                className="bonusComm-option-value"
                                                                style={{ textAlign: "center" }}
                                                                value={
                                                                  commValue !==
                                                                  "-"
                                                                    ? parseFloat(
                                                                        commValue.toFixed(
                                                                          2
                                                                        )
                                                                      )
                                                                    : "-"
                                                                }
                                                              >
                                                                {commValue !==
                                                                "-"
                                                                  ? commValue.toFixed(
                                                                      2
                                                                    )
                                                                  : "-"}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                      <div
                                                        id={"div-product-" + product.productId}
                                                        onClick={() => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxCommission >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) <
                                                                product.maxCommission
                                                            ) {
                                                              value += 0.1;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;

                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }

                                                            if(value == product.maxCommission) {
                                                              document.getElementById("product-" + product.productId).style.opacity = "0.4";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
                                                            }
                                                            else {
                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }
                                                          } else {
                                                              value = 0.0;
                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }
                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }
                                                      }
                                                      >
                                                        <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                                      </div>
                                                      </div>
                                                    </>
                                                  );
                                                }}
                                              />
                                            </>
                                          )}
                                        </td>
                                        <td className="row-data-4">
                                          <div
                                            className="row-data-max"
                                            id={'product-' + product.productId}
                                            onClick={() =>
                                              handleMaxBtn(
                                                product.productId,
                                                product.maxBonus,
                                                product.maxCommission,
                                                product.maxClubFee,
                                                "commission"
                                              )
                                            }
                                          >
                                            {t("MAX")}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {product.productName === "D2 Poker" &&
                                      product.maxClubFee > 0 && (
                                        <tr
                                          className={
                                            "data-row" +
                                            ((i + 1) % 2 !== 0
                                              ? (product.maxClubFee > 0 &&
                                                (product.maxBonus > 0 ||
                                                  product.maxCommission > 0)
                                                  ? " bg-color-3"
                                                  : "")
                                              : "")
                                          }
                                        >
                                          <td className="row-data-1">
                                            {product.maxCommission > 0 ? (
                                              ""
                                            ) : (
                                              <div className="row-data-title">
                                                {product.productName}
                                              </div>
                                            )}
                                          </td>
                                          <td className="row-data-2">
                                            <div className="txt-1">
                                              {t("CLUB_FEE")}
                                            </div>
                                            <div className="txt-2">
                                              {t("MAX") +
                                                ":" +
                                                product.maxClubFee.toFixed(2) +
                                                "%"}
                                            </div>
                                          </td>
                                          <td className="row-data-3">
                                            {product.maxClubFee > 0 && (
                                              <>
                                                <Controller
                                                  control={control}
                                                  name={
                                                    "clubFee-" +
                                                    product.productId
                                                  }
                                                  defaultValue={0}
                                                  render={({
                                                    onChange,
                                                    value,
                                                  }) => {

                                                    let valueNotLowest = checkValueForLowest(value);
                                                    let valueNotHighest = checkValueForHighest(value, category, 'clubFee');

                                                    return (
                                                      <>
                                                      <div className="add-minus-box">
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              product.maxClubFee >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) > 0
                                                            ) {
                                                              value -= 5.0;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;
                                                            } else {
                                                              value = 0.0;
                                                            }
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }}
                                                        >
                                                           <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                        </div>
                                                        <select
                                                          className="bonusComm-option-select"
                                                          value={value}
                                                          placeholder={value}
                                                          onChange={(e) => {
                                                            handleSelectValue(
                                                              "clubFee",
                                                              product.productId,
                                                              e.target.value,
                                                              product.maxClubFee
                                                            );
                                                          }}
                                                        >
                                                          {valueList[
                                                            category
                                                              .originalTitle
                                                          ][
                                                            product.productName
                                                          ].clubFeeValues.map(
                                                            (
                                                              clubFeeValue,
                                                              index
                                                            ) => {
                                                              return (
                                                                <option
                                                                  key={index}
                                                                  className="bonusComm-option-value"
                                                                  style={{ textAlign: "center" }}
                                                                  value={
                                                                    clubFeeValue !==
                                                                    "-"
                                                                      ? parseFloat(
                                                                          clubFeeValue.toFixed(
                                                                            2
                                                                          )
                                                                        )
                                                                      : "-"
                                                                  }
                                                                >
                                                                  {clubFeeValue !==
                                                                  "-"
                                                                    ? clubFeeValue.toFixed(
                                                                        2
                                                                      )
                                                                    : "-"}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                        </select>
                                                        <div
                                                          id={"div-product-" + product.productId}
                                                          onClick={() => {
                                                            if (value !== "-") {
                                                            if (
                                                              product.maxClubFee >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) <
                                                                product.maxClubFee
                                                            ) {
                                                              value += 5.0;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;

                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }
                                                            if(value == product.maxClubFee) {
                                                              document.getElementById("product-" + product.productId).style.opacity = "0.4";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
                                                            }
                                                            else {
                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                            }
                                                          }
                                                          else {
                                                            value = 0.0;
                                                              document.getElementById("product-" + product.productId).style.opacity = "1";
                                                              document.getElementById("div-product-" + product.productId).getElementsByTagName("img")[0].style.opacity = "1";
                                                          }
                                                            
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }}
                                                        >
                                                          <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                                        </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }}
                                                />
                                              </>
                                            )}
                                          </td>
                                          <td className="row-data-4">
                                            <div
                                              className="row-data-max"
                                              id={'product-' + product.productId}
                                              onClick={() =>
                                                handleMaxBtn(
                                                  product.productId,
                                                  product.maxBonus,
                                                  product.maxCommission,
                                                  product.maxClubFee,
                                                  "clubFee"
                                                )
                                              }
                                            >
                                              {t("MAX")}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                  </Fragment>
                                );
                              })}
                        </Fragment>
                      );
                    }
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="card">
              <div className="card-body text-center">
                {t("NO_ASSIGNED_PRODUCT")}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="downline-product-rate">
      <Header
        history={_history}
        url={WebUrl._URL_DOWNLINE}
        title={t("DOWNLINE")}
        className="mb-0"
      />
      <form
        className="margin-bottom-0 pb-5"
        onSubmit={handleSubmit(onSaveGroupSubmit)}
      >
        {initCheck && <>{EditDownlineForm()}</>}
        <button type="submit" className="btn custom-btn mt-4 w-100">
          {t("SAVE")}
        </button>
      </form>
    </div>
  );
};

export default DownlineProductRateDetail;
