import React, { Fragment, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { showResponseMessage, setBusy, setIdle } from '../../redux/AppAction';
import ApiEngine from '../../util/ApiEngine';
import { ApiUrl, ApiKey, WebUrl } from '../../util/Constant';
import { createFormBody, isObjectEmpty, stringIsNullOrEmpty } from '../../util/Util';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PlusIcon from '../../assets/img/icon/ionic-ios-add-circle-outline.svg';
import MinusIcon from '../../assets/img/icon/ionic-ios-minus-circle-outline.svg';
import BluePlusIcon from '../../assets/img/icon/Add_Icon.svg';
import BlueMinusIcon from '../../assets/img/icon/Minus_Icon.svg';
import GoldPlusIcon from '../../assets/img/icon/goldPlus.svg';
import GoldMinusIcon from '../../assets/img/icon/goldMinus.svg';
import Input from "../../components/custom/Input";
import Header from "../../components/custom/Header";
import { Modal, ModalBody } from "reactstrap";
import Keyboard from 'react-simple-keyboard';

const EditReferral = props => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const _location = useLocation();
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [referralCodeDetail, setReferralCodeDetail] = useState({});
  const [referralCodeId, setReferralCodeId] = useState();
  const [initCheck, setInitCheck] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showCategoryKeyboard, setShowCategoryKeyboard] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0000");
  const [currentIndexOfValue, setCurrentIndexOfValue] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState("");
  const [selectedBonusOrComm, setSelectedBonusOrComm] = useState("");
  const [selectedProductsIndex, setSelectedProductsIndex] = useState(0);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [valueList, setValueList] = useState([]);
  const [deviceType, setDeviceType] = useState("");

  const _userData = useSelector(state => state.authState.userData);
  const keyboard = useRef();

  const { register, handleSubmit, errors, setValue, getValues, control, watch } = useForm();

  const backgroundLightMaxButton = "linear-gradient(0deg, rgba(51, 105, 255, 0.5), rgba(51, 105, 255, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)";
  const backgroundDarkMaxButton = "linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)";

  useEffect(() => {
    init();
  }, [_location.state]);

  useEffect(() => {
    setDeviceType(window.navigator.platform);
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!_location.state) {
      products.map((product, index) => {
        setValue("bonus-" + product.productId, 0.0);
        setValue("commission-" + product.productId, 0.0);
        setValue("clubFee-" + product.productId, 0.0);
      });
      categories.map((category, index) => {
        setValue("category-bonus-" + category.id, 0.0);
        setValue("category-commission-" + category.id, 0.0);
        setValue("category-clubFee-" + category.id, 0.0);
      })
    }
    else {
      if (!isObjectEmpty(referralCodeDetail)) {
        referralCodeDetail.memberReferralInfos.map((info) => {
          setValue('bonus-' + info.productId, info.bonus);
          setValue('commission-' + info.productId, info.commission);
          setValue('clubFee-' + info.productId, info.clubFee);

          // if(info.bonus == info.maxBonus || info.commission == info.maxCommission || info.clubFee == info.maxClubFee) {
          //   if(document.getElementById("product-" + info.productId) !== null) {
          //     document.getElementById("product-" + info.productId).style.opacity = "0.4";
          //     document.getElementById("div-product-" + info.productId).getElementsByTagName("img")[0].style.opacity = "0.4";
          //   }
          // }
        })
      }
    }
  }, [initCheck]);

  /// <summary>
  /// Author: -
  /// </summary>
  async function getCodeDetail() {
    let apiUrl = ApiUrl._API_GET_MEMBER_REFERRAL_CODES;

    if (!stringIsNullOrEmpty(_location.state.id)) {
      apiUrl += "?id=" + _location.state.id;
    }
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY].length > 0) {
      data = responseJson[ApiKey._API_DATA_KEY][0];
      setReferralCodeDetail(data);
      data.memberReferralInfos.map((info) => {
        setValue('bonus-' + info.productId, info.bonus);
        setValue('commission-' + info.productId, info.commission);
        setValue('clubFee-' + info.productId, info.clubFee);
      });

      setExpiryDate(moment(data["expiryDate"]).format('YYYY-MM-DD'))
    }
    return data;
  }

  async function init() {
    let tempReferralCodeDetail = [];
    if (_location.state) {
      setReferralCodeId(_location.state.id);
      tempReferralCodeDetail = await getCodeDetail();

      let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_WITH_MAX_RATE);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let productData = responseJson[ApiKey._API_DATA_KEY];
        setProducts(productData);

        responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let categoryData = responseJson[ApiKey._API_DATA_KEY];
          setCategories(categoryData);
          setActiveTab(categoryData[0]["id"]);
          let tempObj = {};
          let tempHighestMaxBonus = 0.00;
          let tempHighestMaxComm = 0.00;
          let tempHighestMaxClubFee = 0.00;
          let tempAvailableCategories = [];
          categoryData.map((category, index) => {
            //if (tempReferralCodeDetail.length > 0 && tempReferralCodeDetail.memberReferralInfos.filter(x => x.productCategory === category.originalTitle && (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)).length > 0 || category.originalTitle === "All") {
            //  tempAvailableCategories.push(category);
            //}
            if (!tempObj[category.originalTitle]) {
              tempObj[category.originalTitle] = { bonusValues: [], commValues: [], clubFeeValues: [], highestMaxBonus: 0, highestMaxComm: 0, highestMaxClubFee: 0 };
            }
            productData.filter(x => x.categoryName === category.originalTitle).map((product, i) => {
              if (i === 0) { tempHighestMaxBonus = product.maxBonus; tempHighestMaxComm = product.maxCommission; tempHighestMaxClubFee = product.maxClubFee; }
              if (!tempObj[category.originalTitle][product.productName])
                tempObj[category.originalTitle][product.productName] = { bonusValues: [], commValues: [], clubFeeValues: [], };
              if (product.maxBonus > tempHighestMaxBonus) {
                tempHighestMaxBonus = product.maxBonus;
              }
              if (product.maxCommission > tempHighestMaxComm) {
                tempHighestMaxComm = product.maxCommission;
              }
              if (product.maxClubFee > tempHighestMaxClubFee) {
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (product.maxBonus > 0) {
                for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxBonus.toFixed(2)); i += 0.1) {

                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));
                  if (i === 0.00) {
                    tempObj[category.originalTitle][product.productName].bonusValues.push('-');
                  }
                  tempObj[category.originalTitle][product.productName].bonusValues.push(i);
                };
              }
              if (product.maxCommission > 0) {
                for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxCommission.toFixed(2)); i += 0.1) {

                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));
                  if (i === 0.00) {
                    tempObj[category.originalTitle][product.productName].commValues.push('-');
                  }
                  tempObj[category.originalTitle][product.productName].commValues.push(i);
                };
              }
              if (product.maxClubFee > 0) {
                for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxClubFee.toFixed(2)); i += 5.0) {

                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));
                  if (i === 0.00) {
                    tempObj[category.originalTitle][product.productName].clubFeeValues.push('-');
                  }
                  tempObj[category.originalTitle][product.productName].clubFeeValues.push(i);
                };
              }
            });

            tempObj[category.originalTitle]['highestMaxBonus'] = tempHighestMaxBonus;
            tempObj[category.originalTitle]['highestMaxComm'] = tempHighestMaxComm;
            tempObj[category.originalTitle]['highestMaxClubFee'] = tempHighestMaxClubFee;

            for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxBonus.toFixed(2))); i += 0.1) {

              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.00) {
                tempObj[category.originalTitle].bonusValues.push('-');
              }
              tempObj[category.originalTitle].bonusValues.push(i);
            };
            for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxComm.toFixed(2))); i += 0.1) {

              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.00) {
                tempObj[category.originalTitle].commValues.push('-');
              }
              tempObj[category.originalTitle].commValues.push(i);
            };
            for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxClubFee.toFixed(2))); i += 5.0) {

              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.00) {
                tempObj[category.originalTitle].clubFeeValues.push('-');
              }
              tempObj[category.originalTitle].clubFeeValues.push(i);
            };
          })
          setValueList(tempObj);
          //setCategories(tempAvailableCategories);
        }
      }
    } else {
      let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRODUCT_RATES);
      let uplineProductRates = [];
      let processedProducts = [];
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
      }
      else {
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
      }

      var productResponseJson = await ApiEngine.get(ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT);
      if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
        productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
          let uplineProduct = uplineProductRates.filter(i => i.accountProductId == product.accountProductId)[0];
          processedProducts.push({
            id: product.id,
            productId: product.productId,
            accountProductId: product.accountProductId,
            productName: product.productName + (product.useGroupRate ? " *" : ""),
            assigned: product.assigned,
            bonus: product.bonus,
            commission: product.commission,
            clubFee: product.clubFee,
            categoryName: product.categoryName,
            maxBonus: (uplineProduct != null) ? uplineProduct.bonus : 0,
            maxCommission: (uplineProduct != null) ? uplineProduct.commission : 0,
            maxClubFee: (uplineProduct != null) ? uplineProduct.clubFee : 0,
            useGroupRate: product.useGroupRate
          });
        });
      }
      else {
        throw productResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProduct(products['length'] == 0 ? processedProducts[0] : processedProducts.find(x => x['id'] == product['id']));
      setProducts(processedProducts);

      let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
      if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
        let categoryData = categoryJson[ApiKey._API_DATA_KEY];
        setActiveTab(categoryData[0]["id"]);
        setCategories(categoryData);
        let tempObj = {};
        let tempHighestMaxBonus = 0.00;
        let tempHighestMaxComm = 0.00;
        let tempHighestMaxClubFee = 0.00;
        categoryData.map((category, index) => {
          if (!tempObj[category.originalTitle]) {
            tempObj[category.originalTitle] = { bonusValues: [], commValues: [], clubFeeValues: [], highestMaxBonus: 0, highestMaxComm: 0, highestMaxClubFee: 0 };
          }
          processedProducts.filter(x => x.categoryName === category.originalTitle).map((product, i) => {
            if (i === 0) { tempHighestMaxBonus = product.maxBonus; tempHighestMaxComm = product.maxCommission; tempHighestMaxClubFee = product.maxClubFee; }
            if (!tempObj[category.originalTitle][product.productName])
              tempObj[category.originalTitle][product.productName] = { bonusValues: [], commValues: [], clubFeeValues: [], };
            if (product.maxBonus > tempHighestMaxBonus) {
              tempHighestMaxBonus = product.maxBonus;
            }
            if (product.maxCommission > tempHighestMaxComm) {
              tempHighestMaxComm = product.maxCommission;
            }
            if (product.maxClubFee > tempHighestMaxClubFee) {
              tempHighestMaxClubFee = product.maxClubFee;
            }
            if (product.maxBonus > 0) {
              for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxBonus.toFixed(2)); i += 0.1) {

                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));
                if (i === 0.00) {
                  tempObj[category.originalTitle][product.productName].bonusValues.push('-');
                }
                tempObj[category.originalTitle][product.productName].bonusValues.push(i);
              };
            }
            if (product.maxCommission > 0) {
              for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxCommission.toFixed(2)); i += 0.1) {

                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));

                if (i === 0.00) {
                  tempObj[category.originalTitle][product.productName].commValues.push('-');
                }
                tempObj[category.originalTitle][product.productName].commValues.push(i);
              };
            }
            if (product.maxClubFee > 0) {
              for (var i = 0.00; parseFloat(i.toFixed(2)) <= parseFloat(product.maxClubFee.toFixed(2)); i += 5.0) {

                i = i * 100;
                i = Math.round(i);
                i = i / 100;
                i = parseFloat(i.toFixed(2));

                if (i === 0.00) {
                  tempObj[category.originalTitle][product.productName].clubFeeValues.push('-');
                }
                tempObj[category.originalTitle][product.productName].clubFeeValues.push(i);
              };
            }
          });

          tempObj[category.originalTitle]['highestMaxBonus'] = tempHighestMaxBonus;
          tempObj[category.originalTitle]['highestMaxComm'] = tempHighestMaxComm;
          tempObj[category.originalTitle]['highestMaxClubFee'] = tempHighestMaxClubFee;
          for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxBonus.toFixed(2))); i += 0.1) {

            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            if (i === 0.00) {
              tempObj[category.originalTitle].bonusValues.push('-');
            }
            tempObj[category.originalTitle].bonusValues.push(i);
          };
          for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxComm.toFixed(2))); i += 0.1) {

            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            if (i === 0.00) {
              tempObj[category.originalTitle].commValues.push('-');
            }
            tempObj[category.originalTitle].commValues.push(i);
          };
          for (var i = 0.00; parseFloat(i.toFixed(2)) <= (parseFloat(tempHighestMaxClubFee.toFixed(2))); i += 5.0) {

            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            if (i === 0.00) {
              tempObj[category.originalTitle].clubFeeValues.push('-');
            }
            tempObj[category.originalTitle].clubFeeValues.push(i);
          };
        })
        setValueList(tempObj);
      }
    }

    setInitCheck(true);
  }

  const submitForm = async (data) => {
    _dispatch(setBusy());
    let params = {
    };

    if (!stringIsNullOrEmpty(referralCodeId)) {
      params["id"] = referralCodeId;
    }

    Object.keys(data).map((key, value) => {
      if (data[key] !== '-') {
        params[key] = data[key];
      }
      else {
        params[key] = 0.00;
      }
    });

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(ApiUrl._API_GENERATE_MEMBER_REFERRAL_CODE, formBody);

    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setModalVisible(false);

      /// used to show the latest detail after created and edit
      if (isObjectEmpty(referralCodeDetail)) {
        _history.replace({
          pathname: _location.pathname,
          state: {
            id: responseJson[ApiKey._API_DATA_KEY]["id"]
          }
        })
      }
      else {
        init();
      }
    }
    _dispatch(setIdle());
  }

  function onEditReferralKeyPress(key) {
    let valueStr = selectedValue;
    let tempCurrentIndex = currentIndexOfValue;
    let tempSlicedValueStr = "";
    let tempValue = 0;
    if (valueStr === "0000") {
      tempCurrentIndex = valueStr.length - 1;
      setCurrentIndexOfValue(tempCurrentIndex);
    }
    else {
      tempSlicedValueStr = valueStr.substring(tempCurrentIndex + 1, valueStr.length);
    }
    if (key !== "{bksp}" && key !== "{enter}") {
      if (tempCurrentIndex > 0) {
        valueStr = valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        setCurrentIndexOfValue(tempCurrentIndex - 1);
        setSelectedValue(valueStr);
      }
      else {
        valueStr = valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        if (valueStr.charAt(valueStr.length - 1) !== "0") {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
            valueStr = valueStr.substring(0, 3) + "0";
          }
          else {
            if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
              if (valueStr === "9999") {
                valueStr = "10000";
              }
              else {
                valueStr = '' + (parseInt(valueStr) + 1);
              }
            }
            else {
              valueStr = valueStr.substring(0, 2) + (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) + "0";
            }
          }
        }
        setSelectedValue(valueStr);
        if (valueStr.length === 5) {
          tempValue = parseFloat(valueStr.substring(0, 3) + "." + valueStr.substring(3, valueStr.length));
        }
        else {
          tempValue = parseFloat(valueStr.substring(0, 2) + "." + valueStr.substring(2, valueStr.length));
        }
        if (selectedBonusOrComm === "bonus") {
          if (tempValue > products[selectedProductsIndex]["maxBonus"]) {
            tempValue = products[selectedProductsIndex]["maxBonus"];
          }
        }
        else if (selectedBonusOrComm === "commission") {
          if (tempValue > products[selectedProductsIndex]["maxCommission"]) {
            tempValue = products[selectedProductsIndex]["maxCommission"];
          }
        }
        else if (selectedBonusOrComm === "clubFee") {
          if (tempValue > products[selectedProductsIndex]["maxClubFee"]) {
            tempValue = products[selectedProductsIndex]["maxClubFee"];
          }
        }
        else {
          //error
        }
        setValue('' + selectedBonusOrComm + '-' + selectedProductId, tempValue);
        setTimeout(() => setShowKeyboard(false), 500);
        setTimeout(() => setSelectedValue("0000"), 600);
      }
    }
    else if (key === "{enter}") {
      if (valueStr.charAt(valueStr.length - 1) !== "0") {
        if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
          valueStr = valueStr.substring(0, 3) + "0";
        }
        else {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
            if (valueStr === "9999") {
              valueStr = "10000";
            }
            else {
              valueStr = '' + (parseInt(valueStr) + 1);
            }
          }
          else {
            valueStr = valueStr.substring(0, 2) + (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) + "0";
          }
        }
      }
      if (valueStr.length === 5) {
        tempValue = parseFloat(valueStr.substring(0, 3) + "." + valueStr.substring(3, valueStr.length));
      }
      else {
        tempValue = parseFloat(valueStr.substring(0, 2) + "." + valueStr.substring(2, valueStr.length));
      }
      if (selectedBonusOrComm === "bonus") {
        if (tempValue > products[selectedProductsIndex]["maxBonus"]) {
          tempValue = products[selectedProductsIndex]["maxBonus"];
        }
      }
      else if (selectedBonusOrComm === "commission") {
        if (tempValue > products[selectedProductsIndex]["maxCommission"]) {
          tempValue = products[selectedProductsIndex]["maxCommission"];
        }
      }
      else if (selectedBonusOrComm === "clubFee") {
        if (tempValue > products[selectedProductsIndex]["maxClubFee"]) {
          tempValue = products[selectedProductsIndex]["maxClubFee"];
        }
      }
      else {
        //error
      }
      setValue('' + selectedBonusOrComm + '-' + selectedProductId, tempValue);
      setTimeout(() => setShowKeyboard(false), 500);
      setTimeout(() => setSelectedValue("0000"), 600);
    }
    else {
      setSelectedValue("0000");
    }
  }

  function onCategoryKeyPress(key) {
    let valueStr = selectedValue;
    let tempCurrentIndex = currentIndexOfValue;
    let tempSlicedValueStr = "";
    let tempValue = 0;
    if (valueStr === "0000") {
      tempCurrentIndex = valueStr.length - 1;
      setCurrentIndexOfValue(tempCurrentIndex);
    }
    else {
      tempSlicedValueStr = valueStr.substring(tempCurrentIndex + 1, valueStr.length);
    }
    if (key !== "{bksp}" && key !== "{enter}") {
      if (tempCurrentIndex > 0) {
        valueStr = valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        setCurrentIndexOfValue(tempCurrentIndex - 1);
        setSelectedValue(valueStr);
      }
      else {
        valueStr = valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        if (valueStr.charAt(valueStr.length - 1) !== "0") {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
            valueStr = valueStr.substring(0, 3) + "0";
          }
          else {
            if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
              if (valueStr === "9999") {
                valueStr = "10000";
              }
              else {
                valueStr = '' + (parseInt(valueStr) + 1);
              }
            }
            else {
              valueStr = valueStr.substring(0, 2) + (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) + "0";
            }
          }
        }
        setSelectedValue(valueStr);
        if (valueStr.length === 5) {
          tempValue = parseFloat(valueStr.substring(0, 3) + "." + valueStr.substring(3, valueStr.length));
        }
        else {
          tempValue = parseFloat(valueStr.substring(0, 2) + "." + valueStr.substring(2, valueStr.length));
        }
        if (selectedBonusOrComm === "bonus") {
          products.filter(x => x.categoryName === selectedCategoryTitle).map((product, index) => {
            if (tempValue > product["maxBonus"]) {
              setValue('bonus-' + product.productId, product["maxBonus"]);
            }
            else {
              setValue('bonus-' + product.productId, tempValue);
            }
          });
        }
        else if (selectedBonusOrComm === "commission") {
          products.filter(x => x.categoryName === selectedCategoryTitle).map((product, index) => {
            if (tempValue > product["maxCommission"]) {
              setValue('commission-' + product.productId, product["maxCommission"]);
            }
            else {
              setValue('commission-' + product.productId, tempValue);
            }
          });
        }
        else if (selectedBonusOrComm === "clubFee") {
          products.filter(x => x.categoryName === selectedCategoryTitle).map((product, index) => {
            if (tempValue > product["maxClubFee"]) {
              setValue('clubFee-' + product.productId, product["maxClubFee"]);
            }
            else {
              setValue('clubFee-' + product.productId, tempValue);
            }
          });
        }
        else {
          //error
        }
        setValue('category-' + selectedBonusOrComm + '-' + selectedCategoryId, tempValue);
        setTimeout(() => setShowCategoryKeyboard(false), 500);
        setTimeout(() => setSelectedValue("0000"), 600);
      }
    }
    else if (key === "{enter}") {
      if (valueStr.charAt(valueStr.length - 1) !== "0") {
        if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
          valueStr = valueStr.substring(0, 3) + "0";
        }
        else {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
            if (valueStr === "9999") {
              valueStr = "10000";
            }
            else {
              valueStr = '' + (parseInt(valueStr) + 1);
            }
          }
          else {
            valueStr = valueStr.substring(0, 2) + (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) + "0";
          }
        }
      }
      if (valueStr.length === 5) {
        tempValue = parseFloat(valueStr.substring(0, 3) + "." + valueStr.substring(3, valueStr.length));
      }
      else {
        tempValue = parseFloat(valueStr.substring(0, 2) + "." + valueStr.substring(2, valueStr.length));
      }
      if (selectedBonusOrComm === "bonus") {
        products.filter(x => x.categoryName === selectedCategoryTitle).map((product, index) => {
          if (tempValue > product["maxBonus"]) {
            setValue('bonus-' + product.productId, product["maxBonus"]);
          }
          else {
            setValue('bonus-' + product.productId, tempValue);
          }
        });
      }
      else if (selectedBonusOrComm === "commission") {

        products.filter(x => x.categoryName === selectedCategoryTitle).map((product, index) => {
          if (tempValue > product["maxCommission"]) {
            setValue('commission-' + product.productId, product["maxCommission"]);
          }
          else {
            setValue('commission-' + product.productId, tempValue);
          }
        });
      }
      else if (selectedBonusOrComm === "clubFee") {
        products.filter(x => x.categoryName === selectedCategoryTitle).map((product, index) => {
          if (tempValue > product["maxClubFee"]) {
            setValue('clubFee-' + product.productId, product["maxClubFee"]);
          }
          else {
            setValue('clubFee-' + product.productId, tempValue);
          }
        });
      }
      else {
        //error
      }
      setValue('category-' + selectedBonusOrComm + '-' + selectedCategoryId, tempValue);
      setTimeout(() => setShowCategoryKeyboard(false), 500);
      setTimeout(() => setSelectedValue("0000"), 600);
    }
    else {
      setSelectedValue("0000");
    }
  }

  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== '-') {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
        let tempBonusOrComm = bonusOrComm.substring(0, 1).toUpperCase() + bonusOrComm.substring(1, bonusOrComm.length);
        if (value > product["max" + tempBonusOrComm]) {
          setValue(bonusOrComm + '-' + product.productId, product["max" + tempBonusOrComm]);
          if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
            document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
            document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
          }
        }
        else if (value == product["max" + tempBonusOrComm]) {
          setValue(bonusOrComm + '-' + product.productId, value);
          if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
            document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
            document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
          }
        }
        else {
          setValue(bonusOrComm + '-' + product.productId, value);
          if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
            document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
            document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
          }
        }
      });
    }
    else {
      products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
        setValue(bonusOrComm + '-' + product.productId, '-');

        if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
          document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
          document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
        }

      });
    }
    setValue('category-' + bonusOrComm + '-' + categoryId, value);
  }

  function handleSelectValue(bonusOrComm, productId, value, maxValue) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      if (value == maxValue) {
        document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
      }
      else {
        document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
      }
    }

    setValue(bonusOrComm + "-" + productId, value);
  }

  //function setValueOptionList(productsIndex, bonusOrComm, productId) {
  //    setSelectedProductsIndex(productsIndex);
  //    setSelectedProductId(productId);
  //    setSelectedBonusOrComm(bonusOrComm);
  //}

  //function setCatValueOptionList(categoryIndex, bonusOrComm, categoryTitle, categoryId) {
  //    setSelectedCategoryIndex(categoryIndex);
  //    setSelectedCategoryTitle(categoryTitle);
  //    setSelectedCategoryId(categoryId);
  //    setSelectedBonusOrComm(bonusOrComm);

  //    let categoryProductList = products.filter(x => x.categoryName === categoryTitle);
  //    let tempList = [];
  //    categoryProductList.map((product, index) => {
  //        if (bonusOrComm === "bonus") {
  //            tempList.push(product.maxBonus);
  //        }
  //        else if (bonusOrComm === "commission") {
  //            tempList.push(product.maxCommission);
  //        }
  //    });

  //    let tempOptionsList = [];
  //    let tempValue = 0.0;
  //    categoryProductList.map((product, index) => {
  //        tempOptionsList.push(tempValue);
  //        if (Math.max(tempList) > tempValue) {
  //            tempValue += 0.1;
  //        }
  //    });
  //    setValueList(tempOptionsList);
  //}

  function handleCatBtn(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== '-') {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
        let tempBonusOrComm = bonusOrComm.substring(0, 1).toUpperCase() + bonusOrComm.substring(1, bonusOrComm.length);

        if (value > product["max" + tempBonusOrComm]) {
          setValue(bonusOrComm + '-' + product.productId, product["max" + tempBonusOrComm]);
        }
        else if (value == product["max" + tempBonusOrComm]) {
          setValue(bonusOrComm + '-' + product.productId, value);

          if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
            document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
            document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
          }
        }
        else {
          setValue(bonusOrComm + '-' + product.productId, value);

          if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null && document.getElementById("category-" + categoryId + "-" + bonusOrComm) !== null) {
            document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
            document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
          }
        }
      });
    }
    else {
      products.filter(x => x.categoryName === categoryTitle).map((product, index) => {
        setValue(bonusOrComm + '-' + product.productId, '-');

        if (document.getElementById("product-" + product.productId + "-" + bonusOrComm) !== null) {
          document.getElementById("product-" + product.productId + "-" + bonusOrComm).style.background = backgroundLightMaxButton;
          document.getElementById("div-product-" + product.productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "1";
        }
      });
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {

    document.getElementById("product-" + productId + "-" + bonusOrComm).style.background = backgroundDarkMaxButton;
    document.getElementById("div-product-" + productId + "-" + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";

    if (bonusOrComm === "bonus") {
      setValue('bonus-' + productId, maxBonus);
    }
    else if (bonusOrComm === "commission") {
      setValue('commission-' + productId, maxComm);
    }
    else if (bonusOrComm === "clubFee") {
      setValue('clubFee-' + productId, maxClubFee);
    }
  }

  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {
    let tempHighestBonus = 0.00;
    let tempHighestComm = 0.00;
    let tempHighestMaxClubFee = 0.00;
    products.filter(x => x.categoryName === categoryTitle).map((product, index) => {

      if (document.getElementById("product-" + product.productId + '-' + bonusOrComm) !== null && document.getElementById("category-" + categoryId + "-" + bonusOrComm) !== null) {
        document.getElementById("product-" + product.productId + '-' + bonusOrComm).style.background = backgroundDarkMaxButton;
        document.getElementById("div-product-" + product.productId + '-' + bonusOrComm).getElementsByTagName("img")[0].style.opacity = "0.4";
      }

      if (bonusOrComm === "bonus") {
        if (product.maxBonus > tempHighestBonus) {
          tempHighestBonus = product.maxBonus;
        }
        if (100.00 > product.maxBonus) {
          setValue('bonus-' + product.productId, product["maxBonus"]);
        }
        else {
          setValue('bonus-' + product.productId, 100.00);
        }
      }
      else if (bonusOrComm === "commission") {
        if (product.maxCommission > tempHighestComm) {
          tempHighestComm = product.maxCommission;
        }
        if (100.00 > product.maxCommission) {
          setValue('commission-' + product.productId, product["maxCommission"]);
        }
        else {
          setValue('commission-' + product.productId, 100.00);
        }
      }
      else if (bonusOrComm === "clubFee") {
        if (product.maxClubFee > tempHighestMaxClubFee) {
          tempHighestMaxClubFee = product.maxClubFee;
        }
        if (100.00 > product.maxClubFee) {
          setValue('clubFee-' + product.productId, product["maxClubFee"]);
        }
        else {
          setValue('clubFee-' + product.productId, 100.00);
        }
      }
    });

    if (bonusOrComm === "bonus") {
      setValue('category-bonus-' + categoryId, tempHighestBonus);
    }
    else if (bonusOrComm === "commission") {
      setValue('category-commission-' + categoryId, tempHighestComm);
    }
    else if (bonusOrComm === "clubFee") {
      setValue('category-clubFee-' + categoryId, tempHighestMaxClubFee);
    }
  }

  function checkValueForLowest(value) {
    if (value !== '-') {
      if (value === 0) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (value !== '-') {
      if (bonusOrComm === "bonus") {
        if (value === valueList[category.originalTitle].highestMaxBonus) {
          return false;
        }
        else {
          return true;
        }
      }
      else if (bonusOrComm === "commission") {
        if (value === valueList[category.originalTitle].highestMaxComm) {
          return false;
        }
        else {
          return true;
        }
      }
      else if (bonusOrComm === "clubFee") {
        if (value === valueList[category.originalTitle].highestMaxClubFee) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    else {
      return true;
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const ReferralCodeDetailForm = () => {
    return (
      <div id={"referral-detail-modern"}>
        <div>
          <h4 className="mb-3">{t("REMARK")}:</h4>
          <div className="custom-input-container">
            <Input name="remark"
              defaultValue={stringIsNullOrEmpty(referralCodeDetail.remark) ? "" : referralCodeDetail.remark}
              ref={register()}
              placeholder={t("ENTER_OWN_REMARK")} />
          </div>
        </div>
        <div>
          <table className="edit-referral-table">
            <thead>
            </thead>
            <tbody>
              {
                categories.map((category, index) => {
                  if (products.filter(x => x.categoryName === category.originalTitle && (x.maxBonus > 0 || x.maxCommission > 0 || x.maxClubFee > 0)).length > 0) {
                    return (
                      <Fragment>
                        {products.filter(x => x.categoryName === category.originalTitle && x.maxBonus > 0).length > 0 ?
                          <>{!products.filter(x => x.categoryName === category.originalTitle && x.maxBonus > 0)[0] && <hr className="edit-referral-hr" />}
                            {index % 2 == 0 ?
                              (
                                <div className="hr-right mt-3 mb-3"></div>
                              ) : (
                                <div className="hr-left mt-3 mb-3"></div>
                              )}
                            <tr className="data-row category" style={{
                              borderBottomLeftRadius: valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px", borderBottomRightRadius: valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px",
                              boxShadow: valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0 ? "0px 0px 0px rgba(0, 0, 0, 0)" : "0px 4px 7px rgba(0, 0, 0, 0.3)"
                            }}
                            >
                              <td className="row-data-1"
                              // style={{ color: "#ffc159" }}
                              >
                                <b className="category-title">{t(category.title)}</b></td>
                              <td className="row-data-2"><div className="txt-1">{t("BONUS")}</div><div className="txt-2">{t("MAX") + ":" + (valueList[category.originalTitle].highestMaxBonus).toFixed(2) + "%"}</div></td>
                              <td className="row-data-3">
                                <Controller
                                  control={control}
                                  name={"category-bonus-" + category.id}
                                  defaultValue={0}
                                  render={({ onChange, value }) => {
                                    let valueNotLowest = checkValueForLowest(value);
                                    let valueNotHighest = checkValueForHighest(value, category, 'bonus');

                                    if (document.getElementById("category-" + category.id + "-bonus") !== null) {
                                      if (!valueNotHighest) {
                                        document.getElementById("category-" + category.id + "-bonus").style.background = backgroundDarkMaxButton;
                                      }
                                      else {
                                        document.getElementById("category-" + category.id + "-bonus").style.background = backgroundLightMaxButton;
                                      }
                                    }

                                    return (
                                      <>
                                        <div className='add-minus-box'>
                                          <div
                                            onClick={(e) => {
                                              if (valueNotLowest === true) {
                                                if (value !== '-') {
                                                  value -= 0.10;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                }
                                                else {
                                                  value = 0.00;
                                                }
                                                setValue('category-bonus-' + category.id, value);
                                                handleCatBtn("bonus", category.id, category.originalTitle, value);
                                              }
                                            }}>
                                            <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                          </div>
                                          <select
                                            className="bonusComm-option-select"
                                            value={value}
                                            placeholder={value}
                                            onChange={(e) => {
                                              handleSelectCatValue("bonus", category.id, category.originalTitle, e.target.value);
                                            }} >
                                            {
                                              valueList[category.originalTitle].bonusValues.map((bonusValue, index) => {
                                                return (
                                                  <option key={index} className="bonusComm-option-value" value={bonusValue !== '-' ? parseFloat(bonusValue.toFixed(2)) : '-'}>{bonusValue !== "-"
                                                    ? bonusValue.toFixed(2)
                                                    : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                      ? '\u00A0\u00A0\u00A0\u00A0-'
                                                      : "-"
                                                  }</option>
                                                )
                                              })
                                            }
                                          </select>
                                          <div
                                            onClick={(e) => {
                                              if (valueNotHighest === true) {
                                                if (value !== '-') {
                                                  value += 0.10;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                }
                                                else {
                                                  value = 0.00;
                                                }
                                                setValue('category-bonus-' + category.id, value);
                                                handleCatBtn("bonus", category.id, category.originalTitle, value);
                                              }
                                            }}>
                                            <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                          </div>
                                        </div>
                                        {errors.editPercent && <div className="invalid-feedback">{t(errors.editPercent.message)}</div>}
                                      </>
                                    )
                                  }}
                                />
                              </td>
                              <td className="row-data-4">
                                <div
                                  id={"category-" + category.id + "-bonus"}
                                  className={"row-data-max" + (getValues('category-bonus-' + category.id) == (valueList[category.originalTitle].highestMaxBonus).toFixed(2) ? " disabled" : "")}
                                  onClick={() => handleMaxCatBtn(category.id, category.originalTitle, "bonus")}
                                >
                                  {t("MAX")}
                                </div>
                              </td>
                            </tr></>
                          :
                          <>
                            {category.originalTitle == "Lottery" ?
                              <div className="hr-right mt-3 mb-3"></div>
                              : null
                            }
                          </>
                        }

                        {category.originalTitle != "Lottery" ? (index % 2 == 0 ?
                          (
                            <div className="hr-right mt-3 mb-3"></div>
                          ) : (
                            <div className="hr-left mt-3 mb-3"></div>
                          )) : null
                        }
                        {products.filter(x => x.categoryName === category.originalTitle && (x.maxCommission > 0)).length > 0 &&
                          <tr className="data-row category"
                            style={{
                              marginTop:
                                products.filter(
                                  (x) =>
                                    x.categoryName ===
                                    category.originalTitle && x.maxBonus > 0
                                ).length > 0
                                  ? "0"
                                  : "25px",
                              borderTopLeftRadius: valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px", borderTopRightRadius: valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0 ? "0" : "8px",

                            }}
                          >
                            <td className="row-data-1">{products.filter(x => x.categoryName === category.originalTitle && x.maxBonus > 0).length > 0 ? "" : <b className="category-title">{t(category.title)}</b>}</td>
                            <td className="row-data-2"><div className="txt-1">{t("COMMISSION_SHORT")}</div><div className="txt-2">{t("MAX") + ":" + (valueList[category.originalTitle].highestMaxComm).toFixed(2) + "%"}</div></td>
                            <td className="row-data-3">
                              {products.filter(x => x.categoryName === category.originalTitle && x.maxCommission > 0).length > 0 && <>
                                <Controller
                                  control={control}
                                  name={"category-commission-" + category.id}
                                  defaultValue={0}
                                  render={({ onChange, value }) => {
                                    let valueNotLowest = checkValueForLowest(value);
                                    let valueNotHighest = checkValueForHighest(value, category, 'commission');

                                    if (document.getElementById("category-" + category.id + "-commission") !== null) {
                                      if (!valueNotHighest) {
                                        document.getElementById("category-" + category.id + "-commission").style.background = backgroundDarkMaxButton;
                                      }
                                      else {
                                        document.getElementById("category-" + category.id + "-commission").style.background = backgroundLightMaxButton;
                                      }
                                    }

                                    return (
                                      <>
                                        <div className="add-minus-box">
                                          <div
                                            onClick={(e) => {
                                              if (valueNotLowest === true) {
                                                if (value !== '-') {
                                                  value -= 0.10;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                }
                                                else {
                                                  value = 0.00;
                                                }
                                                setValue("category-commission-" + category.id, parseFloat(value.toFixed(2)));
                                                handleCatBtn("commission", category.id, category.originalTitle, value);
                                              }
                                            }}>
                                            <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                          </div>
                                          <select
                                            className="bonusComm-option-select"
                                            value={value}
                                            placeholder={value}
                                            onChange={(e) => {
                                              handleSelectCatValue("commission", category.id, category.originalTitle, e.target.value);
                                            }} >
                                            {
                                              valueList[category.originalTitle].commValues.map((commValue, index) => {
                                                return (
                                                  <option key={index} className="bonusComm-option-value" value={commValue !== '-' ? parseFloat(commValue.toFixed(2)) : '-'}>{commValue !== "-"
                                                    ? commValue.toFixed(2)
                                                    : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                      ? '\u00A0\u00A0\u00A0\u00A0-'
                                                      : "-"
                                                  }</option>
                                                )
                                              })
                                            }
                                          </select>
                                          <div
                                            onClick={(e) => {
                                              if (valueNotHighest === true) {
                                                if (value !== '-') {
                                                  value += 0.10;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                }
                                                else {
                                                  value = 0.00;
                                                }
                                                setValue("category-commission-" + category.id, parseFloat(value.toFixed(2)));
                                                handleCatBtn("commission", category.id, category.originalTitle, value);
                                              }

                                            }}>
                                            <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                          </div>
                                        </div>
                                        {errors.editPercent && <div className="invalid-feedback">{t(errors.editPercent.message)}</div>}
                                      </>
                                    )
                                  }}
                                />
                              </>}
                            </td>
                            <td className="row-data-4">
                              <div
                                id={"category-" + category.id + "-commission"}
                                className="row-data-max"
                                onClick={() => handleMaxCatBtn(category.id, category.originalTitle, "commission")}
                              >
                                {t("MAX")}
                              </div>
                            </td>
                          </tr>}
                        {category.originalTitle === "Poker" && products.filter(x => x.categoryName === category.originalTitle && (x.maxClubFee > 0)).length > 0 && <tr className="data-row category" style={{ marginTop: products.filter(x => x.categoryName === category.originalTitle && x.maxCommission > 0).length > 0 ? "0" : "25px" }}>
                          <td className="row-data-1">{products.filter(x => x.categoryName === category.originalTitle && x.maxCommission > 0).length > 0 ? "" : <b className="category-title">{t(category.title)}</b>}</td>
                          <td className="row-data-2"><div className="txt-1">{t("CLUB_FEE")}</div><div className="txt-2">{t("MAX") + ":" + (valueList[category.originalTitle].highestMaxClubFee).toFixed(2) + "%"}</div></td>
                          <td className="row-data-3">
                            {products.filter(x => x.categoryName === category.originalTitle && x.maxClubFee > 0).length > 0 && <>
                              <Controller
                                control={control}
                                name={"category-clubFee-" + category.id}
                                defaultValue={0}
                                render={({ onChange, value }) => {
                                  let valueNotLowest = checkValueForLowest(value);
                                  let valueNotHighest = checkValueForHighest(value, category, 'clubFee');

                                  if (document.getElementById("category-" + category.id + "-clubFee") !== null) {
                                    if (!valueNotHighest) {
                                      document.getElementById("category-" + category.id + "-clubFee").style.background = backgroundDarkMaxButton;
                                    }
                                    else {
                                      document.getElementById("category-" + category.id + "-clubFee").style.background = backgroundLightMaxButton;
                                    }
                                  }
                                  return (
                                    <>
                                      <div className="add-minus-box">
                                        <div
                                          onClick={(e) => {
                                            if (valueNotLowest === true) {
                                              if (value !== '-') {
                                                value -= 5.0;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                              }
                                              else {
                                                value = 0.00;
                                              }
                                              setValue("category-clubFee-" + category.id, parseFloat(value.toFixed(2)));
                                              handleCatBtn("clubFee", category.id, category.originalTitle, value);
                                            }
                                          }}>
                                          <img style={{ opacity: valueNotLowest ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                        </div>
                                        <select
                                          className="bonusComm-option-select"
                                          value={value}
                                          placeholder={value}
                                          onChange={(e) => {
                                            handleSelectCatValue("clubFee", category.id, category.originalTitle, e.target.value);
                                          }} >
                                          {
                                            valueList[category.originalTitle].clubFeeValues.map((clubFeeValue, index) => {
                                              return (
                                                <option key={index} className="bonusComm-option-value" value={clubFeeValue !== '-' ? parseFloat(clubFeeValue.toFixed(2)) : '-'}>{clubFeeValue !== "-"
                                                  ? clubFeeValue.toFixed(2)
                                                  : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                    ? '\u00A0\u00A0\u00A0\u00A0-'
                                                    : "-"
                                                }</option>
                                              )
                                            })
                                          }
                                        </select>
                                        <div
                                          onClick={(e) => {
                                            if (valueNotHighest === true) {
                                              if (value !== '-') {
                                                value += 5.0;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                              }
                                              else {
                                                value = 0.00;
                                              }
                                              setValue("category-clubFee-" + category.id, parseFloat(value.toFixed(2)));
                                              handleCatBtn("clubFee", category.id, category.originalTitle, value);
                                            }
                                          }}>
                                          <img style={{ opacity: valueNotHighest ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                        </div>
                                      </div>
                                      {errors.editPercent && <div className="invalid-feedback">{t(errors.editPercent.message)}</div>}
                                    </>
                                  )
                                }}
                              />
                            </>}
                          </td>
                          <td className="row-data-4">
                            <div
                              id={"category-" + category.id + "-clubFee"}
                              className="row-data-max"
                              onClick={() =>
                                handleMaxCatBtn(category.id, category.originalTitle, "clubFee")
                              }>
                              {t("MAX")}
                            </div>
                          </td>
                        </tr>}


                        {products.filter(x => x.categoryName === category.originalTitle).length > 0 &&
                          products.filter(product => product.categoryName === category.originalTitle && (product.maxBonus > 0 || product.maxCommission > 0 || product.maxClubFee > 0)).map((product, i) => {
                            return (
                              <Fragment key={i}>
                                {product.maxBonus > 0 && <tr className={"data-row" + ((i + 1) % 2 !== 0 ? " bg-color-1" : '')}>
                                  <td className="row-data-1">
                                    <div className="row-data-title">{product.productName}</div>
                                  </td>
                                  <td className="row-data-2"><div className="txt-1">{t("BONUS")}</div><div className="txt-2">{t("MAX") + ":" + product.maxBonus.toFixed(2) + "%"}</div></td>
                                  <td className="row-data-3">
                                    <Controller
                                      control={control}
                                      name={"bonus-" + product.productId}
                                      defaultValue={0}
                                      render={({ onChange, value }) => {
                                        if (document.getElementById("product-" + product.productId + "-bonus") !== null) {
                                          if (value === '-') {
                                            document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                            document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";
                                          }
                                          else if (value == product.maxBonus) {
                                            document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                            document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "0.4";
                                          }
                                          else {
                                            document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                            document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";
                                          }
                                        }

                                        return (
                                          <>
                                            <div className='add-minus-box'>
                                              <div
                                                onClick={(e) => {
                                                  if (value !== '-') {
                                                    if (product.maxBonus > 0 && parseFloat(value) > 0) {
                                                      value -= 0.10;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;

                                                      document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                      document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";

                                                    }
                                                    if (value == product.maxBonus) {
                                                      document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundDarkMaxButton;
                                                      document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                    }
                                                    else {
                                                      document.getElementById("product-" + product.productId + "-bonus").style.background = backgroundLightMaxButton;
                                                      document.getElementById("div-product-" + product.productId + "-bonus").getElementsByTagName("img")[0].style.opacity = "1";
                                                    }
                                                  }
                                                  else {
                                                    value = 0.00;
                                                    document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                    document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                  }
                                                  setValue("bonus-" + product.productId, value);
                                                }}>
                                                <img style={{ opacity: (product.maxBonus > 0 && parseFloat(value)) || (value == '-') > 0 ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                              </div>
                                              <select
                                                className="bonusComm-option-select"
                                                value={value}
                                                placeholder={value}
                                                onChange={(e) => {
                                                  handleSelectValue("bonus", product.productId, e.target.value, product.maxBonus);
                                                }} >
                                                {
                                                  valueList[category.originalTitle][product.productName].bonusValues.map((bonusValue, index) => {
                                                    return (
                                                      <option className="bonusComm-option-value" value={bonusValue !== '-' ? parseFloat(bonusValue.toFixed(2)) : '-'}>{bonusValue !== "-"
                                                        ? bonusValue.toFixed(2)
                                                        : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                          ? '\u00A0\u00A0\u00A0\u00A0-'
                                                          : "-"
                                                      }</option>
                                                    )
                                                  })
                                                }
                                              </select>
                                              <div
                                                id={"div-product-" + product.productId + "-bonus"}
                                                onClick={(e) => {
                                                  if (value !== '-') {
                                                    if (product.maxBonus > 0 && parseFloat(value) < product.maxBonus) {
                                                      value += 0.10;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    }
                                                  }
                                                  else {
                                                    value = 0.00;
                                                  }
                                                  setValue("bonus-" + product.productId, value);
                                                }}>
                                                <img style={{ opacity: (product.maxBonus > 0 && parseFloat(value) || (value == '-')) < product.maxBonus ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="add" />
                                              </div>
                                            </div>
                                            {errors.editPercent && <div className="invalid-feedback">{t(errors.editPercent.message)}</div>}
                                          </>
                                        )
                                      }}
                                    />
                                  </td>
                                  <td className="row-data-4">
                                    <div
                                      id={"product-" + product.productId + "-bonus"}
                                      className="row-data-max"
                                      onClick={() =>
                                        handleMaxBtn(product.productId, product.maxBonus, product.maxCommission, product.maxClubFee, "bonus")
                                      }>
                                      {t("MAX")}
                                    </div>
                                  </td>
                                </tr>}
                                {product.maxCommission > 0 && <tr className={"data-row" + ((i + 1) % 2 !== 0 ? (product.maxBonus > 0 ? product.maxClubFee > 0 ? " bg-color-2" : " bg-color-3" : product.maxClubFee > 0 ? " bg-color-1" : "") : "")}>
                                  <td className="row-data-1">{product.maxBonus > 0 ? "" : <div className="row-data-title">{product.productName}</div>}</td>
                                  <td className="row-data-2"><div className="txt-1">{t("COMMISSION_SHORT")}</div><div className="txt-2">{t("MAX") + ":" + product.maxCommission.toFixed(2) + "%"}</div></td>
                                  <td className="row-data-3">
                                    {product.maxCommission > 0 && <>
                                      <Controller
                                        control={control}
                                        name={"commission-" + product.productId}
                                        defaultValue={0}
                                        render={({ onChange, value }) => {

                                          if (document.getElementById("product-" + product.productId + "-commission") !== null) {
                                            if (value === '-') {
                                              document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                              document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                            }
                                            else if (value == product.maxCommission) {
                                              document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                              document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "0.4";
                                            }
                                            else {
                                              document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                              document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                            }
                                          }

                                          return (
                                            <>
                                              <div className="add-minus-box">
                                                <div
                                                  className=""
                                                  onClick={(e) => {
                                                    if (value !== '-') {
                                                      if (product.maxCommission > 0 && parseFloat(value) > 0) {
                                                        value -= 0.10;
                                                        value *= 100;
                                                        value = Math.round(value);
                                                        value /= 100;

                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }

                                                      if (value == product.maxCommission) {
                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                      }
                                                      else {
                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }
                                                    }
                                                    else {
                                                      value = 0.00;
                                                      document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                      document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                    }
                                                    setValue("commission-" + product.productId, value);
                                                  }}>
                                                  <img style={{ opacity: (product.maxCommission > 0 && parseFloat(value) > 0) || (value == '-') ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                </div>
                                                <select
                                                  className="bonusComm-option-select"
                                                  value={value}
                                                  placeholder={value}
                                                  onChange={(e) => {
                                                    handleSelectValue("commission", product.productId, e.target.value, product.maxCommission);
                                                  }} >
                                                  {
                                                    valueList[category.originalTitle][product.productName].commValues.map((commValue, index) => {
                                                      return (
                                                        <option className="bonusComm-option-value" value={commValue !== '-' ? parseFloat(commValue.toFixed(2)) : '-'}>{commValue !== "-"
                                                          ? commValue.toFixed(2)
                                                          : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                            ? '\u00A0\u00A0\u00A0\u00A0-'
                                                            : "-"
                                                        }</option>
                                                      )
                                                    })
                                                  }
                                                </select>
                                                <div
                                                  id={"div-product-" + product.productId + "-commission"}
                                                  onClick={(e) => {
                                                    if (value !== '-') {
                                                      if (product.maxCommission > 0 && parseFloat(value) < product.maxCommission) {
                                                        value += 0.10;
                                                        value *= 100;
                                                        value = Math.round(value);
                                                        value /= 100;

                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }

                                                      if (value == product.maxCommission) {
                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundDarkMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                      }
                                                      else {
                                                        document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }
                                                    }
                                                    else {
                                                      value = 0.00;
                                                      document.getElementById("product-" + product.productId + "-commission").style.background = backgroundLightMaxButton;
                                                      document.getElementById("div-product-" + product.productId + "-commission").getElementsByTagName("img")[0].style.opacity = "1";
                                                    }
                                                    setValue("commission-" + product.productId, value);
                                                  }}>
                                                  <img style={{ opacity: (product.maxCommission > 0 && parseFloat(value) < product.maxCommission) || (value == '-') ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="minus" />
                                                </div>
                                              </div>
                                            </>
                                          )
                                        }}
                                      />
                                    </>}
                                  </td>
                                  <td className="row-data-4">
                                    <div
                                      id={"product-" + product.productId + "-commission"}
                                      className="row-data-max"
                                      onClick={() =>
                                        handleMaxBtn(product.productId, product.maxBonus, product.maxCommission, product.maxClubFee, "commission")
                                      }>
                                      {t("MAX")}
                                    </div>
                                  </td>
                                </tr>}
                                {product.productName === "D2 Poker" && product.maxClubFee > 0 && <tr className={"data-row" + ((i + 1) % 2 !== 0 ? (product.maxClubFee > 0 && (product.maxBonus > 0 || product.maxCommission > 0) ? " bg-color-3" : " bg-color") : " bg-color")}>
                                  <td className="row-data-1">{product.maxCommission > 0 ? "" : <div className="row-data-title">{product.productName}</div>}</td>
                                  <td className="row-data-2"><div className="txt-1">{t("CLUB_FEE")}</div><div className="txt-2">{t("MAX") + ":" + product.maxClubFee.toFixed(2) + "%"}</div></td>
                                  <td className="row-data-3">
                                    {product.maxClubFee > 0 && <>
                                      <Controller
                                        control={control}
                                        name={"clubFee-" + product.productId}
                                        defaultValue={0}
                                        render={({ onChange, value }) => {

                                          if (document.getElementById("product-" + product.productId + "-clubFee") !== null) {
                                            if (value === '-') {
                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                              document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                            }
                                            else if (value == product.maxClubFee) {
                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                              document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "0.4";
                                            }
                                            else {
                                              document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                              document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                            }
                                          }
                                          return (
                                            <>
                                              <div className='add-minus-box'>
                                                <div
                                                  onClick={(e) => {
                                                    if (value !== '-') {
                                                      if (product.maxClubFee > 0 && parseFloat(value) > 0) {
                                                        value -= 5.0;
                                                        value *= 100;
                                                        value = Math.round(value);
                                                        value /= 100;

                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }
                                                      if (value == product.maxClubFee) {
                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                      }
                                                      else {
                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }
                                                    }
                                                    else {
                                                      value = 0.00;
                                                    }
                                                    setValue("clubFee-" + product.productId, value);
                                                  }}>
                                                  <img style={{ opacity: (product.maxClubFee > 0 && parseFloat(value) > 0) || (value == '-') ? "1" : "0.4" }} src={require("../../assets/img/v3/minus.png")} alt="minus" />
                                                </div>
                                                <select
                                                  className="bonusComm-option-select"
                                                  value={value}
                                                  placeholder={value}
                                                  onChange={(e) => {
                                                    handleSelectValue("clubFee", product.productId, e.target.value, product.maxClubFee);
                                                  }} >
                                                  {
                                                    valueList[category.originalTitle][product.productName].clubFeeValues.map((clubFeeValue, index) => {
                                                      return (
                                                        <option className="bonusComm-option-value" value={clubFeeValue !== '-' ? parseFloat(clubFeeValue.toFixed(2)) : '-'}>{clubFeeValue !== "-"
                                                          ? clubFeeValue.toFixed(2)
                                                          : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                            ? '\u00A0\u00A0\u00A0\u00A0-'
                                                            : "-"
                                                        }</option>
                                                      )
                                                    })
                                                  }
                                                </select>
                                                <div
                                                  id={"div-product-" + product.productId + "-clubFee"}
                                                  onClick={(e) => {
                                                    if (value !== '-') {
                                                      if (product.maxClubFee > 0 && parseFloat(value) < product.maxClubFee) {
                                                        value += 5.0;
                                                        value *= 100;
                                                        value = Math.round(value);
                                                        value /= 100;

                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }
                                                      if (value == product.maxClubFee) {
                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundDarkMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "0.4";
                                                      }
                                                      else {
                                                        document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                        document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                      }
                                                    }
                                                    else {
                                                      value = 0.00;
                                                      document.getElementById("product-" + product.productId + "-clubFee").style.background = backgroundLightMaxButton;
                                                      document.getElementById("div-product-" + product.productId + "-clubFee").getElementsByTagName("img")[0].style.opacity = "1";
                                                    }
                                                    setValue("clubFee-" + product.productId, value);
                                                  }}>
                                                  <img style={{ opacity: (product.maxClubFee > 0 && parseFloat(value) < product.maxClubFee) || (value == '-') ? "1" : "0.4" }} src={require("../../assets/img/v3/add.png")} alt="minus" />
                                                </div>
                                              </div>
                                            </>
                                          )
                                        }}
                                      />
                                    </>}
                                  </td>
                                  <td className="row-data-4">
                                    <div
                                      className="row-data-max"
                                      id={"product-" + product.productId + "-clubFee"}
                                      onClick={() =>
                                        handleMaxBtn(product.productId, product.maxBonus, product.maxCommission, product.maxClubFee, "clubFee")
                                      }>
                                      {t("MAX")}
                                    </div>
                                  </td>
                                </tr>}
                              </Fragment>
                            )
                          })
                        }
                      </Fragment>
                    )
                  }
                })
              }
            </tbody>
          </table>
        </div>

      </div>
    )
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_REFERRAL_CODE_DETAIL}
        state={_location.state}
        title={t("EDIT_REFERRAL_DETAIL")}
      />
      <form className='pb-4'>
        {
          (!isObjectEmpty(referralCodeDetail) && initCheck) && <>
            {ReferralCodeDetailForm()}
            <div style={{ margin: "1rem 0px" }}>
              <button type="button" className="btn custom-btn mt-4 mb-5 w-100" onClick={() => handleSubmit(submitForm)()}>{t("SUBMIT")}</button>
            </div>
          </>
        }
      </form>
      {/* Modal for manually keying % */}
      <Modal isOpen={showKeyboard} contentClassName="modal-brand modal-bottom modal-numpad" centered>
        <ModalBody>
          <div className="display-wrapper">
            <button type="button" style={{
              alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", fontWeight: "bold",
            }} onClick={() => { setShowKeyboard(false) }} data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
            <strong className="text-brand display-value">{selectedValue.substring(0, 2) + "." + selectedValue.substring(2, selectedValue.length)}</strong>
          </div>
          <Keyboard
            layout={{
              'default': [
                "1 2 3 4 5 6 7 8 9 . {enter} 0 {bksp}"
              ]
            }}
            display={{
              '{bksp}': "<i class ='fas fa-backspace'></i>",
              '{enter}': "<span className='referral-keyboard-enter'>Enter</span>"
            }}
            theme={`keyboard keyboard-numpad`}
            keyboardRef={r => (keyboard.current = r)}
            onKeyPress={onEditReferralKeyPress}
            disableButtonHold
            disableCaretPositioning
          />
        </ModalBody>
      </Modal>
      {/* Modal for manually keying category % */}
      <Modal isOpen={showCategoryKeyboard} contentClassName="modal-brand modal-bottom modal-numpad" centered>
        <ModalBody>
          <div className="display-wrapper">
            <button type="button" style={{
              alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", fontWeight: "bold",
            }} onClick={() => { setShowCategoryKeyboard(false) }} data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
            <strong className="text-brand display-value">{selectedValue.substring(0, 2) + "." + selectedValue.substring(2, selectedValue.length)}</strong>
          </div>
          <Keyboard
            layout={{
              'default': [
                "1 2 3 4 5 6 7 8 9 . {enter} 0 {bksp}"
              ]
            }}
            display={{
              '{bksp}': "<i class ='fas fa-backspace'></i>",
              '{enter}': "<span className='referral-keyboard-enter'>Enter</span>"
            }}
            theme={`keyboard keyboard-numpad`}
            keyboardRef={r => (keyboard.current = r)}
            onKeyPress={onCategoryKeyPress}
            disableButtonHold
            disableCaretPositioning
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditReferral;