import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import Header from "../../components/custom/Header";
import { useTranslation } from "react-i18next";
import { stringIsNullOrEmpty } from "../../util/Util";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
/// <summary>
/// Author :
/// </summary>
const ContactForm = (props) => {
  const _history = useHistory();
  const _dispatch = useDispatch();
  const { t } = useTranslation();
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState();
  const [toggleModal, setToggleModal] = useState();
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    _dispatch(setBusy());
    fetch(ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        'Content-Type': ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
      },
    }).then((response) => response.json()).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setChannelData(responseJson[ApiKey._API_DATA_KEY]);
      }
      _dispatch(setIdle());
    });
  }, []);

  function setChannelData(data) {
    var channels = [];
    data.map((d, index) => {
      var channel =
      {
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  function handleClick(channel) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "chat",
          // url: `${channel.actionUrl}`,
          url: channel.actionUrl.match(/live-chat/i) != null ? `${window.location.origin}${channel.actionUrl}` : `${channel.actionUrl}`
        })
      );
    }
    else {
      if (!stringIsNullOrEmpty(channel.actionUrl)) {
        setToggleModal(false);
        window.location.href = channel.actionUrl;
      }
      else {
        setSelectedChannel(channel);
        setToggleModal(true);
      }
    }
  }

  return (
    <>
      <Header history={_history} url={WebUrl._URL_MAIN} title={t('LIVE_CHAT')} />
      <div>
        {channels &&
          channels.length > 0 &&
          map(channels, (item, index) => {
            return (
              <>
                <div className="box-list-with-shadow live-chat-item" key={item.id} onClick={() => handleClick(item)}>
                  <div>
                    <div className="custom-icon-box" >
                      <img style={{ width: "20px" }} src={item.image} alt={item.name} />
                    </div>
                  </div>
                  <div className="second">
                    {item.name}
                  </div>
                </div>
              </>
            );
          })}
      </div>
      <Modal fade={false} isOpen={toggleModal} toggle={() => { setToggleModal(false) }} centered>
        <ModalHeader toggle={() => { setToggleModal(false) }}>{selectedChannel?.name}</ModalHeader>
        <ModalBody>
          <img src={selectedChannel?.attachment} className="w-100" />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ContactForm;