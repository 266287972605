import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { numberWithCurrencyFormat } from "../../util/Util";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";

const Summary = (props) => {
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    var _history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    var tempStartDate = params.get('startDate');
    if (tempStartDate == null) { tempStartDate = moment().add(-1, "days") };
    var tempEndDate = params.get('endDate');
    if (tempEndDate == null) { tempEndDate = moment().add(-1, "days") };

    const [allData, setAllData] = useState({});
    const [memberDepthData, setMemberDepthData] = useState({});
    const [startDate, setStartDate] = useState(tempStartDate);
    const [endDate, setEndDate] = useState(tempEndDate);
    const [tempFromDate, setTempFromDate] = useState(tempStartDate);
    const [tempToDate, setTempToDate] = useState(tempEndDate);

    const [processedData, setProcessedData] = useState({});
    const [processedUserTotal, setProcessedUserTotal] = useState({});
    const [processedCategoryTotal, setProcessedCategoryTotal] = useState({});
    const [processedSubTotal, setProcessedSubTotal] = useState({});
    const [processedGrandTotal, setProcessedGrandTotal] = useState({});
    const [processedUsernameRows, setProcessedUsernameRows] = useState({});
    const [companySummaryData, setCompanySummaryData] = useState({});
    const [companyCategoryTotal, setCompanyCategoryTotal] = useState({});
    const [companyGrandTotal, setCompanyGrandTotal] = useState({});

    const companyToggle = (category) => {
        let tempObj = companyCategoryTotal;
        if (category !== "null") {
            tempObj[category].show = !tempObj[category].show;
        }
        else {
            tempObj.show = !tempObj.show;
        }
        setCompanyCategoryTotal({ ...tempObj });
    }


    const settlementToggle = (username) => {
        let tempObj = processedUserTotal;
        tempObj[username].showset = !tempObj[username].showset;
        setProcessedUserTotal({ ...tempObj });
    }

    const settlementCatToggle = (username, category) => {
        let tempObj = processedCategoryTotal;
        tempObj[username][category].showset = !tempObj[username][category].showset;
        setProcessedCategoryTotal({ ...tempObj });
    }

    const usernameToggle = (username) => {
        let tempObj = processedUserTotal;
        tempObj[username].show = !tempObj[username].show;
        setProcessedUserTotal({ ...tempObj });
    }
    const categoryToggle = (username, category) => {
        let tempObj = processedCategoryTotal;
        tempObj[username][category].show = !tempObj[username][category].show;
        setProcessedCategoryTotal({ ...tempObj });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        init(tempStartDate, tempEndDate);
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init(sDate, eDate) {
        _dispatch(setBusy());
        setStartDate(sDate);
        setEndDate(eDate);
        var responseJson = await ApiEngine.get(ApiUrl._API_CHECK_HIDE_SUMMARY + "?hideSummary=" + props.hideSummary);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${moment(sDate).format("YYYY-MM-DD")}&enddate=${moment(eDate).format("YYYY-MM-DD")}`);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];
                setAllData(data);
                let memberData = responseJson[ApiKey._API_DATA_KEY]["depthData"];
                setMemberDepthData(memberData);

                let summaryRows = {};
                let summaryCategoryTotal = { show: false };
                let summaryGrandTotal = { sales: 0, vsales: 0, payout: 0, netProfit: 0 };

                data["summaryData"].map((summary, index) => {
                    if (!summaryRows[summary.categoryName]) {
                        summaryRows[summary.categoryName] = {};
                        summaryCategoryTotal[summary.categoryName] = { grossSales: 0, validTurnover: 0, payout: 0, netProfit: 0 };
                    }

                    if (!summaryRows[summary.categoryName][summary.productName]) {
                        summaryRows[summary.categoryName][summary.productName] = { grossSales: summary["grossSales"], validTurnover: summary["validTurnover"], payout: summary["payout"], netProfit: summary["netProfit"] };
                    }

                    summaryCategoryTotal[summary.categoryName].show = false;
                    summaryCategoryTotal[summary.categoryName].grossSales += summary["grossSales"];
                    summaryCategoryTotal[summary.categoryName].validTurnover += summary["validTurnover"];
                    summaryCategoryTotal[summary.categoryName].payout += summary["payout"];
                    summaryCategoryTotal[summary.categoryName].netProfit += summary["netProfit"];

                    if (summary.categoryName !== null) {
                        summaryGrandTotal.sales += summary["grossSales"];
                        summaryGrandTotal.vsales += summary["validTurnover"];
                        summaryGrandTotal.payout += summary["payout"];
                        summaryGrandTotal.netProfit += summary["netProfit"];
                    }
                    else {
                        summaryGrandTotal.netProfit += summary["netProfit"];
                    }
                })
                setCompanySummaryData(summaryRows);
                setCompanyCategoryTotal(summaryCategoryTotal);
                setCompanyGrandTotal(summaryGrandTotal);


                let tempRows = {};
                let dataRows = {};
                let userTotal = {};
                let categoryTotal = {};
                let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
                let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };

                data["downlineData"].map((e, i) => {
                    if (!dataRows[e.username]) {
                        dataRows[e.username] = {};
                        tempRows[e.username] = { memberGuid: e.memberId, hasDownline: e.usernameHasDownlineData };
                        userTotal[e.username] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                        categoryTotal[e.username] = {};
                    }

                    if (!dataRows[e.username][e.categoryName]) {
                        dataRows[e.username][e.categoryName] = {};
                        categoryTotal[e.username][e.categoryName] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                    }

                    if (!dataRows[e.username][e.categoryName][e.productName]) {
                        dataRows[e.username][e.categoryName][e.productName] = { grossSales: e.grossSales, validTurnover: e.validTurnover, netProfit: e.netProfit, profit: e.profit, settlement: e.settlement, share: e.share };
                    }

                    userTotal[e.username].show = false;
                    userTotal[e.username].showset = false;
                    userTotal[e.username].grossSales += e.grossSales;
                    userTotal[e.username].validTurnover += e.validTurnover;
                    userTotal[e.username].netProfit += e.netProfit;
                    userTotal[e.username].profit += e.profit;
                    userTotal[e.username].settlement += e.settlement;
                    userTotal[e.username].share = e.share;


                    categoryTotal[e.username][e.categoryName].show = false;
                    categoryTotal[e.username][e.categoryName].showset = false;
                    categoryTotal[e.username][e.categoryName].grossSales += e.grossSales;
                    categoryTotal[e.username][e.categoryName].validTurnover += e.validTurnover;
                    categoryTotal[e.username][e.categoryName].netProfit += e.netProfit;
                    categoryTotal[e.username][e.categoryName].profit += e.profit;
                    categoryTotal[e.username][e.categoryName].settlement += e.settlement;
                    categoryTotal[e.username][e.categoryName].share = e.share;

                    subTotal.sales += e.grossSales;
                    subTotal.vsales += e.validTurnover;
                    subTotal.netProfit += e.profit;

                    grandTotal.sales += e.grossSales;
                    grandTotal.vsales += e.validTurnover;
                    grandTotal.netProfit += e.profit;
                    grandTotal.netProfit += e.settlement;

                });
                setProcessedUserTotal(userTotal);
                setProcessedCategoryTotal(categoryTotal);
                setProcessedData(dataRows);
                setProcessedUsernameRows(tempRows);
                setProcessedSubTotal(subTotal);
                setProcessedGrandTotal(grandTotal);

            }
            else {
                _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        }
        else {
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
            _history.push(WebUrl._URL_DASHBOARD);
        }
        _dispatch(setIdle());
    }

    const getDownlineSales = async (username) => {
        _history.push(WebUrl._URL_SUMMARY_DOWNLINE_SALES + "?startDate=" + moment(startDate).format('YYYY-MM-DD') + "&endDate=" + moment(endDate).format('YYYY-MM-DD') + "&username=" + username + "&GAzobE73Vse4nr8WpufA=" + props.hideSummary);
        //responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).format("YYYY-MM-DD")}&memberId=${memberId}`);
        //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        //}
    }

    return (
        <div className={"shareholder-downline custom-tables" + (memberDepthData['selectedTheme'] === 0 ? "" : " modern")}>
            <div style={{ paddingBottom: "10px" }}>
                {/*<div className="card kpi-header-widget mb-2">
                    <div className="card-body">
                        <div className="user-detail">
                            <div style={{ marginBottom: "10px" }}>{kpiData["username"]}</div>
                            <div>{kpiData["phone"]}</div>
                        </div>
                        <div>
                            <div className="text-right">{t("DOWNLINE")}: {kpiData["totalDownline"]}</div>
                        </div>
                    </div>
                </div>*/}
                <div className="hr-right mt-3 mb-3"></div>
                <DateRangeFilter2 startDate={tempFromDate} endDate={tempToDate} setStartDate={setTempFromDate} setEndDate={setTempToDate} onSave={(start, end) => {
                    init(start, end);
                }} options={true} />

                {Object.keys(companySummaryData) && Object.keys(companySummaryData).length > 0 && <>
                    <div className="hr-left mt-3 mb-3"></div>
                    <h3 className="mb-3">{t("COMPANY_SUMMARY")}</h3>
                    <div className="card-wrapper"><div className="card card-package">
                        <div className="card-body p-0">
                            <div className="card-table table-responsive">
                                <table className="table table-kpi-summary">
                                    <thead style={{ borderBottom: "2px solid" }}>
                                        <tr>
                                            <th style={{ textAlign: "left", paddingLeft: "20px" }}>{t("CATEGORY")}</th>
                                            <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                            <th style={{ textAlign: "right" }}>{t("PAYOUT")}</th>
                                            <th style={{ textAlign: "right", paddingRight: "20px" }}>{t("NET_PROFIT")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(companySummaryData).length > 0 && Object.keys(companyCategoryTotal).length > 0 &&
                                            Object.keys(companySummaryData).map((category, i) => {
                                                if (typeof (companyCategoryTotal[category]) !== "undefined" && (category === "null" || companyCategoryTotal.show)) {
                                                    return (
                                                        <>
                                                            <tr key={i}>
                                                                <td style={{ textAlign: "left", paddingLeft: "20px" }} onClick={() => companyToggle(category)}>{category === "null" ? t("SETTLEMENT") : t(category)}{category !== "null" && <i className={companyCategoryTotal[category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} />}{category === "null" && <i className={companyCategoryTotal.show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} />}</td>
                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyCategoryTotal[category]["validTurnover"]), 2, true)}</td>
                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyCategoryTotal[category]["payout"]), 2, true)}</td>
                                                                <td className={companyCategoryTotal[category]["netProfit"] < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(companyCategoryTotal[category]["netProfit"]), 2, true)}</td>
                                                            </tr>
                                                            {companyCategoryTotal[category].show &&
                                                                Object.keys(companySummaryData[category]).map((product, index) => {
                                                                    return (
                                                                        <tr key={index} className="child-row">
                                                                            <td className="category-product" style={{ textAlign: "left" }}>- {product}</td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companySummaryData[category][product]["validTurnover"]), 2, true)}</td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companySummaryData[category][product]["payout"]), 2, true)}</td>
                                                                            <td className={companySummaryData[category][product]["netProfit"] < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(companySummaryData[category][product]["netProfit"]), 2, true)}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                        <tr className="grandtotal" key={"companygrandtotal"}>
                                            <td style={{ textAlign: "left", paddingLeft: "20px" }}>{t("GRAND_TOTAL")}</td>
                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyGrandTotal.vsales), 2, true)}</td>
                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyGrandTotal.payout), 2, true)}</td>
                                            <td className={companyGrandTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(companyGrandTotal.netProfit), 2, true)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div></div>
                </>
                }

                {Object.keys(companySummaryData).length === 0 && <>
                    <div className="hr-left mt-3 mb-3"></div>
                    <h3 className="mb-3">{t("COMPANY_SUMMARY")}</h3>
                    <div className="card-wrapper"><div className="card card-package">
                        <div className="card-body p-0">
                            <div className="card-table table-responsive">
                                <table className="table table-kpi-summary">
                                    <thead style={{ borderBottom: "2px solid" }}>
                                        <tr>
                                            <th style={{ textAlign: "left", paddingLeft: "20px" }}>{t("CATEGORY")}</th>
                                            <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                            <th style={{ textAlign: "right" }}>{t("PAYOUT")}</th>
                                            <th style={{ textAlign: "right", paddingRight: "20px" }}>{t("NET_PROFIT")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">{t("NO_REPORT_FOR_THE_DATE")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div></div>
                </>
                }

                {Object.keys(processedData).length > 0 && <>
                    <div className="hr-right mt-3 mb-3"></div>
                    <h3 className="mb-3">{t("MY_INCOME")}</h3>
                    <div className="shareholder-my-income">
                        <div className="card-wrapper"><div className="card my-income-card">
                            <div className="my-income-card-content">
                                <div className="content-title">{t("DIRECT_DOWNLINE")}</div>
                                <div className="content-value">{memberDepthData["totalDirectDownlines"]}</div>
                            </div>
                        </div></div>
                        <div className="card-wrapper"><div className="card my-income-card">
                            <div className="my-income-card-content">
                                <div className="content-title">{t("NET_PROFIT")}</div>
                                <div className={"content-value" + (processedGrandTotal.netProfit < 0 ? " text-red" : "")}>{isNaN(processedGrandTotal.netProfit) ? "" : numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</div>
                            </div>
                        </div></div>
                        <div className="card-wrapper"><div className="card my-income-card">
                            <div className="my-income-card-content">
                                <div className="content-title">{t("VALID_TURNOVER")}</div>
                                <div className={"content-value" + (processedGrandTotal.vsales < 0 ? " text-red" : "")}>{isNaN(processedGrandTotal.vsales) ? "" : numberWithCurrencyFormat(parseFloat(processedGrandTotal.vsales), 2, true)}</div>
                            </div>
                        </div></div>
                    </div>
                    <div className="card-wrapper"><div className="card card-package">
                        <div className="card-body p-0">
                            <div className="card-table table-responsive">
                                <table className="table table-kpi-summary">
                                    <thead style={{ borderBottom: "2px solid" }}>
                                        <tr>
                                            <th style={{ textAlign: "left", paddingLeft: "20px" }}>{t("USERNAME")}</th>
                                            <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                            <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                            <th style={{ textAlign: "right" }}>{t("%")}</th>
                                            <th style={{ textAlign: "right", paddingRight: "20px" }}>{t("NET_PROFIT")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                                if (typeof (processedUserTotal[_username]) !== "undefined") {
                                                    return (
                                                        <>
                                                            <tr key={"username_" + _username}>
                                                                <td className={"has-downline" + ((Object.keys(processedUsernameRows).length > 0 && processedUsernameRows[_username] && processedUsernameRows[_username]["hasDownline"]) ? "" : "-false")} style={{ textAlign: "left", paddingLeft: "20px" }} onClick={() => { if (Object.keys(processedUsernameRows).length > 0 && processedUsernameRows[_username] && processedUsernameRows[_username]["hasDownline"]) { getDownlineSales(_username); } }}>{_username}</td>
                                                                <td style={{ textAlign: "left" }} onClick={() => usernameToggle(_username)}>{t("ALL")} <i className={processedUserTotal[_username].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].validTurnover), 2, true)}</td>
                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true)}/130</td>
                                                                <td className={processedUserTotal[_username].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].profit), 2, true)}</td>
                                                            </tr>
                                                            {
                                                                processedUserTotal[_username].show && Object.keys(processedData[_username]).map((_category, j) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                                                <td style={{ textAlign: "left" }}></td>
                                                                                <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => categoryToggle(_username, _category)}>- {t(_category)} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].validTurnover), 2, true)}</td>
                                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                                                <td className={processedCategoryTotal[_username][_category].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].profit), 2, true)}</td>
                                                                            </tr>
                                                                            {
                                                                                processedCategoryTotal[_username][_category].show && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                                                    return (
                                                                                        <>
                                                                                            <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                                                                <td style={{ textAlign: "left" }}></td>
                                                                                                <td style={{ textAlign: "left" }} className="cat-prod">-- {_product}</td>
                                                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].validTurnover), 2, true)}</td>
                                                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                                                                <td className={processedData[_username][_category][_product].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].profit), 2, true)}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                }
                                            })
                                        }

                                        <tr className="subtotal" key={"subtotal"}>
                                            <td style={{ textAlign: "left", paddingLeft: "20px" }} colSpan="2">{t("SUB_TOTAL")}</td>
                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.vsales), 2, true)}</td>
                                            <td style={{ textAlign: "right" }}></td>
                                            <td className={processedSubTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.netProfit), 2, true)}</td>
                                        </tr>

                                        {
                                            Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                                if (typeof (processedUserTotal[_username]) !== "undefined") {
                                                    return (
                                                        <>
                                                            <tr key={"settlement_username_" + _username}>
                                                                <td style={{ textAlign: "left", paddingLeft: "20px" }}>{_username}</td>
                                                                <td style={{ textAlign: "left" }} colSpan="2" onClick={() => settlementToggle(_username)}>{t("SETTLEMENT")} <i className={processedUserTotal[_username].showset ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true)}/130</td>
                                                                <td className={processedUserTotal[_username].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].settlement), 2, true)}</td>
                                                            </tr>
                                                            {
                                                                processedUserTotal[_username].showset && Object.keys(processedData[_username]).map((_category, j) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                                                <td style={{ textAlign: "left" }}></td>
                                                                                <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => settlementCatToggle(_username, _category)}>-{t(_category)} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                                <td style={{ textAlign: "right" }}></td>
                                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                                                <td className={processedCategoryTotal[_username][_category].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].settlement), 2, true)}</td>
                                                                            </tr>
                                                                            {
                                                                                processedCategoryTotal[_username][_category].showset && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                                                    return (
                                                                                        <>
                                                                                            <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                                                                <td style={{ textAlign: "left" }}></td>
                                                                                                <td style={{ textAlign: "left" }} className="cat-prod">--{_product}</td>
                                                                                                <td style={{ textAlign: "right" }}></td>
                                                                                                <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                                                                <td className={processedData[_username][_category][_product].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].settlement), 2, true)}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                }
                                            })
                                        }

                                        <tr className="grandtotal" key={"grandtotal"}>
                                            <td style={{ textAlign: "left", paddingLeft: "20px" }} colSpan="2">{t("GRAND_TOTAL")}</td>
                                            <td style={{ textAlign: "right" }}></td>
                                            <td style={{ textAlign: "right" }}></td>
                                            <td className={processedGrandTotal.netProfit < 0 && "text-red"} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div></div>
                </>
                }

                {Object.keys(processedData).length == 0 && <>
                    <div className="hr-right mt-3 mb-3"></div>
                    <h3 className="mb-3">{t("MY_INCOME")}</h3>
                    <div className="shareholder-my-income">
                        <div className="card-wrapper"><div className="card my-income-card">
                            <div className="my-income-card-content">
                                <div className="content-title">{t("DIRECT_DOWNLINE")}</div>
                                <div className="content-value">{memberDepthData["totalDirectDownlines"]}</div>
                            </div>
                        </div></div>
                        <div className="card-wrapper"><div className="card my-income-card">
                            <div className="my-income-card-content">
                                <div className="content-title">{t("NET_PROFIT")}</div>
                                <div className={"content-value" + (processedGrandTotal.netProfit < 0 ? " text-red" : "")}>{isNaN(processedGrandTotal.netProfit) ? "" : numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</div>
                            </div>
                        </div></div>
                        <div className="card-wrapper"><div className="card my-income-card">
                            <div className="my-income-card-content">
                                <div className="content-title">{t("VALID_TURNOVER")}</div>
                                <div className={"content-value" + (processedGrandTotal.vsales < 0 ? " text-red" : "")}>{isNaN(processedGrandTotal.vsales) ? "" : numberWithCurrencyFormat(parseFloat(processedGrandTotal.vsales), 2, true)}</div>
                            </div>
                        </div></div>
                    </div>
                    <div className="card-wrapper"><div className="card card-package">
                        <div className="card-body p-0">
                            <div className="card-table table-responsive">
                                <table className="table table-kpi-summary">
                                    <thead style={{ borderBottom: "2px solid" }}>
                                        <tr>
                                            <th style={{ textAlign: "left", paddingLeft: "20px" }}>{t("USERNAME")}</th>
                                            <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                            <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                            <th style={{ textAlign: "right" }}>{t("%")}</th>
                                            <th style={{ textAlign: "right", paddingRight: "20px" }}>{t("NET_PROFIT")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="5">{t("NO_REPORT_FOR_THE_DATE")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div></div>
                </>
                }
            </div>
        </div>
    );
};

export default Summary;
