import React, { useEffect, useState } from "react";
import { WebUrl } from "../../util/Constant";
import NavigationButton from "./NavigationButton";
import { useSelector } from "react-redux";
import MenuIcon from "../../assets/img/v3/right-menu-icon.svg";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const {
    title,
    history,
    url,
    slot,
    state,
    hasBackButton = true,
    hasRightMenu = false,
    rightMenuList = [],
    fromDashboard = false,
    setShowModal = false,
    ...rest
  } = props;
  const { t, i18n } = useTranslation();
  const [showMenuList, setShowMenuList] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  function handleUrl(url) {
      if (window.ReactNativeWebView) {
        if (fromDashboard) {
            return WebUrl._URL_MAIN;
        }

      return null;
    }
    return url;
  }

  return (
    <div
      className={
        "content-header home-header shared-header" +
        (activeTheme === 0 ? "" : " modern-header")
      }
    >
      <div className="top-header">
        <div>
          {hasBackButton && (
            <NavigationButton
              class="btn btn-icon"
              icon="fa fa-angle-left"
              history={history}
              url={handleUrl(url)}
              state={state}
            />
          )}
          {title}
        </div>
        {slot && <div>{slot}</div>}
        {hasRightMenu &&
          <div id="setShowMenuList">
            <div className="top-right-menu" onClick={() => setShowMenuList(!showMenuList)}>
              <img src={MenuIcon} />
            </div>
            {showMenuList &&
              <div className="top-right-menu-dropdown">
                {rightMenuList.map((item, index) => {
                  return (
                    <div className={"dropdown-option " + (index % 2 == 0 ? 'light' : 'dark')} key={index} onClick={() => {
                      if (item.url) {
                        history.push({
                          pathname: item.url,
                          state: {
                            id: item.stateId ? item.stateId : 0,
                            url: item.from,
                            from: item.from
                          }
                        });
                      }
                      else if (props.setShowModal) {
                        props.setShowModal(true);
                        setShowMenuList(false);
                      }
                      else {
                        //do nothing
                      }
                    }}>
                      {t(item.title)}
                    </div>
                  )
                })}
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default Header;
