import { _SAVE_LANGUAGE_ID } from './LanguageAction';

const _INITIALSTATE = {
    languageId: 1,
    retain: false
};

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIALSTATE, action) => {
    switch (action.type) {
        case _SAVE_LANGUAGE_ID:
            return {
                ...state,
                languageId: action.languageId,
                retain: action.retain
            }
            break;
        default:
            return state;
            break;
    }
};