import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import livechatIcon from "../../assets/img/Live_Chat_Icon/live-chat.png"
import {
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { ApiKey, ApiUrl, WebUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { map } from "lodash";
import { stringIsNullOrEmpty } from "../../util/Util";
import {v4 as uuidv4} from 'uuid';

const LiveChatModal = (props) => {
  const {
    title,
    history,
    url,
    slot,
    state,
    hasBackButton = true,
    hasRightMenu = false,
    rightMenuList = [],
    fromDashboard = false,
    setShowModal = false,
    ...rest
  } = props;
  const { t, i18n } = useTranslation();
  const [isModalShow, setIsModalShow] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const [communicationChannel, setCommunicationChannel] = useState([]);

  const [toggleModal, setToggleModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState();
  const userName = useSelector(
    (state) => state.authState.userData.username
  );

  const userId = useSelector(
    (state) => state.authState.userData.userId
  );

  const { isLoggedIn } = useSelector((state) => state.authState);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true");
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
    setCommunicationChannel(data)
    }
  }

  useEffect(() => {
    if (selectedChannel) {
      if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
        setToggleModal(true);
      }
    }
  }, [selectedChannel]);


  return (
    <div className="live-chat" >
      <div className="live-chat-box">
        <img src={livechatIcon} className="live-chat-img-button" onClick={() => { setIsModalShow(true) }}>
        </img>
      </div>
      <Modal
        id="custom-modal3"
        contentClassName="modal-brand modal-bottom-live-chat"
        fade={true}
        isOpen={isModalShow}
        toggle={() => {
          setIsModalShow(false);
        }}
        centered
      >
        <ModalBody>
          <form >
            <div className="flex-box-centered mb-1">
              <h2 className="custom-modal-title mb-0 mr-3">{t("WELCOME_TO_LIVECHAT")}</h2>
            </div>
            <hr style={{ borderTop: "1px solid grey" }}></hr>
            <div className="live-chat-container">
              {communicationChannel && communicationChannel.length && map(communicationChannel, (channel, index) => {
                return (
                  <div className="live-chat-icon" onClick={() => {
                    if (channel.channelName === "Livechat") {
                      // window.open(channel.actionUrl, "_blank")
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            action: "chat",
                            url: `${channel.cta}`
                          })
                        );
                      }
                      else {
                        window.open(channel.cta, "_blank", 'live-chat');
                      }
                    }
                    else if (!stringIsNullOrEmpty(channel.cta)) {
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            action: "chat",
                            url: `${channel.cta}`
                          })
                        );
                      }
                      else {
                        if(channel.channelName.match("WeChat")){
                          let openid = uuidv4();
                          const ctaUrl = channel.cta + `/?openid=${isLoggedIn ? userId : openid}&nickname=${isLoggedIn ? userName : openid}&robotName=i8CS`;
                          window.open(ctaUrl, "_blank", 'live-chat');
                        }
                        else {
                          window.location.href = channel.cta;
                        }
                      }
                    }
                    else if (channel.channelName.match("WeChat")) {
                      setSelectedChannel(channel);
                      setIsModalShow(false)
                      setToggleModal(true);
                    } else {
                      setToggleModal(false)
                    }
                  }}>
                  <div className="live-chat-gird">
                    <img src={channel.channelImage} style={{ width: "32px", height: "32px" }}></img>
                    </div>
                    <a style={{ fontSize:"10px" }}>{channel.channelName}
                    </a>
                    </div>
                  )
              })
              }
            </div>
            
          </form>
        </ModalBody>
      </Modal>

      <Modal fade={false} isOpen={toggleModal} toggle={() => { setToggleModal(false) }} centered>
        <ModalHeader toggle={() => { setToggleModal(false) }} style={{ color:"black" }}>{selectedChannel?.channelDescription}</ModalHeader>
        <ModalBody>
          <img src={selectedChannel?.attachment} className="w-100" />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LiveChatModal;