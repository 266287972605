import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { WebUrl } from "../../util/Constant";
import { mdiCompassOutline } from "@mdi/js";

/// <summary>
/// Author: -
/// </summary>
const ReportMenu = (props) => {
  const { t } = useTranslation();
  const _history = useHistory();

  const _MENU_OPTION = [
    {
      name: "WALLET_STATEMENT",
      imageUrl: require("../../assets/img/v3/reports_wallet-statement.svg"),
      redirectUrl: WebUrl._URL_WALLET_HISTORY,
    },
    {
      name: "BONUS_STATEMENT",
      imageUrl: require("../../assets/img/v3/reports_bonus-report.svg"),
      redirectUrl: WebUrl._URL_BONUS_HISTORY,
    },
    {
      name: "ROLLOVER_STATEMENT",
      imageUrl: require("../../assets/img/v3/reports_rollover-report.svg"),
      redirectUrl: WebUrl._URL_ROLLOVER_HISTORY,
    },
    {
      name: "GAME_RECORD",
      imageUrl: require("../../assets/img/v3/reports_game-record.svg"),
      redirectUrl: WebUrl._URL_GAME_HISTORY,
    },
    //{
    //  name: "INTERNAL_TRANSFER",
    //  imageUrl: require("../../assets/img/icon/bonus-report-icon.png"),
    //  redirectUrl: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
    //},
    //{
    //  name: "WALLET_TRANSFER",
    //  imageUrl: require("../../assets/img/icon/bonus-report-icon.png"),
    //  redirectUrl: WebUrl._URL_WALLET_TRANSFER_HISTORY,
    //},
  ];

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("TRANSACTION_HISTORY")}
      />
      <div className="container" style={{ padding: "0" }}>
        <div className="report-menu">
          {_MENU_OPTION.map((menu, index) => {
            return (
              <div key={index} className="card-panel mb-3">
                <Link to={{
                  pathname: menu["redirectUrl"],
                  state: { from: window.location.pathname }
                }}>
                  <div className="report-menu-wrapper">
                    <div>
                      <div
                        className="report-menu-details"
                      >
                        <div className="custom-icon-box">
                          <img
                            style={{ objectFit: "contain" }}
                            src={menu["imageUrl"]}
                          />
                        </div>
                        <div>{t(menu["name"])}</div>
                      </div>
                    </div>
                    <div><i className="fas fa-chevron-right"></i></div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ReportMenu;
