import React from "react";
import { Route, withRouter } from "react-router-dom";
import { _ROUTES, _LOGIN_ROUTE } from "./../../config/page-route.jsx";
import { PageSettings } from "./../../config/page-settings.js";
import { SessionKey, WebUrl } from "../../util/Constant";
import { connect } from "react-redux";
import LiveChat from "react-livechat";
import appsettings from "../../appsettings-fe.json";

function setTitle(path, routeArray) {
  var pageTitle;
  //for (var i = 0; i < routeArray.length; i++) {
  //  if (routeArray[i].path === path) {
  //    pageTitle = "i8 | " + routeArray[i].title;
  //  }
  //}
  //document.title = pageTitle ? pageTitle : "i8";
}

class Content extends React.Component {
  constructor(props) {
    super(props);
    this._TIMEOUT_TIME = 60 * 60 * 1000;
    this.state = {
      routes: _ROUTES,
      liveChatLicense: parseInt(appsettings.ApplicationConfig.liveChatLicense),
      currentPage: this.props.history.location.pathname,
    };
    this.liveChat = null;
  }

  /// <summary>
  /// Author :
  /// </summary>
  PrivateRoute(index, routeData) {
    return (
      <Route
        key={index}
         exact={
          routeData.authRequired && !this.props.authState.isLoggedIn
            ? _LOGIN_ROUTE.exact
            : routeData.exact
         }
         path={
          routeData.authRequired && !this.props.authState.isLoggedIn
            ? _LOGIN_ROUTE.path
            : routeData.path
         }
         component={
          routeData.authRequired && !this.props.authState.isLoggedIn
            ? _LOGIN_ROUTE.component
            : routeData.component
        }
        // exact={routeData.exact}
        // path={routeData.path}
        // component={routeData.component}
      />
    );
  }

  componentWillUpdate() {
    if (this.currentPage !== this.props.history.location.pathname) {
      this.currentPage = this.props.history.location.pathname;
      this.resetTimeout();
    }
  }

  componentDidMount() {
    if (this.props.history.location.pathname !== "/login") {
      this.startTimeout = setTimeout(() => {
        window.location.reload();
      }, this._TIMEOUT_TIME);
    }
    setTitle(this.props.history.location.pathname, Route);
  }

  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, Route);
    });
  }

  /// <summary>
  /// Author : -
  /// Edit: added localstorage
  /// </summary>
  resetTimeout = () => {
    clearTimeout(this.startTimeout);
    if (
      this.currentPage !== "/login" &&
      this.props.history.location.pathname !== "/login"
    ) {
      this.startTimeout = setTimeout(() => {
        window.location.reload();
      }, this._TIMEOUT_TIME);
    }
  };

  render() {
    this.state.routes = _ROUTES.filter((route) => {
      return (
        this.props.authState.isLoggedIn ||
        !route.authRequired ||
        route.path == this.props.history.location.pathname
      );
    });
    {
      /*<span style={{ color: "black" }}>Content</span>)*/
    }
    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            {this.state.routes.map((route, index) =>
              this.PrivateRoute(index, route)
            )}
            <LiveChat license={this.state.liveChatLicense} />
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, null)(withRouter(Content));
