import React, { useState, useEffect, useCallback } from "react";
import "react-simple-keyboard/build/css/index.css";
import { map, debounce, isNull } from "lodash";
import ApiCms from "util/ApiCms";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	SessionKey,
	LanguageOption,
	Language,
	LanguageSelectStyle,
} from "../../util/Constant";
import { useHistory } from "react-router-dom";
import {
	showResponseMessage,
	setBusy,
	setIdle,
} from "../../redux/AppAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import Header from "../../components/custom/Header";
import HelpCenterMainBanner from "../../assets/img/help-centre/help-centre-banner.png";
import { Accordion, Card } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { saveLanguageId } from "../../redux/LanguageAction";
import Select from "react-select";
import { createFormBody, createMultiPartFormBody } from "../../util/Util";
import Input from "../../components/custom/Input";

function CustomToggle({ children, eventKey }) {
	const wrapOnClick = useAccordionButton(eventKey, () =>
		console.log("totally custom!")
	);

	return <div onClick={wrapOnClick}>{children}</div>;
}

const HelpCentre = (props) => {
	const { t, i18n } = useTranslation();
	const [searchText, setSearchText] = useState("");
	const [breadcrumbs, updateBreadcrumbs] = useState([]);
	const [categories, setCategories] = useState([]);
	const [channels, setChannels] = useState([]);
	let _history = useHistory();
	var _dispatch = useDispatch();
	var _userData = useSelector((state) => state.authState.userData);

	const onClick = (breadcrumbItems) => {
		updateBreadcrumbs((arr) => [...arr, breadcrumbItems]);
	};
	const [activeKey, setActiveKey] = useState(0);
	const [displayDataIndex, setDisplayDataIndex] = useState(-1);

	useEffect(() => {
		fetchData();
		init();
	}, [localStorage.getItem(SessionKey._LANGUAGE)]);

	async function init() {
		_dispatch(setBusy());
		var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_COMMUNICATION_CHANNELS +
				"?all=true&languageCode=" +
				localStorage.getItem(SessionKey._LANGUAGE)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setChannelData(responseJson[ApiKey._API_DATA_KEY]);
		}

		_dispatch(setIdle());
	}

	async function fetchData() {
		_dispatch(setBusy());

		const { data } = await ApiCms.get(
			"/categories/query" +
				"?locale=" +
				localStorage.getItem(SessionKey._LANGUAGE)
		);

		const enData = await ApiCms.get("/categories/query" + "?locale=en");

		const { data: categoriesList } = data;
		setCategories(categoriesList);

		_dispatch(setIdle());
	}

	async function GoToCategory(slug, category_id) {
		if (slug) {
			_history.push({
				pathname: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", slug),
				state: {
					category_id: category_id,
				},
			});
		}
	}

	async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
		if (slug) {
			_history.push({
				pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
					.replace(":category", slug)
					.replace(":sub_category", sub_slug),
				state: {
					category_id: category_id,
					sub_category_id: sub_category_id,
				},
			});
		}
	}

	async function GoToFaq(
		slug,
		sub_slug,
		faq,
		category_id,
		sub_category_id,
		faq_id
	) {
		if (sub_slug) {
			_history.push({
				pathname: WebUrl._URL_HELP_CENTRE_FAQ
					.replace(":category", slug)
					.replace(":sub_category", sub_slug)
					.replace(":faq", faq),
				state: {
					category_id: category_id,
					sub_category_id: sub_category_id,
					faq_id: faq_id,
				},
			});
		}
	}

	function setChannelData(data) {
		var channels = [];
		data
			.filter((i) => i.channelName.match(/Livechat/i))
			.map((d, index) => {
				var channel = {
					channelName: d.channelName,
					name: d.channelDescription,
					description: "",
					actionUrl: d.cta,
					image: d.channelImage,
					attachment: d.attachment,
				};

				channels.push(channel);
			});
		setChannels(channels);
	}

	const searchOnChange = useCallback(
		debounce(async (keyword) => {
			let url =
				"/categories/query" +
				"?locale=" +
				localStorage.getItem(SessionKey._LANGUAGE);

			if (keyword.target.value.length) {
				keyword = keyword.target.value;
				url = url + "&search=" + keyword;
			}

			const { data } = await ApiCms.get(url);
			const { data: categoriesList } = data;

			setCategories(categoriesList);

			setSearchText(keyword);
		}, 500),
		[]
	);

	const onChangeLanguage = async (selectedLanguage) => {
		i18n.changeLanguage(selectedLanguage);

		_dispatch(saveLanguageId(selectedLanguage, true));

		let data = {};
		data["languageSetting"] = selectedLanguage;

		// Silently update user's language settings
		await ApiEngine.post(
			ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
			createMultiPartFormBody(data)
		);

		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					action: "language",
					language: selectedLanguage,
				})
			);
		}
	};

	const handleDownlineOnClick = async (index, e) => {
		_dispatch(setBusy());
		e.preventDefault();
		if (displayDataIndex === index) {
			setDisplayDataIndex(-1);
		} else {
			setDisplayDataIndex(index);
		}
		_dispatch(setIdle());
	};

	return (
		<>
			<Header
				history={_history}
				url={WebUrl._URL_MAIN}
				title={t("HELP_CENTRE")}
				slot={
					<Select
						className="lang-selector"
						menuPlacement="auto"
						menuPosition="fixed"
						options={LanguageOption.map((x) => {
							return { label: x["prefix"], value: x["value"] };
						})}
						isSearchable={false}
						styles={LanguageSelectStyle}
						placeholder={
							LanguageOption.filter(
								(option) =>
									option.value == localStorage.getItem(SessionKey._LANGUAGE)
							)[0] !== undefined
								? LanguageOption.filter(
										(option) =>
											option.value == localStorage.getItem(SessionKey._LANGUAGE)
								  )[0].prefix
								: "EN"
						}
						value={
							LanguageOption.filter(
								(option) =>
									option.value == localStorage.getItem(SessionKey._LANGUAGE)
							)[0] !== undefined
								? LanguageOption.filter(
										(option) =>
											option.value == localStorage.getItem(SessionKey._LANGUAGE)
								  )[0].prefix
								: Language._ENGLISH
						}
						onChange={(e) => {
							onChangeLanguage(e.value);
						}}
					/>
				}
			/>
			<div className="search-input-box">
				<div className="form-group">
					<div className="input-group mb-3">
						<input
							className="help-centre-search-input"
							id="hsearch-input"
							type="text"
							placeholder={t("SEARCH")}
							name="search"
							onChange={searchOnChange}
						></input>
						<div className="input-group-append">
							<span className="input-group-text">
								<img
									src={require("../../assets/img/v3/search-blue.png")}
									alt="search"
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="hr-right mt-3 mb-3"></div>
			<Accordion className="helpcenter-accordion">
				<ul
					className="ul-news-list custom-dynamic-table-container"
					style={{ height: "unset" }}
				>
					{categories && categories.length === 0
						? t("NO_DATA_FOUND")
						: categories.map((item, index) => {
								return (
									!isNull(item.publishedAt) && (
										<Accordion.Item key={index} eventKey={index}>
											<Accordion.Header
												onClick={(e) => {
													handleDownlineOnClick(index, e);
												}}
											>
												<div>
													<strong className="d-block">{item.title}</strong>
												</div>
												<i
													className={
														"fas fa-chevron-" +
														(displayDataIndex === index ? "down" : "right")
													}
												></i>
											</Accordion.Header>
											<Accordion.Body>
												<div key={index} className="helpcenter-data-wrapper">
													{item.sub_categories &&
														item.sub_categories.length &&
														item.sub_categories.map((item1, index) => {
															return (
																!isNull(item1.publishedAt) &&
																(item1.faqs.length == 0 ? (
																	<a
																		onClick={() =>
																			GoToSubCategory(
																				item.slug,
																				item1.slug,
																				item.category_id,
																				item1.sub_category_id
																			)
																		}
																		className="d-flex justify-content-between align-items-center list-link cursor-pointer"
																	>
																		{item1.Title}
																	</a>
																) : (
																	<>
																		{item1.faqs &&
																			item1.faqs.length &&
																			item1.faqs.map((item2, index) => {
																				return (
																					!isNull(item2.publishedAt) && (
																						<a
																							onClick={() =>
																								GoToFaq(
																									item.slug,
																									item1.slug,
																									item2.slug,
																									item.category_id,
																									item1.sub_category_id,
																									item2.faq_id
																								)
																							}
																							className="d-flex justify-content-between align-items-center list-link cursor-pointer"
																						>
																							{item2.Title}
																						</a>
																					)
																				);
																			})}
																	</>
																))
															);
														})}
												</div>
											</Accordion.Body>
										</Accordion.Item>
									)
								);
						  })}
				</ul>
			</Accordion>
			<div className="hr-left mt-3 mb-3"></div>
			<p className={"f-w-800 text-brand modern"}>
				{t("OTHER_WAY_TO_GET_HELP")}
			</p>
			<Accordion className="helpcenter2-accordion">
				<ul
					className="ul-news-list custom-dynamic-table-container"
					style={{ height: "unset" }}
				>
					<Accordion.Item>
						{channels.map((channel, index) => {
							return (
								<>
									<Accordion.Header
										onClick={(e) => {
											if (window.ReactNativeWebView) {
												window.ReactNativeWebView.postMessage(
														JSON.stringify({
																action: "chat",
																url: `${channel.actionUrl}`
														})
												);
											}
											else {
												window.open(channel.actionUrl, "_blank", "live-chat");
											}
										}}
									>
										<div>
											<strong className="d-block">{t("LIVE_CHAT")}</strong>
										</div>
										<i className={"fas fa-chevron-right"}></i>
									</Accordion.Header>
								</>
							);
						})}
					</Accordion.Item>
					<Accordion.Item>
						<Accordion.Header
							onClick={(e) => {
								_history.push({
									pathname: WebUrl._URL_CONTACT_US,
								});
							}}
						>
							<div>
								<strong className="d-block">{t("CONTACT")}</strong>
							</div>
							<i className={"fas fa-chevron-right"}></i>
						</Accordion.Header>
					</Accordion.Item>
				</ul>
			</Accordion>
		</>
	);
};
export default HelpCentre;
