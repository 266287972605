import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, WebUrl, SessionKey, Language, LanguageOption, LanguageSelectStyle, PhoneNumberPrefix } from "../../util/Constant";
import Input from "../../components/custom/Input";
import PhoneNumber from "../../components/custom/PhoneNumber";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { saveLanguageId } from "../../redux/LanguageAction";
import Select from "react-select";
import PinInput from 'react-pin-input';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import Icon from '@mdi/react'
import { mdiInformation, mdiLockOutline, mdiNumeric, mdiLogout, mdiBoxShadow } from '@mdi/js'
import { updateUserData, performLogout } from "../../redux/AuthAction";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import classicImage from "../../assets/img/classicSampleImage.PNG";
import modernImage from "../../assets/img/modernSampleImage.PNG";
import { map } from "lodash";
import DateTime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/el';
import closedEye from "../../assets/img/v3/hide.png";
import openEye from "../../assets/img/v3/eye-blue.png";
import DatePicker from "react-datepicker";
import id from "date-fns/locale/id";
import zh from 'date-fns/locale/zh-CN';
import vi from 'date-fns/locale/vi';
import th from 'date-fns/locale/th';
import ms from 'date-fns/locale/ms';
import bn from 'date-fns/locale/bn';

import DateRangePicker from "react-bootstrap-daterangepicker";
/// <summary>
/// Author: - 
/// </summary>
const Settings = props => {
    var _history = useHistory();
    var { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    var _userData = useSelector(state => state.authState.userData);

    const [verificationModal, setVerificationModal] = useState(false);


    const {
        register: profileRegister,
        errors: profileErrors,
        setError: setProfileError,
        handleSubmit: handleProfileSubmit,
        setValue: setProfileValue,
        getValues: getProfileValues,
        clearErrors: clearProfileErrors,
        watch: profileWatch
    } = useForm();

    const {
        errors: resetPasswordErrors,
        handleSubmit: handleResetPasswordSubmit,
        watch: resetPasswordWatch,
        setValue: setResetPasswordValue,
        setError: setResetPasswordError,
        clearErrors: clearResetPasswordErrors,
        register: resetPasswordRegister
    } = useForm();

    const {
        register: codeRegister,
        errors: codeErrors,
        getValues: getCodeValues,
        handleSubmit: handleCodeSubmit
    } = useForm();

    const {
        handleSubmit: pinHandleSubmit
    } = useForm();

    const {
        handleSubmit, register, errors
    } = useForm();

    const _COUNTDOWN_PERIOD = 120;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 5000;

    const [showAlert, setShowAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [showThemeAlert, setShowThemeAlert] = useState(false);
    const [failMessage, setFailMessage] = useState("");
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [member, setMember] = useState();
    const [sentCode, setSentCode] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [countdownPeriodEmail, setCountdownPeriodEmail] = useState(0);
    const [oldPinNumber, setOldPinNumber] = useState("");
    const [pinNumber, setPinNumber] = useState("");
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");

    const [oldVipPinNumber, setOldVipPinNumber] = useState("");
    const [vipPinNumber, setVipPinNumber] = useState("");
    const [vipPinNumberConfirm, setVipPinNumberConfirm] = useState("");

    const [tempPinNumber, setTempPinNumber] = useState("");

    const [oldPinNumberFocused, setOldPinNumberFocused] = useState(0);
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);

    const [oldVipPinNumberFocused, setOldVipPinNumberFocused] = useState(0);
    const [vipPinNumberFocused, setVipPinNumberFocused] = useState(0);
    const [vipPinNumberConfirmFocused, setVipPinNumberConfirmFocused] = useState(0);

    const [tempPinNumberFocused, setTempPinNumberFocused] = useState(0);

    const [checkPinExist, setCheckPinExist] = useState(true);
    const [checkVipPinExist, setCheckVipPinExist] = useState(true);
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [showVipPinEntry, setShowVipPinEntry] = useState(false);
    const [allowPinClick, setAllowPinClick] = useState(true);
    const [selectedTheme, setSelectedTheme] = useState(0);
    const [isShareholder, setIsShareholder] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const [birthModal, setBirthModal] = useState(false);
    const [birthDateString, setBirthDateString] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneCountry, setPhoneCountry] = useState("");
    const [memberId, setMemberId] = useState("");
    const [tac, setTAC] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmMessage, setConfirmMessage] = useState(false);
    const [email, setEmail] = useState("");
    const [sendEmailCode, setSendEmailCode] = useState(false);

    const [emailVerificationModal, setEmailVerificationModal] = useState(false);

    const [userInfoModalVisible, setUserInfoModalVisible] = useState(false);
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
    const [changePinModalVisible, setChangePinModalVisible] = useState(false);


    const [isHiddenCurrent, setIsHiddenCurrent] = useState(closedEye);
    const [currentLoginPasswordVisible, setCurrentLoginPasswordVisible] = useState(false);

    const [isHiddenNew, setIsHiddenNew] = useState(closedEye);
    const [newLoginPasswordVisible, setNewLoginPasswordVisible] = useState(false);

    const [isHiddenConfirm, setIsHiddenConfirm] = useState(closedEye);
    const [confirmLoginPasswordVisible, setConfirmLoginPasswordVisible] = useState(false);

    const [pinTabActive, setPinTabActive] = useState(1);
    const [datePickerLanguage, setDatePickerLanguage] = useState();
    const [unclaimComm, setUnclaimComm] = useState("");
    const [grandTotalRewards, setGrandTotalRewards] = useState("");
    const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [birthdayString, setBirthdayString] = useState("");
    const [verifyModal, setVerifyModal] = useState(false);
    const pinList = [
        {
            id: 1,
            title: 'Withdrawal PIN',
        },
        {
            id: 2,
            title: 'Shareholder PIN',
        },
    ];


    const [currentPinRef, setCurrentPinRef] = useState(0);
    const pin_1 = useRef(null);
    const pin_2 = useRef(null);
    const pin_3 = useRef(null);
    const pin_vip_1 = useRef(null);
    const pin_vip_2 = useRef(null);
    const pin_vip_3 = useRef(null);
    const pin_temp = useRef(null);
    const pin_vip_temp = useRef(null);
    const keyboard = useRef();

    const _MENU_OPTION = {
        "GeneralSettings": 1,
        "ChangePassword": 2,
        "ChangePin": 3,
        "ChangeShareholderPin": 4,
    }
    const [activeMenu, setActiveMenu] = useState(_MENU_OPTION.GeneralSettings);
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState("61");
    const phoneNumberPrefix = [
        {
            label: "+61",
            countryCode: "AU",
            value: "61",
        },
        {
            label: "+60",
            countryCode: "MY",
            value: "60",
        },
        {
            label: "+62",
            countryCode: "ID",
            value: "62",
        },
        {
            label: "+66",
            countryCode: "TH",
            value: "66",
        },
        {
            label: "+84",
            countryCode: "VI",
            value: "84",
        },
        {
            label: "+93",
            countryCode: "AF",
            value: "93",
        },
        {
            label: "+355",
            countryCode: "AL",
            value: "355",
        },
    ];

    const [mobileVersion, setMobileVersion] = useState(null);
    const maxDate = moment().subtract(18, "years");
    const minDate = moment().subtract(100, "years");

    /// <summary>
    /// Author: - 
    /// </summary>
    useEffect(() => {
        init();

        return () => {
            setIsContentLoaded(false);
        }
    }, []);

    const toggleHiddenCurrent = () => {
        setCurrentLoginPasswordVisible(!currentLoginPasswordVisible);

        if (isHiddenCurrent == closedEye) {
            setIsHiddenCurrent(openEye);
        } else {
            setIsHiddenCurrent(closedEye);
        }
    };

    const toggleHiddenNew = () => {
        setNewLoginPasswordVisible(!newLoginPasswordVisible);

        if (isHiddenNew === closedEye) {
            setIsHiddenNew(openEye);
        } else {
            setIsHiddenNew(closedEye);
        }
    };

    const toggleHiddenConfirm = () => {
        setConfirmLoginPasswordVisible(!confirmLoginPasswordVisible);

        if (isHiddenConfirm === closedEye) {
            setIsHiddenConfirm(openEye);
        } else {
            setIsHiddenConfirm(closedEye);
        }
    };

    /// <summary>
    /// Author: - 
    /// </summary>
    async function init() {
        moment.locale("en")
        let params = {};
        let responseJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCheckPinExist(false);
        }
        responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] === true) {
                let responseJson2 = await ApiEngine.post(
                    ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
                    createFormBody(params)
                );

                if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
                    setCheckVipPinExist(false);
                }
            }

            setMember(responseJson[ApiKey._API_DATA_KEY]);
            setIsShareholder(responseJson[ApiKey._API_DATA_KEY]["isShareHolder"]);
            setSelectedTheme(responseJson[ApiKey._API_DATA_KEY]["selectedTheme"]);
            setIsContentLoaded(true);
            setProfileValue('username', responseJson[ApiKey._API_DATA_KEY]['username']);
            setProfileValue('phoneNumber', "+" + responseJson[ApiKey._API_DATA_KEY]['phoneNumber']);
            setBirthDateString(responseJson[ApiKey._API_DATA_KEY]['birthDateString']);
            setSelectedPhonePrefix(String(responseJson[ApiKey._API_DATA_KEY]['phoneNumber']).substring(0, 2));
            let numPhone = responseJson[ApiKey._API_DATA_KEY]['phoneNumber'].length;
            setPhoneNumber(String(responseJson[ApiKey._API_DATA_KEY]['phoneNumber']).substring(2, numPhone));
            setMemberId(responseJson[ApiKey._API_DATA_KEY]['id']);
            setEmail(responseJson[ApiKey._API_DATA_KEY]['email']);
            checkENB(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }
    /// <summary>
    /// Author: - 
    /// </summary>
    const updateProfile = async (data) => {
        if (!sentCode || countdownPeriod === 0) {
            let codeIsSuccess = await sendVerificationCode();
            if (!codeIsSuccess) {
                setPhoneNumberError(true);
            }
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const emailVerifiaction = async (data) => {
        if (!sendEmailCode || countdownPeriodEmail === 0) {
            let codeIsSuccess = await sendEmailVerificationCode();
            if (codeIsSuccess[0].success == false) {
                _dispatch(showResponseMessage(codeIsSuccess[0].success, codeIsSuccess[0].message));
            } else {
                setBirthModal(false);
                setVerifyModal(true);
            }
        }
    }

    /// <summary>
    /// Author: - 
    /// </summary>
    const resetPassword = async (data) => {
        data['memberId'] = _userData.userId;

        var responseJson = await ApiEngine.post(ApiUrl._API_MEMBER_RESET_PASSWORD, createMultiPartFormBody(data));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setResetPasswordValue('currentPassword', '');
            setResetPasswordValue('password', '');
            setResetPasswordValue('confirmPassword', '');
            clearResetPasswordErrors();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }

    /// <summary>
    /// Author: - 
    /// </summary>
    const onChangeLanguage = async (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);

        _dispatch(saveLanguageId(selectedLanguage, true));

        let data = {};
        data['languageSetting'] = selectedLanguage;

        // Silently update user's language settings
        await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_LANGUAGE, createMultiPartFormBody(data));

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: "language",
                    language: selectedLanguage,
                })
            );
        }

    }

    /// <summary>
    /// Author: Wong
    /// </summary>
    const sendVerificationCode = async () => {
        let returnBool = false;
        _dispatch(setBusy());
        let formatPhoneNumber = selectedPhonePrefix + phoneNumber;
        setFormattedPhoneNumber(formatPhoneNumber);
        await ApiEngine.post(ApiUrl._API_SEND_VERIFICATION_CODE, createMultiPartFormBody({ phoneNumber: formatPhoneNumber }))
            .then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    setSentCode(true);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                    setVerificationModal(true);
                    returnBool = true;
                    setPhoneNumberError(false);
                }
            })
        _dispatch(setIdle());
        return returnBool;
    }

    const sendEmailVerificationCode = async () => {
        let returnBool = [];
        _dispatch(setBusy());
        await ApiEngine.post(ApiUrl._API_SEND_EMAIL_VERIFICATION_CODE, createMultiPartFormBody({ email: email }))
            .then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                    setSendEmailCode(true);
                    setCountdownPeriodEmail(_COUNTDOWN_PERIOD);
                    setEmailVerificationModal(true);
                } else {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: t("INVALID_EMAIL") 
                        }]
                }
            })
        _dispatch(setIdle());
        return returnBool;
    }

    const changePin = async () => {
        let params = {
            "oldPinExist": checkPinExist,
            "oldPinNumber": oldPinNumber,
            "pinNumber": pinNumber,
            "confirmPinNumber": pinNumberConfirm
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_SET_MEMBER_PIN_NUMBER, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCheckPinExist(true);
        }

        checkPinExist && pin_1['current'].clear();
        pin_2['current'].clear();
        pin_3['current'].clear();
        setOldPinNumber("");
        setPinNumber("");
        setPinNumberConfirm("");
        setTempPinNumber("");
        setOldPinNumberFocused(0);
        setPinNumberFocused(0);
        setPinNumberConfirmFocused(0);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

    }

    const changeShareholderPin = async () => {
        let params = {
            "oldPinNumber": oldVipPinNumber,
            "pinNumber": vipPinNumber,
            "confirmPinNumber": vipPinNumberConfirm
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCheckVipPinExist(true);
        }

        checkVipPinExist && pin_vip_1['current'].clear();
        pin_vip_2['current'].clear();
        pin_vip_3['current'].clear();
        setOldVipPinNumber("");
        setVipPinNumber("");
        setVipPinNumberConfirm("");
        setTempPinNumber("");
        setOldVipPinNumberFocused(0);
        setVipPinNumberFocused(0);
        setVipPinNumberConfirmFocused(0);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

    }

    /// <summary>
    /// Author: Wong
    /// </summary>
    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

    useEffect(() => {

        var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].code) : "en";
        if (language == "en") {
            setDatePickerLanguage("en");
        }
        else if (language == "zh") {
            setDatePickerLanguage(zh);

        }
        else if (language == "ms") {
            setDatePickerLanguage(ms);
        }
        else if (language == "th") {
            setDatePickerLanguage(th);
        }
        else if (language == "vi") {
            setDatePickerLanguage(vi);
        }
        else if (language == "id") {
            setDatePickerLanguage(id);
        }
    }, []);
    /// <summary>
    /// Author: Wong
    /// </summary>
    useEffect(() => {
        if (countdownPeriodEmail > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriodEmail - 1;
                setCountdownPeriodEmail(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriodEmail]);

    /// <summary>
    /// Author: Wong
    /// </summary>
    const verifyPhoneNumber = async () => {
        let success = true;
        let message = t("OPERATION_SUCCESS");
        let formatPhoneNumber = selectedPhonePrefix + phoneNumber;
        await ApiEngine.post(ApiUrl._API_VERIFY_PHONE_NUMBER, createMultiPartFormBody({ verificationCode: getCodeValues('verificationCode'), phoneNumber: formatPhoneNumber }))
            .then(async (response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    let data = {};
                    data['phoneNumber'] = formatPhoneNumber;
                    data['isMemberOperate'] = true;
                    await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_PHONE_NUMBER, createMultiPartFormBody(data)).then((responseJson) => {
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            clearProfileErrors("phoneNumber");
                        }
                        else {
                            success = false;
                            message = responseJson[ApiKey._API_MESSAGE_KEY];
                        }
                    });
                    setVerificationModal(false);
                    init();
                }
                else {
                    success = false;
                    message = response[ApiKey._API_MESSAGE_KEY];
                }
                _dispatch(showResponseMessage(success, message));
            })
    }

    /// <summary>
    /// Author: 
    /// </summary>
    const verifyEmail = async () => {
        let returnBool = [];
        await ApiEngine.post(ApiUrl._API_VERIFY_EMAIL, createMultiPartFormBody({ verificationCode: tac }))
            .then(async (response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                } else {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                }
            })
        return returnBool;
    }

    function onKeyPress(key) {
        setAllowPinClick(false);
        // 1st: have to check currentPinRef
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = tempPinNumber;
        if (key !== "{bksp}") {
            if (currentPinRef === 1) {
                currentIndex = tempPinNumberFocused;
                pin_temp["current"]["elements"][currentIndex].focus();
                pin_1["current"]["elements"][currentIndex].state.value = key;
                pin_temp["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setTempPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setTempPinNumberFocused(currentIndex);
                }
                else {
                    setOldPinNumber(pinStr);
                    setTempPinNumber("");
                    pin_temp["current"].clear();
                    setTempPinNumberFocused(0);
                    setShowPinEntry(false);
                    setTimeout(function () {
                        console.log('test');
                        setAllowPinClick(true);
                    }, 1000)
                }
            }
            else if (currentPinRef === 2) {
                currentIndex = tempPinNumberFocused;
                pin_temp["current"]["elements"][currentIndex].focus();
                pin_2["current"]["elements"][currentIndex].state.value = key;
                pin_temp["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setTempPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setTempPinNumberFocused(currentIndex);
                }
                else {
                    setPinNumber(pinStr);
                    setTempPinNumber("");
                    pin_temp["current"].clear();
                    setTempPinNumberFocused(0);
                    setShowPinEntry(false);
                    setTimeout(function () {
                        console.log('test');
                        setAllowPinClick(true);
                    }, 1000)
                }
            }
            else {
                currentIndex = tempPinNumberFocused;
                pin_temp["current"]["elements"][currentIndex].focus();
                pin_3["current"]["elements"][currentIndex].state.value = key;
                pin_temp["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setTempPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setTempPinNumberFocused(currentIndex);
                }
                else {
                    setPinNumberConfirm(pinStr);
                    setTempPinNumber("");
                    pin_temp["current"].clear();
                    setTempPinNumberFocused(0);
                    setShowPinEntry(false);
                    setTimeout(function () {
                        console.log('test');
                        setAllowPinClick(true);
                    }, 1000)
                }
            }
            //if (pinNumber === "") {
            //    currentIndex = pinNumberFocused;
            //    elePin['current']['elements'][currentIndex].state.value = key;
            //    if (currentIndex !== 3) {
            //        currentIndex++;
            //        setPinNumberFocused(currentIndex);
            //        elePin['current']['elements'][currentIndex].focus();
            //    }
            //    else {
            //        elePin['current']['values'].map((pin, index) => { //convert elePin to string
            //            pinStr += key;
            //        })
            //        setPinNumber(pinStr);
            //    }
            //}
            //else {
            //    currentIndex = pinNumberConfirmFocused;
            //    eleConfirm['current']['elements'][currentIndex].state.value = key;
            //    currentIndex++;
            //    setPinNumberConfirmFocused(currentIndex);
            //    if (currentIndex <= 3) {
            //        eleConfirm['current']['elements'][currentIndex].focus();
            //    }
            //    else {
            //        eleConfirm['current']['values'].map((pin, index) => { //convert eleConfirm to string, and submitPin
            //            pinConfirmStr += key;
            //        })
            //        setPinNumberConfirm(pinConfirmStr);
            //        submitPin(pinNumber, pinConfirmStr);
            //    }
            //}
        }
        else {
            pin_1["current"].clear();
            pin_2["current"].clear();
            pin_3["current"].clear();
            pin_temp["current"].clear();
            setOldPinNumber("");
            setPinNumber("");
            setPinNumberConfirm("");
            setTempPinNumber("");
            setOldPinNumberFocused(0);
            setPinNumberFocused(0);
            setPinNumberConfirmFocused(0);
            setTempPinNumberFocused(0);
        }
    }

    function onVipKeyPress(key) {
        setAllowPinClick(false);
        // 1st: have to check currentPinRef
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = tempPinNumber;
        if (key !== "{bksp}") {
            if (currentPinRef === 1) {
                currentIndex = tempPinNumberFocused;
                pin_vip_temp["current"]["elements"][currentIndex].focus();
                pin_vip_1["current"]["elements"][currentIndex].state.value = key;
                pin_vip_temp["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setTempPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setTempPinNumberFocused(currentIndex);
                }
                else {
                    setOldVipPinNumber(pinStr);
                    setTempPinNumber("");
                    pin_vip_temp["current"].clear();
                    setTempPinNumberFocused(0);
                    setShowVipPinEntry(false);
                    setTimeout(function () {
                        console.log('test');
                        setAllowPinClick(true);
                    }, 1000)
                }
            }
            else if (currentPinRef === 2) {
                currentIndex = tempPinNumberFocused;
                pin_vip_temp["current"]["elements"][currentIndex].focus();
                pin_vip_2["current"]["elements"][currentIndex].state.value = key;
                pin_vip_temp["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setTempPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setTempPinNumberFocused(currentIndex);
                }
                else {
                    setVipPinNumber(pinStr);
                    setTempPinNumber("");
                    pin_vip_temp["current"].clear();
                    setTempPinNumberFocused(0);
                    setShowVipPinEntry(false);
                    setTimeout(function () {
                        console.log('test');
                        setAllowPinClick(true);
                    }, 1000)
                }
            }
            else {
                currentIndex = tempPinNumberFocused;
                pin_vip_temp["current"]["elements"][currentIndex].focus();
                pin_vip_3["current"]["elements"][currentIndex].state.value = key;
                pin_vip_temp["current"]["elements"][currentIndex].state.value = key;
                pinStr += key;
                setTempPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setTempPinNumberFocused(currentIndex);
                }
                else {
                    setVipPinNumberConfirm(pinStr);
                    setTempPinNumber("");
                    pin_vip_temp["current"].clear();
                    setTempPinNumberFocused(0);
                    setShowVipPinEntry(false);
                    setTimeout(function () {
                        console.log('test');
                        setAllowPinClick(true);
                    }, 1000)
                }
            }
        }
        else {
            pin_vip_1["current"].clear();
            pin_vip_2["current"].clear();
            pin_vip_3["current"].clear();
            pin_vip_temp["current"].clear();
            setOldVipPinNumber("");
            setVipPinNumber("");
            setVipPinNumberConfirm("");
            setTempPinNumber("");
            setOldVipPinNumberFocused(0);
            setVipPinNumberFocused(0);
            setVipPinNumberConfirmFocused(0);
            setTempPinNumberFocused(0);
        }
    }

    async function handleRadioBtn(themeId) {
        let params = {
            "selectedTheme": themeId
        };
        var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_THEME, createFormBody(params));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(setBusy());
            setSelectedTheme(themeId);
            await _dispatch(updateUserData());
            setShowThemeAlert(true);
            _dispatch(setIdle());
            setTimeout(() => setShowThemeAlert(false), 3000);
        }

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: "change-theme",
                    themeId,
                })
            );
        }
    }

    /// <summary>
    /// Author:
    /// </summary>
    async function checkENB(data) {
        if (stringIsNullOrEmpty(data["email"]) || stringIsNullOrEmpty(data["birthDateString"]) || !data["isEmailVerify"]) {
            setBirthModal(true);
        }
    }

    /// <summary>
    /// Author:
    /// </summary>
    const confirmMessageClick = async () => {


        var a = startDate.toString();
        let parts = a.split(" ");
        let months = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12"
        };
        let date = (parts[3] + "-" + months[parts[1]] + "-" + parts[2]);
        setBirthdayString(moment(startDate).format("YYYY-MM-DD"));



        if (stringIsNullOrEmpty(tac) | stringIsNullOrEmpty(email)) {
            let success = false
            let message = 'PLS_VERIFIFY_THE_CODE_FIRST'
            _dispatch(showResponseMessage(success, message));
        } else {
            setConfirmMessage(true);
        }
    }
    /// <summary>
    /// Author: 
    /// </summary>
    const submitForm = async () => {
        let success = '';
        let message = '';
        if (countdownPeriodEmail > 0) {
            let verifyIsSuccess = await verifyEmail();

            verifyIsSuccess.map((option) => {
                success = option["success"]
                message = option["message"]
            });


            if (success) {
                let params = {
                    "email": email,
                    "birthDate": birthdayString
                }
                if (!stringIsNullOrEmpty(memberId)) {
                    params["id"] = memberId;
                }
                let formBody = createFormBody(params);
                _dispatch(setBusy());

                fetch(ApiUrl._API_UPDATE_MEMBER_EMAIL_AND_BIRTH_DATE, {
                    method: ApiKey._API_POST,
                    headers: {
                        'Content-Type': ApiKey._API_FORM_URLENCODED,
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                    },
                    body: formBody
                }).then((response) => response.json()).then((responseJson) => {
                    _dispatch(setIdle());
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setVerifyModal(false);
                        setConfirmMessage(false);
                        init();
                    }
                    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

                });
            } else {
                _dispatch(showResponseMessage(success, message));

            }
        } else {
            success = false
            message = 'Verification Code Expire Already!'
            _dispatch(showResponseMessage(success, message));
        }
    }

    const closeForm = async () => {
        setConfirmMessage(false);
        setSendEmailCode(true);
    }

    const aboutMenu = [
        {
            link: WebUrl._URL_ABOUT.replace(":category", "about-us"),
            text: t("ABOUT_US"),
        },
        {
            link: WebUrl._URL_ABOUT.replace(":category", "terms-and-conditions"),
            text: t("TERMS_AND_CONDITIONS"),
        },
        {
            link: WebUrl._URL_ABOUT.replace(":category", "privacy-policy"),
            text: t("PRIVACY_POLICY"),
        },
        {
            link: WebUrl._URL_ABOUT.replace(":category", "affiliate-partner"),
            text: t("AFFILIATE_PARTNER_PROGRAM"),
        },
        {
            link: WebUrl._URL_ABOUT.replace(":category", "responsible-gambling"),
            text: t("RESPONSIBLE_GAMBLING"),
        },
    ];

    const securityList = [
        {
            text: 'CHANGE_PASSWORD',
            press: () => setChangePasswordModalVisible(true)
        },
        {
            text: 'CHANGE_PIN',
            press: () => setChangePinModalVisible(true)
        },
    ];

    const handleUserInfoOnClick = async () => {
        setIsUserInfoLoaded(false);
        getUnclaimCommHistory();
        getRewardData();
        setUserInfoModalVisible(true);
        setIsUserInfoLoaded(true);
        getLatestVersion();

        // when click profile will pass to mobile app, so will update the mobile version
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: "profile"
                })
            );
        }
    }

    const getLatestVersion = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_MOBILE_VERSION);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setMobileVersion(data);
        }
    }

    const getUnclaimCommHistory = async () => {
        var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setUnclaimComm(data["totalClaimable"]);
        }
    };

    const getRewardData = async () => {
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            let tempObj = {};
            let grandTotalReward = 0;
            let categoryTotal = {};
            data.map((rewardData, index) => {
                if (!tempObj[rewardData["date"]]) {
                    tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
                }
                if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
                    tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
                }
                if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
                    tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
                }
                tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
                tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
                if (rewardData["claimed"] !== "True") {
                    tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
                    tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
                    grandTotalReward += rewardData["amount"];

                    if (!categoryTotal[rewardData["categoryTitle"]]) {
                        categoryTotal[rewardData["categoryTitle"]] =
                        {
                            category: rewardData["categoryTitle"],
                            totalAmount: rewardData["amount"]
                        };
                    }
                    else if (categoryTotal[rewardData["categoryTitle"]]) {
                        categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
                    }
                    else if (categoryTotal[rewardData["categoryTitle"]]) {
                        categoryTotal[rewardData["categoryTitle"]] =
                        {
                            category: rewardData["categoryTitle"],
                            totalAmount: rewardData["amount"]
                        };
                    }
                }

            });
            setGrandTotalRewards(grandTotalReward);
        } else {
            _dispatch(
                showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
        }
    }

    return (
        <>
            <Header history={_history} url={WebUrl._URL_MAIN} title={t('SETTINGS')}
                slot={
                    <Select
                        className="lang-selector"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        options={LanguageOption.map(x => { return { label: x['prefix'], value: x['value'] } })}
                        isSearchable={false}
                        styles={LanguageSelectStyle}
                        placeholder={(LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].prefix) : "EN"}
                        value={(LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].prefix) : Language._ENGLISH}
                        onChange={(e) => { onChangeLanguage(e.value) }} />
                } />
            <div className="settings-user-container">
                <div className="first">
                    <img
                        src={require("../../assets/img/v3/settings_user_icon.svg")}
                        alt='claim'
                        className="img-responsive m-auto"
                    />
                </div>
                <div className="second">
                    <div className="title">
                        {_userData && !stringIsNullOrEmpty(_userData.username) ? _userData.username : ""}
                    </div>
                    <div className="details">
                        {!stringIsNullOrEmpty(phoneNumber) ? ("+" + selectedPhonePrefix + phoneNumber) : (member && !stringIsNullOrEmpty(member.phoneNumber)) ? "+" + member.phoneNumber : ""}
                    </div>
                </div>
                <div className="third" onClick={() => handleUserInfoOnClick()}>
                    <div>
                        <img
                            src={require("../../assets/img/v3/more-gold.png")}
                            alt='claim'
                            className="img-responsive m-auto"
                        />
                    </div>
                </div>
            </div>
            {member && member.isPhoneVerify == false &&
                <>
                    <div className="hr-right mt-3 mb-3"></div>
                    <div className="custom-box-content-with-shadow settings-not-verified-container">
                        <div className="first">
                            <img
                                src={require("../../assets/img/v3/reminder-red.png")}
                                alt='verify'
                                className="img-responsive m-auto"
                            />
                        </div>
                        <div className="second">
                            <div className="title">
                                {t("YOUR_PHONE_IS_NOT_VERIFIED")}
                            </div>
                            <div className="details">
                                <a onClick={handleProfileSubmit(updateProfile)}>{t("SEND_VERIFICATION_CODE")}</a>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="hr-left mt-3 mb-3"></div>
            <div>
                <h4>{t("SECURITY_AND_PINS")}</h4>
                <div className="setting-list-container">
                    {securityList &&
                        securityList.length &&
                        map(securityList, (item, index) => {
                            return (
                                <>
                                    <div className="box-list-with-shadow setting-list-item" onClick={item.press}>
                                        <div>
                                            {t(item.text)}
                                        </div>
                                        <div className="second">
                                            <img
                                                src={require("../../assets/img/v3/arrow-right.png")}
                                                alt="search"
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        })
                    }
                </div>
            </div>
            <div className="hr-right mt-3 mb-3"></div>
            <div>
                <h4>{t("HELP_AND_SUPPORT")}</h4>
                <div className="setting-list-container">
                    <a href={WebUrl._URL_HELP_CENTRE}>
                        <div className="box-list-with-shadow setting-list-item">
                            <div>
                                {t("HELP_CENTRE")}
                            </div>
                            <div className="second">
                                <img
                                    src={require("../../assets/img/v3/arrow-right.png")}
                                    alt="search"
                                />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className="hr-left mt-3 mb-3"></div>
            <div>
                <h4>{t("COMMUNITY_STANDARDS_AND_LEGAL_POLICIES")}</h4>
                <div className="setting-list-container">
                    {aboutMenu &&
                        aboutMenu.length &&
                        map(aboutMenu, (item, index) => {
                            return (
                                <a href={item.link}>
                                    <div className="box-list-with-shadow setting-list-item">
                                        <div>
                                            {item.text}
                                        </div>
                                        <div className="second">
                                            <img
                                                src={require("../../assets/img/v3/arrow-right.png")}
                                                alt="search"
                                            />
                                        </div>
                                    </div>
                                </a>
                            );
                        })
                    }
                </div>
            </div>
            <div className="hr-right mt-3 mb-3"></div>
            {/*<div>
        <h4>Language & Region</h4>
        <div className="setting-list-container">
          <div className="box-list-with-shadow setting-list-item">
            <div className="color-mainBlue">
              Malaysia  l  English
            </div>
            <div className="second">
              <img
                src={require("../../assets/img/v3/arrow-right.png")}
                alt="search"
              />
            </div>
          </div>
        </div>
      </div>*/}
            {/*<div className="hr-left mt-3 mb-3"></div>*/}
            <div className="setting-list-container mb-5">
                <div className="box-list-with-shadow setting-list-item" onClick={() => { _dispatch(performLogout()) }}>
                    <div className="color-mainBlue">
                        {t("LOGOUT")}
                    </div>
                    <div className="second">
                        <img
                            src={require("../../assets/img/v3/logout.png")}
                            alt="logout"
                        />
                    </div>
                </div>
            </div>
            <div className={"settings-page" + (selectedTheme === 0 ? "" : " modern")} style={{ paddingBottom: '30px' }}>
                <Modal id='custom-modal' fade={false} contentClassName="modal-brand modal-bottom" isOpen={verificationModal} toggle={() => { countdownPeriod > 0 ? setVerificationModal(true) : setVerificationModal(false); }}>
                    <ModalBody style={{ minHeight: 'unset' }}>
                        <div className="font20 text-center">
                            {t("PHONE_VERIFICATION")}
                        </div>
                        <div className="font12 text-center color-gray mb-5">
                            {t("CODE_SENT_TO_NUMBER", { phoneNumber: "+" + formattedPhoneNumber })}
                        </div>
                        <form onSubmit={handleCodeSubmit(verifyPhoneNumber)}>
                            {/*<div className="form-group">
                                <PhoneNumber
                                    mainContainerClassName="not-touchable form-group"
                                    className="p-0"
                                    value={getProfileValues("phoneNumber")}
                                />
                                <div className="text-center">
                                    {
                                        !sentCode || countdownPeriod == 0 ?
                                            <button type="button" onClick={() => sendVerificationCode()} className="btn btn-block btn-brand-gradient">{t("SEND_VERIFICATION_CODE")}</button>
                                            :
                                            <p>{t("VERIFICATION_CODE_SENT")} {t("RESEND_IN")} {countdownPeriod}s</p>
                                    }
                                </div>
                            </div>*/}
                            {
                                sentCode /*&& countdownPeriod !== 0*/ && <>
                                    <div>
                                        <Input
                                            name={"verificationCode"}
                                            placeholder={t("ENTER_VERIFICATION_CODE")}
                                            type="tel"
                                            ref={codeRegister({
                                                required: 'FIELD_REQUIRED',
                                                pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                                            })}
                                            errors={codeErrors} />
                                    </div>
                                    {countdownPeriod !== 0 ?
                                        (<p className="text-center font11 color-gray mb-4">{t("RESEND_IN")} {countdownPeriod}s</p>)
                                        : (<p className="text-center font11 color-gray mb-4" onClick={() => sendVerificationCode()}>{t("SEND_VERIFICATION_CODE")}</p>)}

                                    <div>
                                        <button type="submit" className="btn btn-block btn-brand-gradient">{t("SUBMIT")}</button>
                                    </div>
                                </>
                            }
                        </form>
                    </ModalBody>
                </Modal>
                <Modal 
                    fade={false} 
                    contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad" 
                    backdrop={true}
                    isOpen={showPinEntry} 
                    toggle={() => { 
                        setAllowPinClick(true); 
                        setShowPinEntry(false) 
                    }}
                    centered
                >
                    <ModalBody>
                        <div className="display-wrapper">
                            <button type="button" style={{
                                    alignSelf: "flex-end", 
                                    backgroundColor: "transparent", 
                                    border: "none", 
                                    marginRight: "15px", 
                                    fontSize: "2rem", 
                                    color: "#002e6c", 
                                    marginTop: "-15px", 
                                    fontWeight: "bold",
                                }} 
                                onClick={() => { setAllowPinClick(true); setShowPinEntry(false); }} 
                                data-dismiss="modal" 
                                aria-hidden="true">
                                <span>&times;</span>
                            </button>
                            <strong className="text-brand display-value" style={{ fontSize: "20px" }}>
                                {currentPinRef === 1 ? t("OLD_PIN") : currentPinRef === 2 ? t("NEW_PIN") : t("CONFIRM_PIN")}
                            </strong>
                            <PinInput
                                style={{ marginTop: "37px" }}
                                ref={pin_temp}
                                length={4}
                                initialValue="----"
                                secret
                                type="numeric"
                                disabled={showPinEntry}
                                onChange={(value, index) => {
                                    setTempPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", color: "#A4A4A4" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                        </div>
                        <Keyboard
                            layout={{
                                'default': [
                                    "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                ]
                            }}
                            display={{
                                '{bksp}': "<i class='fas fa-backspace'></i>",
                            }}
                            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                            keyboardRef={r => (keyboard.current = r)}
                            onKeyPress={onKeyPress}
                            disableButtonHold
                            disableCaretPositioning
                        />
                    </ModalBody>
                </Modal>
                <Modal 
                    fade={false} 
                    contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad" 
                    backdrop={true}
                    isOpen={showVipPinEntry} 
                    toggle={() => { 
                        setAllowPinClick(true); 
                        setShowVipPinEntry(false) 
                    }}
                    centered
                >
                    <ModalBody>
                        <div className="display-wrapper">
                            <button type="button" style={{
                                alignSelf: "flex-end", 
                                backgroundColor: "transparent", 
                                border: "none", 
                                marginRight: "15px", 
                                fontSize: "2rem", 
                                color: "#002e6c", 
                                marginTop: "-15px", 
                                fontWeight: "bold",
                              }} 
                              onClick={() => { setAllowPinClick(true); setShowVipPinEntry(false); }} 
                              data-dismiss="modal" 
                              aria-hidden="true">
                                <span>&times;</span>
                            </button>
                            <strong className="text-brand display-value" style={{ fontSize: "20px" }}>
                                {currentPinRef === 1 ? t("OLD_SHAREHOLDER_PIN") : currentPinRef === 2 ? t("NEW_SHAREHOLDER_PIN") : t("CONFIRM_SHAREHOLDER_PIN")}
                            </strong>
                            <PinInput
                                style={{ marginTop: "37px" }}
                                ref={pin_vip_temp}
                                length={4}
                                initialValue="----"
                                secret
                                type="numeric"
                                disabled={showVipPinEntry}
                                onChange={(value, index) => {
                                    setTempPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", color: "#A4A4A4" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                        </div>
                        <Keyboard
                            layout={{
                                'default': [
                                    "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                ]
                            }}
                            display={{
                                '{bksp}': "<i class='fas fa-backspace'></i>",
                            }}
                            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                            keyboardRef={r => (keyboard.current = r)}
                            onKeyPress={onVipKeyPress}
                            disableButtonHold
                            disableCaretPositioning
                        />
                    </ModalBody>
                </Modal>
                <Modal 
                    fade={true} 
                    id="custom-modal"
                    contentClassName="modal-brand modal-bottom"
                    isOpen={birthModal}
                    toggle={() => {
                        setBirthModal(false);
                    }}>
                    <ModalBody style={{ minHeight: 'unset', minWidth: 'unset' }}>
                        <div className="page-custom-header pt-0 email-birthdate-header">
                            <div className="first">
                                <div>
                                    <span>{t("UPDATE_EMAIL_AND_BIRTH_DATE")}</span>
                                    <br/>
                                    <span>{t("UPDATE_EMAIL_AND_BIRTH_DATE_TEXT")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="form-group" >
                                <DateRangePicker
                                    id="datepicker"
                                    singleDatePicker={true}
                                    onApply={(event, picker) => {
                                        setStartDate(picker.startDate);
                                    }}
                                    alwaysShowCalendars={true}
                                    showDropdowns={true}
                                    opens="center"
                                    containerStyles={{ height: "5%" }}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    startDate={startDate == "" ? moment().subtract(18, "years") : startDate} // set default start date to max date
                                    drops="up"
                                >
                                    <input
                                        type="text"
                                        placeholder={t("ENTER_BIRTH_DATE")}
                                        value={startDate ==""?"":moment(startDate).format("DD-MM-YYYY")}
                                        readOnly
                                    />
                                </DateRangePicker>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/*<label className="text-white">{t("EMAIL")}</label>*/}
                            <div className="form-group">
                                <Input
                                    name="email"
                                    placeholder={t("ENTER_EMAIL")}
                                    ref={register({
                                        required: "PLEASE_ENTER_EMAIL",
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "INVALID_EMAIL"
                                        }
                                    })}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }} />
                            </div>
                        </div>
                        <div className="col-lg-3 mt-5">
                            <button
                                type="submit"
                                className="btn btn-block btn-brand-gradient"
                                style={{ width: "100%", fontSize: "14px" }}
                                onClick={() => emailVerifiaction()}
                                disabled={sendEmailCode && countdownPeriod !== 0}
                            >
                                {t("CONTINUE")}
                            </button>
                        </div>
                    </ModalBody>
                </Modal>

                {
                    // Verify Modal
                }
                <Modal
                    fade={true}
                    id="custom-modal"
                    contentClassName="modal-brand modal-bottom"
                    isOpen={verifyModal}
                    toggle={() => {
                        setVerifyModal(false);
                    }}>
                    <ModalBody style={{ minHeight: 'unset', minWidth: 'unset' }}>
                        <div className="page-custom-header pt-0 email-birthdate-header">
                            <div className="first">
                                <div>
                                    <span>{t("EMAIL_VERIFICATION_CODE")}</span>
                                    <br />
                                    {countdownPeriodEmail !== 0 &&
                                        <span style={{ textAlign: "center", fontSize: "12px" }}>{t("CODE_HAVE_BEEN_SENT_TO", { value: email })}</span>
                                    }
                                    {countdownPeriodEmail === 0 &&
                                        <span style={{ textAlign: "center", fontSize: "12px" }}>{t("CODE_WILL_BE_SENT_TO", { value: email })}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <div>
                                    <Input
                                        name="tac"
                                        placeholder={t("ENTER_EMAIL_VERIFICATION")}
                                        className="birthdayEmailModel"
                                        ref={register({
                                            required: "PLEASE_ENTER_TAC",
                                            pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                                        })}
                                        value={tac}
                                        onChange={(e) => {
                                            setTAC(e.target.value);
                                        }} />
                                    {errors.tac && <div className="invalid-feedback">{t(errors.tac.message)}</div>}
                                </div>
                            </div>
                        </div>
                        {sendEmailCode && countdownPeriodEmail === 0 &&
                            <div className="form-group" style={{ display: "flex", justifyContent: "center" }}>
                                <span style={{ textAlign: "center", color: "#3369FF", fontSize: "12px" }} onClick={() => emailVerifiaction()}>{t("GET_VERIFICATION_CODE")}</span>
                            </div>
                        }
                        {countdownPeriodEmail !== 0 &&
                            <div className="form-group" style={{ display: "flex", justifyContent: "center" }}>
                                <span style={{ textAlign: "center", color: "#A4A4A4", fontSize: "12px" }}>{t("VERIFICATION_CODE_SENT")} {t("RESEND_IN")} {countdownPeriodEmail}s</span>
                            </div>
                        }
                        <div className="col-lg-3 mt-5">
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ fontSize: "14px" }} disabled={(stringIsNullOrEmpty(birthDateString) || stringIsNullOrEmpty(email) || stringIsNullOrEmpty(tac)) || tac.length < 6 || tac.length > 6} onClick={() => confirmMessageClick()}>{t("VERIFY")} </button>
                        </div>
                    </ModalBody>
                </Modal>
                
                <Modal fade={false} contentClassName="modal-brand modal-top" style={{ marginTop: "220px" }} isOpen={confirmMessage}>
                    <ModalHeader><label>{t("CONFIRM_MESSAGE")}</label></ModalHeader>
                    <ModalBody>
                        <div style={{ color: "black", textAlign: "center" }}>
                            {t("ARE_YOU_CONFIRM_TO_SELECT_AS_YOUR_BIRTHDAY", { value: moment(birthdayString, "YYYY-MM-DD").format("DD-MM-YYYY") })}
                            <br />
                            {t("CANNOT_BE_CHANGED_AFTER_CONFIRMATION")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ fontSize: "14px" }} onClick={() => closeForm()}>{t("CLOSE")} </button>
                        </div>
                        <div>
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ fontSize: "14px" }} onClick={() => submitForm()}>{t("SUBMIT")} </button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal
                    id="custom-modal"
                    contentClassName="modal-brand modal-bottom"
                    fade={true}
                    isOpen={userInfoModalVisible && isUserInfoLoaded}
                    toggle={() => {
                        setUserInfoModalVisible(false);
                    }}
                    centered
                >
                    <ModalBody>
                        <div className="page-custom-header pt-0">
                            <div className="first" onClick={() => setUserInfoModalVisible(false)}>
                                <div>
                                    <i className="fas fa-chevron-left"></i>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                src={require("../../assets/img/v3/settings_user_icon.svg")}
                                alt='claim'
                                className="img-responsive m-auto"
                            />
                        </div>
                        <div className="setting-points-container mt-3">
                            <div>
                                <div>
                                    <img
                                        src={require("../../assets/img/v3/commission.svg")}
                                        alt='claim'
                                        className="img-responsive m-auto"
                                    />
                                </div>
                                <div>
                                    {numberWithCurrencyFormat(unclaimComm, 2, true)}
                                </div>
                            </div>
                            <div>|</div>
                            <div>
                                <div>
                                    <img
                                        src={require("../../assets/img/v3/reward.svg")}
                                        alt='claim'
                                        className="img-responsive m-auto"
                                    />
                                </div>
                                <div>
                                    {numberWithCurrencyFormat(grandTotalRewards, 2, true)}
                                </div>
                            </div>
                        </div>
                        {isContentLoaded &&
                            <>
                                <form className="margin-bottom-0" onSubmit={handleProfileSubmit(updateProfile)}>
                                    <div>
                                        <div className="input-container">
                                            <div className="input-with-icon-container">
                                                <input
                                                    type='text'
                                                    name={"username"}
                                                    value={profileWatch("username") || ""}
                                                    className="custom-input"
                                                    disabled={true}
                                                    placeholder={member ? member.username : t("USERNAME")}
                                                />
                                                <div className="input-icon">
                                                    <img src={require("../../assets/img/v3/username.svg")} alt="username" className="img-responsive" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-container mt-4">
                                            <div className="custom-phone-container">
                                                <select
                                                    className="custom-select-container"
                                                    onChange={(e) => setSelectedPhonePrefix(e.target.value)}
                                                    value={selectedPhonePrefix}
                                                    disabled={member.isPhoneVerify}
                                                >
                                                    {phoneNumberPrefix.map((prefix, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                className={"phone-prefix-" + (index + 1)}
                                                                value={prefix.value}
                                                            >
                                                                {prefix.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>

                                                <div className="second phone-with-icon">
                                                    <input
                                                        type="text"
                                                        placeholder="eg: 123456"
                                                        value={phoneNumber}
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                        className="custom-phone-input"
                                                        name="phoneNumber"
                                                        disabled={member.isPhoneVerify}
                                                        ref={profileRegister({
                                                            required: t("FIELD_REQUIRED"),
                                                            minLength: {
                                                                value: 8,
                                                                message: t("MIN_CHARACTERS", { length: 8 }),
                                                            },
                                                            pattern: {
                                                                value: /^[0-9]+$/g,
                                                                message:
                                                                    t("MIN_CHARACTERS", { length: 8 }) +
                                                                    " " +
                                                                    t("AND") +
                                                                    " " +
                                                                    t("ONLY_NUMBERS"),
                                                            }
                                                        })}
                                                        errors={profileErrors}
                                                    />
                                                    {!member.isPhoneVerify ?
                                                        <div className="setting-verified-box" onClick={handleProfileSubmit(updateProfile)}>
                                                            <i className="fas fa-paper-plane"></i>
                                                        </div>
                                                        :
                                                        <div className="setting-verified-box">
                                                            <img
                                                                src={require("../../assets/img/v3/verified.png")}
                                                                alt='claim'
                                                                className="img-responsive m-auto"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">
                                                {phoneNumberError == true
                                                    ? 'Number not available'
                                                    : ""}
                                            </div>
                                        </div>
                                        {!birthModal &&
                                            <>
                                                <div className="input-container input-verified mt-4">
                                                    <div className="input-with-icon-container">
                                                        <input
                                                            type='text'
                                                            name={"email"}
                                                            value={email}
                                                            className="custom-input"
                                                            placeholder={t("ENTER_EMAIL")}
                                                            // disabled={member.isEmailVerify}
                                                            disabled={true}
                                                        />
                                                        <div className="input-icon">
                                                            <img src={require("../../assets/img/v3/email.svg")} alt="username" className="img-responsive" />
                                                        </div>
                                                        {!member.isEmailVerify ?
                                                            <div className="setting-verified-box" onClick={() => {setUserInfoModalVisible(false); setTimeout(() => setBirthModal(true), 200); }}>
                                                                <img
                                                                    src={require("../../assets/img/v3/reminder-red.png")}
                                                                    alt='claim'
                                                                    className="img-responsive m-auto"
                                                                />
                                                            </div>
                                                            :
                                                            <div className="setting-verified-box">
                                                                <img
                                                                    src={require("../../assets/img/v3/verified.png")}
                                                                    alt='claim'
                                                                    className="img-responsive m-auto"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="input-container mt-4">
                                                    <div className="input-with-icon-container">
                                                        <input
                                                            type='text'
                                                            name={"birthDateString"}
                                                            value={birthDateString}
                                                            className="custom-input"
                                                            placeholder={t("ENTER_BIRTH_DATE")}
                                                            // disabled={member.isEmailVerify}
                                                            disabled={true}
                                                        />
                                                        <div className="input-icon">
                                                            <img src={require("../../assets/img/v3/calendar.svg")} alt="username" className="img-responsive" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-5 font11 color-gray text-center">
                                                    {"v." + process.env.REACT_APP_VERSION}
                                                    <br/>
                                                    {window.ReactNativeWebView ? 
                                                        mobileVersion == null ? "" : "mv." + mobileVersion
                                                        : ""
                                                    }
                                                </div>
                                            </>}
                                    </div>
                                </form>
                            </>
                        }

                    </ModalBody>
                </Modal>
                <Modal
                    id="custom-modal"
                    contentClassName="modal-brand modal-bottom"
                    fade={true}
                    isOpen={changePasswordModalVisible}
                    toggle={() => {
                        setChangePasswordModalVisible(false);
                    }}
                    centered
                >
                    <ModalBody>
                        <div className="page-custom-header pt-0">
                            <div className="first">
                                <div onClick={() => setChangePasswordModalVisible(false)}>
                                    <img src={require("../../assets/img/v3/back-icon.png")} alt="back" />
                                </div>
                                <div>
                                    {t("CHANGE_PASSWORD")}
                                </div>
                            </div>
                            {/* <div className="second">
                                <img src={require("../../assets/img/v3/more-gold.png")} alt="more" />
                            </div> */}
                        </div>
                        <div className="font12 text-gray text-center">
                            {t("CREATE_YOUR_NEW_PASSWORD_HERE")}
                        </div>
                        <div className="setting-change-password">
                            <form className="margin-bottom-0" onSubmit={handleResetPasswordSubmit(resetPassword)}>

                                <div className="input-container mt-4">
                                    <label>{t("CURRENT_PASSWORD")}</label>
                                    <div className="input-with-icon-container">
                                        <div className="input-icon">
                                            <img src={require("../../assets/img/v3/key.png")} alt="password" className="img-responsive" />
                                        </div>
                                        <Input
                                            placeholder={t("ENTER_OLD_PASSWORD")}
                                            name={"currentPassword"}
                                            className='custom-input input-hidden'
                                            autoComplete={"off"}
                                            type={currentLoginPasswordVisible ? 'text' : 'password'}
                                            ref={resetPasswordRegister({
                                                required: 'REQUIRED_OLD_PASSWORD'
                                            })}
                                            value={resetPasswordWatch('currentPassword') || ""}
                                            onInput={(e) => {
                                                if (e.target.value === resetPasswordWatch("password")) {
                                                    setResetPasswordError("password", { message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD" });
                                                }
                                                else {
                                                    clearResetPasswordErrors("password");
                                                }
                                                setResetPasswordValue('currentPassword', e.target.value);
                                            }}
                                            errors={resetPasswordErrors}
                                        />
                                        <div className="input-hidden-icon" onClick={(e) => toggleHiddenCurrent(e)}>
                                            <img src={isHiddenCurrent} alt="password" className="img-responsive" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-container mt-4">
                                    <label>{t("NEW_PASSWORD")}</label>
                                    <div className="input-with-icon-container">
                                        <div className="input-icon">
                                            <img src={require("../../assets/img/v3/key.png")} alt="password" className="img-responsive" />
                                        </div>
                                        <Input
                                            placeholder={t("ENTER_NEW_PASSWORD")}
                                            name={"password"}
                                            autoComplete={"off"}
                                            className='custom-input input-hidden'
                                            type={newLoginPasswordVisible ? 'text' : 'password'}
                                            ref={resetPasswordRegister({
                                                required: 'REQUIRED_NEW_PASSWORD'
                                            })}
                                            value={resetPasswordWatch('password') || ""}
                                            onInput={(e) => {
                                                if (e.target.value === resetPasswordWatch("currentPassword")) {
                                                    setResetPasswordError("password", { message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD" });
                                                }
                                                else {
                                                    clearResetPasswordErrors("password");
                                                }
                                                setResetPasswordValue('password', e.target.value);
                                            }}
                                            errors={resetPasswordErrors}
                                        />
                                        <div className="input-hidden-icon" onClick={toggleHiddenNew}>
                                            <img src={isHiddenNew} alt="password" className="img-responsive" />
                                        </div>
                                    </div>
                                </div>
                                {/* <Input
                                    placeholder={t("ENTER_NEW_PASSWORD")}
                                    name={"password"}
                                    autoComplete={"off"}
                                    className='custom-input input-hidden'
                                    type="password"
                                    ref={resetPasswordRegister({
                                        required: 'REQUIRED_NEW_PASSWORD'
                                    })}
                                    value={resetPasswordWatch('password') || ""}
                                    onInput={(e) => {
                                        if (e.target.value === resetPasswordWatch("currentPassword")) {
                                            setResetPasswordError("password", { message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD" });
                                        }
                                        else {
                                            clearResetPasswordErrors("password");
                                        }
                                        setResetPasswordValue('password', e.target.value);
                                    }}
                                    errors={resetPasswordErrors} /> */}
                                <div className="input-container mt-4">
                                    <label>{t("CONFIRM_NEW_PASSWORD")}</label>
                                    <div className="input-with-icon-container">
                                        <div className="input-icon">
                                            <img src={require("../../assets/img/v3/key.png")} alt="password" className="img-responsive" />
                                        </div>
                                        <Input
                                            placeholder={t("ENTER_CONFIRM_NEW_PASSWORD")}
                                            name={"confirmPassword"}
                                            autoComplete={"off"}
                                            className='custom-input input-hidden'
                                            type={confirmLoginPasswordVisible ? 'text' : 'password'}
                                            ref={resetPasswordRegister({
                                                required: 'REQUIRED_CONFIRM_NEW_PASSWORD',
                                                validate: (value) => value == resetPasswordWatch("password") || "PASSWORD_MISMATCH"
                                            })}
                                            value={resetPasswordWatch('confirmPassword') || ""}
                                            onInput={(e) => { setResetPasswordValue('confirmPassword', e.target.value); }}
                                            errors={resetPasswordErrors}
                                        />
                                        <div className="input-hidden-icon" onClick={toggleHiddenConfirm}>
                                            <img src={isHiddenConfirm} alt="password" className="img-responsive" />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-brand-gradient mt-5">{t("SAVE")}</button>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    id="custom-modal"
                    contentClassName="modal-brand modal-bottom"
                    fade={true}
                    isOpen={changePinModalVisible}
                    toggle={() => {
                        setChangePinModalVisible(false);
                    }}
                    centered
                >
                    <ModalBody>
                        <div className="page-custom-header pt-0">
                            <div className="first">
                                <div onClick={() => setChangePinModalVisible(false)}>
                                    <img src={require("../../assets/img/v3/back-icon.png")} alt="back" />
                                </div>
                                <div>
                                    {t("CHANGE_PIN")}
                                </div>
                            </div>
                            {/* <div className="second">
                                <img src={require("../../assets/img/v3/more-gold.png")} alt="more" />
                            </div> */}
                        </div>
                        <div className="transfer-header-tab-container mb-4">
                            {isShareholder ? (pinList &&
                                pinList.length &&
                                map(pinList, (item, index) => {
                                    return (
                                        <div key={index} className={item.id == pinTabActive ? "transfer-tab-list-item active" : "transfer-tab-list-item"} onClick={() => setPinTabActive(item.id)}>
                                            {t(((item.title).replace(' ', '_')).toLocaleUpperCase())}
                                        </div>
                                    );
                                })) :
                                <div className="transfer-tab-list-item active">
                                    {t("WITHDRAWAL_PIN")}
                                </div>
                            }
                        </div>
                        {pinTabActive == 1 && (
                            <>
                                <div className="setting-change-pin">
                                    <form onSubmit={pinHandleSubmit(changePin)}>
                                        {checkPinExist &&
                                            <div className="text-center old-pin-container" onClick={() => { if (!allowPinClick) return false; setCurrentPinRef(1); setShowPinEntry(true); }}>
                                                <label className="change-pin-label"><b>{t("OLD_PIN")}</b></label>
                                                <PinInput
                                                    ref={pin_1}
                                                    length={4}
                                                    style={{ marginBottom: "20px" }}
                                                    initialValue="----"
                                                    secret
                                                    type="numeric"
                                                    disabled={true}
                                                    onChange={(value, index) => {
                                                        setOldPinNumberFocused(index);
                                                    }}
                                                    inputMode="numeric"
                                                    inputStyle={{ border: "0", margin: "0px 10px" }}
                                                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                                />
                                            </div>
                                        }
                                        <div className="text-center new-pin-container" onClick={() => { if (!allowPinClick) return false; setCurrentPinRef(2); setShowPinEntry(true); }}>
                                            <label className="change-pin-label"><b>{checkPinExist ? t("NEW_PIN") : t("PIN")}</b></label>
                                            <PinInput
                                                ref={pin_2}
                                                length={4}
                                                style={{ marginBottom: "20px" }}
                                                initialValue="----"
                                                secret
                                                type="numeric"
                                                disabled={true}
                                                onChange={(value, index) => {
                                                    setPinNumberFocused(index);
                                                }}
                                                inputMode="numeric"
                                                inputStyle={{ border: "0", margin: "0px 10px" }}
                                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                            />
                                        </div>
                                        <div className="text-center confirm-pin-container" onClick={() => { if (!allowPinClick) return false; setCurrentPinRef(3); setShowPinEntry(true); }}>
                                            <label className="change-pin-label"><b>{t("CONFIRM_PIN")}</b></label>
                                            <PinInput
                                                ref={pin_3}
                                                style={{ marginBottom: "45px" }}
                                                length={4}
                                                initialValue="----"
                                                secret
                                                type="numeric"
                                                disabled={true}
                                                onChange={(value, index) => {
                                                    setPinNumberConfirmFocused(index);
                                                }}
                                                inputMode="numeric"
                                                inputStyle={{ border: "0", margin: "0px 10px" }}
                                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-block btn-brand-gradient mt-5">{t("SAVE")}</button>
                                    </form>
                                </div>
                            </>
                        )}

                        {isShareholder && pinTabActive == 2 && (
                            <>
                                <div className="setting-change-pin">
                                    <form onSubmit={pinHandleSubmit(changeShareholderPin)}>
                                        {checkVipPinExist &&
                                            <div className="text-center old-sh-pin-container" onClick={() => { if (!allowPinClick) return false; setCurrentPinRef(1); setShowVipPinEntry(true); }}>
                                                <label className="change-pin-label"><b>{t("OLD_SHAREHOLDER_PIN")}</b></label>
                                                <PinInput
                                                    ref={pin_vip_1}
                                                    length={4}
                                                    style={{ marginBottom: "20px" }}
                                                    initialValue="----"
                                                    secret
                                                    type="numeric"
                                                    disabled={true}
                                                    onChange={(value, index) => {
                                                        setOldVipPinNumberFocused(index);
                                                    }}
                                                    inputMode="numeric"
                                                    inputStyle={{ border: "0", margin: "0px 10px" }}
                                                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                                />
                                            </div>
                                        }
                                        <div className="text-center new-sh-pin-container" onClick={() => { if (!allowPinClick) return false; setCurrentPinRef(2); setShowVipPinEntry(true); }}>
                                            <label className="change-pin-label"><b>{t("NEW_SHAREHOLDER_PIN")}</b></label>
                                            <PinInput
                                                ref={pin_vip_2}
                                                length={4}
                                                style={{ marginBottom: "20px" }}
                                                initialValue="----"
                                                secret
                                                type="numeric"
                                                disabled={true}
                                                onChange={(value, index) => {
                                                    setVipPinNumberFocused(index);
                                                }}
                                                inputMode="numeric"
                                                inputStyle={{ border: "0", margin: "0px 10px" }}
                                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                            />
                                        </div>
                                        <div className="text-center confirm-sh-pin-container" onClick={() => { if (!allowPinClick) return false; setCurrentPinRef(3); setShowVipPinEntry(true); }}>
                                            <label className="change-pin-label"><b>{t("CONFIRM_SHAREHOLDER_PIN")}</b></label>
                                            <PinInput
                                                ref={pin_vip_3}
                                                style={{ marginBottom: "45px" }}
                                                length={4}
                                                initialValue="----"
                                                secret
                                                type="numeric"
                                                disabled={true}
                                                onChange={(value, index) => {
                                                    setVipPinNumberConfirmFocused(index);
                                                }}
                                                inputMode="numeric"
                                                inputStyle={{ border: "0", margin: "0px 10px" }}
                                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-block btn-brand-gradient mt-5">{t("SAVE")}</button>
                                    </form>
                                </div>
                            </>
                        )}

                    </ModalBody>
                </Modal>
                {showAlert && <div className="content content-full-width" style={{ position: "absolute", top: "10px", right: "0", width: "50%" }}>
                    <div className="container">
                        <Alert color="green">{t("PIN_CHANGED")}</Alert>
                    </div>
                </div>}
                {showFailAlert && <div className="content content-full-width" style={{ position: "absolute", top: "10px", right: "0", width: "50%" }}>
                    <div className="container">
                        <Alert color="red">{failMessage}</Alert>
                    </div>
                </div>}
                {showThemeAlert && <div className="content content-full-width" style={{ position: "absolute", top: "10px", right: "0", width: "50%" }}>
                    <div className="container">
                        <Alert color="green">{t("THEME_UPDATED")}</Alert>
                    </div>
                </div>}
            </div>
        </>
    );
};

export default Settings;
