import React, { useState, useEffect, useRef } from "react";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Header from "../../components/custom/Header";
import moment from "moment";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  TransactionStatus,
} from "../../util/Constant";
import Icon from "@mdi/react";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiDotsHorizontalCircle,
} from "@mdi/js";

const WalletHistory = () => {
  let _history = useHistory();
  var _location = useLocation();
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const [withdrawalUrl, setWithdrawalUrl] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState("");
  const [currentItem, setItem] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    getTransactionData(startDate, endDate);
  }, []);

  async function getTransactionData(start, end, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&type=" +
      "2";

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setWithdrawalUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getTransactionData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? _location.state && _location.state.url ? _location.state.url : WebUrl._URL_WITHDRAWAL : null}
        title={t("WITHDRAWAL_RECORD")}
        state={{ from: window.location.pathname }}
      />
      <div className="container" style={{ marginBottom: "10px", padding: "0" }}>
        <DateRangeFilter2
          onSave={(start, end) => {
            setStartDate(moment(start));
            setEndDate(moment(end));
            getTransactionData(start, end);
            setSelectedDate("");
          }}
          options={true}
        />
        <div className="card card-package">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table reportTable">
                <thead>
                  <tr>
                    <th>{t("DATE")}</th>
                    <th>{t("STATUS")}</th>
                    <th>{t("AMOUNT")}</th>
                    <th>{t("DETAIL")}</th>
                  </tr>
                </thead>
                <tbody>
                  <DynamicLoadTable
                    pageSize={20}
                    apiQuery={withdrawalUrl}
                    searchEnabled={true}
                    isTable={true}
                    render={(item, i) => {
                      var statusHtml = "";
                      switch (item["approvalStatus"]) {
                        case TransactionStatus._APPROVED:
                          statusHtml = (
                            <span style={{ color: "#03BB3A" }}>
                              {t("APPROVED")}
                            </span>
                          );
                          break;
                        case TransactionStatus._REJECTED:
                          statusHtml = (
                            <span style={{ color: "red" }}>
                              {t("REJECTED")}
                            </span>
                          );
                          break;
                        case TransactionStatus._OPEN:
                          statusHtml = (
                            <span style={{ color: "#002e6c" }}>
                              {t("PROCESSING")}
                            </span>
                          );
                          break;
                        default:
                          statusHtml = (
                            <span style={{ color: "#002e6c" }}>
                              {t("PROCESSING")}
                            </span>
                          );
                          break;
                      }

                      return (
                        <tr key={i}>
                          <td>{item["date"]}</td>
                          <td>{statusHtml}</td>
                          <td>
                            {numberWithCurrencyFormat(
                              parseFloat(item["amount"]),
                              2
                            )}
                          </td>
                          <td
                            style={{ color: "#FFC159" }}
                            onClick={() => {
                              setItem(item);
                              toggle();
                            }}
                          >
                            {" "}
                            {t("VIEW")}{" "}
                          </td>
                        </tr>
                      );
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <Modal
            fade={false}
            isOpen={modal}
            toggle={toggle}
            contentClassName="game-lobby-modal modal-brand modal-bottom withdrawal"
          >
            <ModalBody
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#FFC159",
                textAlign: "center",
                padding: "40px 35px",
                width: "100%",
              }}
            >
              <div style={{ marginBottom: "16px" }}>
                {t("WITHDRAWAL_RECORD_DETAIL")}
              </div>

              {currentItem["approvalStatus"] == TransactionStatus._APPROVED && (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginBottom: "16px" }}
                >
                  <Icon path={mdiCheckCircle} size={0.8} color="#03BB3A" />

                  <div style={{ color: "#03BB3A", paddingLeft: "5px" }}>
                    {t("APPROVED")}
                  </div>
                </div>
              )}
              {currentItem["approvalStatus"] == TransactionStatus._REJECTED && (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginBottom: "16px" }}
                >
                  <Icon path={mdiCloseCircle} size={0.8} color="red" />

                  <div style={{ color: "red", paddingLeft: "5px" }}>
                    {t("REJECTED")}
                  </div>
                </div>
              )}
              {currentItem["approvalStatus"] == TransactionStatus._OPEN && (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ marginBottom: "16px" }}
                >
                  <Icon path={mdiDotsHorizontalCircle} size={0.8} color="#002e6c" />

                  <div style={{ color: "#002e6c", paddingLeft: "5px" }}>
                    {t("PROCESSING")}
                  </div>
                </div>
              )}

              <div
                style={{
                  fontSize: "16px",
                  color: "#002E6C",
                  marginBottom: "30px",
                }}
              >
                {numberWithCurrencyFormat(parseFloat(currentItem["amount"]), 2)}
              </div>
              <div
                className="d-flex"
                style={{ width: "100%", fontSize: "12px", color: "#002E6C" }}
              >
                <div style={{ width: "50%", textAlign: "left" }}>
                  {t("DATE_AND_TIME")}
                </div>
                <div style={{ width: "50%", textAlign: "right" }}>
                  {currentItem["dateTime"]}
                </div>
              </div>
              <div
                className="d-flex"
                style={{ flex: "100%", fontSize: "12px", color: "#002E6C" }}
              >
                <div style={{ width: "50%", textAlign: "left" }}>
                  {t("NEW_BALANCE")}
                </div>
                <div style={{ width: "50%", textAlign: "right" }}>
                  {numberWithCurrencyFormat(
                    parseFloat(currentItem["postMemberPrimaryBalance"]),
                    2
                  )}
                </div>
              </div>

              <Button
                style={{ marginTop: "35px" }}
                className="btn btn-block btn-brand-gradient"
                onClick={toggle}
              >
                {t("DONE")}
              </Button>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default WalletHistory;
