import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { map } from "lodash";
import Header from '../../components/custom/Header';
import Numpad2 from "../../components/custom/Numpad2.js";
import { ApiKey, ApiUrl, DEFAULT_PAGE_SIZE, PanelType, TransactionType, WebUrl, _MAIN_WALLET_ID } from "../../util/Constant.js";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import { useDispatch, useSelector } from "react-redux";
import { checkIsLoggedIn } from "../../redux/AuthAction.js";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty, numberWithCurrencyFormat2 } from "../../util/Util.js";
import { AsyncPaginate } from "react-select-async-paginate";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import PinInput from 'react-pin-input';
import Keyboard from 'react-simple-keyboard';
import { Alert, Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import Spinner from "react-bootstrap/Spinner";
import classnames from "classnames";


/// <summary>
/// Author : 
/// </summary>
const Transfer = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const [paymentMethodActive, setPaymentMethodActive] = useState(1);

    const [loadingWalletData, setLoadingWalletData] = useState(true);
    const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [fromOptions, setFromOptions] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [showTaxWarning, setShowTaxWarning] = useState(false);
    const [transferFromId, setTransferFromId] = useState("");
    const [transferToId, setTransferToId] = useState("");
    const [transferFrom, setTransferFrom] = useState("");
    const [transferTo, setTransferTo] = useState("");
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [isTransferSuccess, setIsTransferSuccess] = useState(false);
    const [paidAmount, setPaidAmount] = useState();
    const [deductedAmount, setDeductedAmount] = useState();
    const [transferDate, setTransferDate] = useState();
    const [currentInput, setCurrentInput] = useState();

    const [member, setMember] = useState({});
    const [selectedMember, setSelectedMember] = useState();
    const [internalTransferLimit, setInternalTransferLimit] = useState(0);
    const [internalTransferLimitBalance, setInternalTransferLimitBalance] = useState(0);
    const [showFullnamePopup, setShowFullnamePopup] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [createPinEntry, setCreatePinEntry] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
    const [pinEntered, setPinEntered] = useState(false);
    const [forgetPin, setForgetPin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [submittedInternalTransferData, setSubmittedInternalTransferData] = useState({});
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [transferFromOptions, setTransferFromOptions] = useState("");
    const [isTestAccount, setIsTestAccount] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [paymentList, setPaymentList] = useState([]);

    const _COUNTDOWN_PERIOD = 60;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 3000;
    const [isFinalChecking, setIsFinalChecking] = useState(true);
    var _userData = useSelector(state => state.authState.userData);
    const [conversionRate, setConversionRate] = useState(0);
    const _COUNTDOWN_RESET = 0;
    const [resetCountDown, setResetCountDown] = useState(false);
    const [internalTransferStatus, setInternalTransferStatus] = useState(false);

    const rightMenuList = [
        {
            title: "WALLET_TRANSFER_RECORD",
            url: WebUrl._URL_WALLET_TRANSFER_HISTORY,
            from: window.location.pathname
        },
    ];

    _userData.internalTransferStatus === true &&
        rightMenuList.push({
            title: "INTERNAL_TRANSFER_RECORD",
            url: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
            from: window.location.pathname
        });

    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);

    const keyboard = useRef();

    const _SELECT_STYLES = {
        menu: (styles, state) => ({
            ...styles,
            margin: '0px',
            padding: '0px',
            background: '#132144',
            // borderWidth: '1px',
            // borderColor: '#A4A4A4',
            borderRadius: '0px',
            border: '1px solid #A4A4A4'
        }),
        control: (styles, state) => ({
            ...styles,
            borderRadius: '50px',
            boxShadow: '0px 3px 17px 0px rgba(0,0,0,.1)',
            height: '35px',
            fontSize: '14px'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                background: '#132144',
                color: '#A4A4A4'
            };
        },
        singleValue: styles => ({
            ...styles,
            paddingLeft: '1rem',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#002e6c'
        }),
        placeholder: styles => ({
            ...styles,
            paddingLeft: '1rem',
            fontSize: '14px',
            color: '#000000'
        })
    }

    const {
        register: registerWalletTransfer,
        handleSubmit: handleSubmitWalletTransfer,
        errors: errorsWalletTransfer,
        setValue: setValueWalletTransfer
    } = useForm();
    const {
        register: registerInternalTransfer,
        handleSubmit: handleSubmitInternalTransfer,
        errors: errorsInternalTransfer,
        control: controlInternalTransfer,
        setValue: setValueInternalTransfer
    } = useForm();
    const {
        register: registerFullname,
        handleSubmit: handleSubmitFullname,
        errors: errorsFullname,
        setValue: setValueFullname
    } = useForm();

    const memberList = [
        {
            id: 1,
            title: 'Barcove005',
        },
        {
            id: 2,
            title: 'test1',
        },
    ];

    useEffect(() => {
        if (paymentMethodActive === 2) {
            initInternalTransfer();
        }
        else {
            setSelectedMember();
        }
    }, [paymentMethodActive]);

    const initInternalTransfer = async () => {
        try {
            let memberFullname = "";
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FULLNAME);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                memberFullname = data["userFullName"];
            }
            if (_userData && _userData.internalTransferLimit > 0) {
                var limitJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE);
                if (limitJson[ApiKey._API_SUCCESS_KEY]) {
                    let limitData = limitJson[ApiKey._API_DATA_KEY];
                    setInternalTransferLimitBalance(limitData["memberInternalTransferLimitBalance"]);
                    setInternalTransferLimit(limitData["memberInternalTransferLimit"]);
                }
            }

            if (stringIsNullOrEmpty(memberFullname)) {
                setShowFullnamePopup(true);
            }
            else {
                let params = {};
                let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
                if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                    var theInterval = setInterval(() => {
                        if (elePin['current'] == undefined)
                            return;

                        if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                            elePin['current']['elements'][0].focus();
                        }
                        else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                            elePin['current']['elements'][1].focus();
                        }
                        else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                            elePin['current']['elements'][2].focus();
                        }
                        else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                            elePin['current']['elements'][3].focus();
                        } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                            eleConfirm['current']['elements'][0].focus();
                        }
                        else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                            eleConfirm['current']['elements'][1].focus();
                        }
                        else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                            eleConfirm['current']['elements'][2].focus();
                        }
                        else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                            eleConfirm['current']['elements'][3].focus();
                        }
                        else {

                            var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                            var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                            if (currpin != currconfpin) {
                                setInvalidPin(true);
                                elePin['current'].clear();
                                eleConfirm['current'].clear();
                                elePin['current'].focus();
                            }
                            else {
                                setInvalidPin(false);
                                clearInterval(theInterval);
                                submitPin(currpin, currconfpin);
                            }
                        }

                    }, 50);
                    setTheInterval(theInterval);
                    setCreatePinEntry(true);
                }
                else {
                    setIsFinalChecking(false);
                }
            }
            _dispatch(checkIsLoggedIn());
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    async function submitPin(pin, conf) {
        try {
            let params = {
                "pinNumber": pin,
                "confirmPinNumber": conf
            };
            let responseJson = await ApiEngine.post(ApiUrl._API_SET_MEMBER_PIN_NUMBER, createFormBody(params));
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setCreatePinEntry(false);
                setIsFinalChecking(false);
            }
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t("CREATE") + " " + t(responseJson[ApiKey._API_MESSAGE_KEY])));

            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(0);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(0);
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY].data;
            data = data.filter(x => x.id !== _userData.userId);
            var formattedData;
            formattedData = data.filter(x => x.id !== member.id).map((m) => { return { label: m.username, value: m.id } });
            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1
                }
            };
        }
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setIsLoading(true);
        let testAccountStatusJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_TEST_ACCOUNT_STATUS);
        if (testAccountStatusJson[ApiKey._API_SUCCESS_KEY]) {
            let isTestAccountResult = testAccountStatusJson[ApiKey._API_DATA_KEY] === true || testAccountStatusJson[ApiKey._API_DATA_KEY] === 'true';
            setIsTestAccount(isTestAccountResult);
            if (!isTestAccountResult) {
                setPaymentList(
                    [
                        {
                            id: 1,
                            title: 'WALLET_TRANSFER',
                        },
                        {
                            id: 2,
                            title: 'INTERNAL_TRANSFER',
                        },
                    ]
                )
            } else {
                setPaymentList(
                    [
                        {
                            id: 1,
                            title: 'WALLET_TRANSFER',
                        }
                    ]
                )
            }
        }

        //let transferStatusJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_INTERNAL_TRANSFER_STATUS);
        let slotRolloverJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);

        if (slotRolloverJson[ApiKey._API_SUCCESS_KEY]) {
            setInternalTransferStatus(slotRolloverJson[ApiKey._API_DATA_KEY] === false);
        }

        setIsLoading(false);
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_FLAG_TRANSFER_BACK_MAIN_WALLET);
        if (responseJson[ApiKey._API_DATA_KEY] == 1) {
            setIsTransferBackToMainWallet(true);
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                    let option = { label: product.productName + " - " + numberWithCurrencyFormat2(parseFloat(product.balance), 2, true), value: product.productId, bonus: product.bonus, conversionRate: product.productConversionRate };
                    tempFromOptions.push(option);
                    //if (product.productId !== selectedFromOptions) {
                    //    tempToOptions.push(option);
                    //}
                })

                setFromOptions(tempFromOptions);
            }
        }
        else {
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                let tempFromOptions = [];
                let tempToOptions = [];

                responseJson[ApiKey._API_DATA_KEY].map((product) => {
                    let option = { label: product.productName + " - " + numberWithCurrencyFormat2(parseFloat(product.balance), 2, true), value: product.productId, conversionRate: product.productConversionRate };
                    if (product.category == "Lottery") {
                        tempToOptions.push(option);
                    } else {
                        tempFromOptions.push(option);
                    }
                })

                /*
                 foreach (var product in responseJson[ApiKey._API_DATA_KEY])
                 {
                 //code
                 }
                 */

                setFromOptions(tempFromOptions);
                setToOptions(tempToOptions);
            }
        }
        setLoadingWalletData(false);
        _dispatch(checkIsLoggedIn());
    }

    function setTransfertoToption(productId) {
        let tempProductId = parseInt(productId);
        let tempFromOption = fromOptions.filter(x => x.value === tempProductId)[0];
        let checkFromBalance = tempFromOption.label.substring(tempFromOption.label.indexOf(" - "), tempFromOption.label.length);
        setTransferFromOptions(checkFromBalance.slice(checkFromBalance.indexOf('-') + 2));
        setTransferFrom(tempFromOption.label);
        setTransferFromId(tempFromOption.value);
        setConversionRate(tempFromOption.conversionRate);
        let tempToOptions = [];
        if (isTransferBackToMainWallet) {
            if (tempProductId == 0) {
                walletList.map((product) => {
                    if (product.productId !== tempProductId) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat2(parseFloat(product.balance), 2, true), value: product.productId, conversionRate: product.productConversionRate };
                        tempToOptions.push(option);
                    }
                });
            }
            else {
                walletList.map((product) => {
                    if (product.productId == 0) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat2(parseFloat(product.balance), 2, true), value: product.productId, conversionRate: product.productConversionRate };
                        tempToOptions.push(option);
                    }
                });
            }
        }
        else {
            setShowTaxWarning(false);
            if (tempProductId == 0) {
                walletList.map((product) => {
                    if (product.productId !== tempProductId) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat2(parseFloat(product.balance), 2, true), value: product.productId, conversionRate: product.productConversionRate };
                        tempToOptions.push(option);
                        setTransferToId(tempToOptions[0].value);
                    }
                });
            }
            else {
                walletList.map((product) => {
                    if (product.productId == 0) {
                        let option = { label: product.productName + " - " + numberWithCurrencyFormat2(parseFloat(product.balance), 2, true), value: product.productId, conversionRate: product.productConversionRate };
                        tempToOptions.push(option);
                        setTransferToId(option.value);
                    }
                });
            }
        }
        setToOptions(tempToOptions);
    }

    const handleTransferToOnChange = (productId) => {
        let tempToOption = toOptions.filter(x => x.value === parseInt(productId))[0];
        setTransferTo(tempToOption.label);
        setTransferToId(tempToOption.value);
        if (tempToOption.value !== 0) { setConversionRate(tempToOption.conversionRate); }
    }

    const submitWalletTransferForm = async (data, e) => {
        e.preventDefault();
        _dispatch(setBusy());
        let params = {
            "fromWallet": transferFromId,
            "toWallet": transferToId,
            "amount": data.amount
        }

        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION, createFormBody(params));
        let responseMsg = responseJson[ApiKey._API_MESSAGE_KEY];
        responseMsg = responseMsg.replace("Amount", t("AMOUNT"));
        responseMsg = responseMsg.replace("From", t("FROM"));
        responseMsg = responseMsg.replace("Wallet", t("MAIN_WALLET"));
        responseMsg = responseMsg.replace("to", t("TO"));
        if (responseMsg.includes("Success")) {
            responseMsg = responseMsg.replace("Success", t("SUCCESS"));
        }
        else {
            responseMsg = responseMsg.replace("Failed", t("FAILED"));
            if (responseMsg.includes("Transaction")) {
                responseMsg = responseMsg.replace("Transaction", t("TRANSACTION"));
            }
        }
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(setIdle());
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            setPaymentMethodActive(-1);
            setTimeout(() => setPaymentMethodActive(1), 1);
            setLoadingWalletData(true);
            await init();

            setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
            setDeductedAmount(responseJson[ApiKey._API_DATA_KEY].deductedAmount);
            setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
            setIsTransferSuccess(true);
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

            e.target.reset();
            resetFormField();
        } else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : -
    /// </summary>
    function resetFormField() {
        setTransferFrom("");
        setTransferFromId("");
        setTransferTo("");
        setTransferToId("");
        setValueWalletTransfer("amount", 0);
        setCurrentInput();
    }

    const internalTransferPreSubmit = (data, e) => {
        e.preventDefault();
        setSubmittedInternalTransferData(data);
        setShowPinEntry(true);
    }

    const submitInternalTransferForm = async () => {
        setShowPinEntry(false);
        _dispatch(setBusy());
        let data = submittedInternalTransferData;
        let params = {
            "transactionTypeId": TransactionType._TRANSFER,
            "pinNumber": pinNumberFinal,
            "amount": data.amount,
            "targetMember": selectedMember.value,
            "channel": "",
            "panel": PanelType._MEMBERSITE,
        };

        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_TRANSACTION, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPaymentMethodActive(-1);
            setTimeout(() => setPaymentMethodActive(2), 1);
            await initInternalTransfer();
            _dispatch(checkIsLoggedIn());
            setSelectedMember(null);
            setValueInternalTransfer("userId", "");

            var limitJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE);
            if (limitJson[ApiKey._API_SUCCESS_KEY]) {
                let limitData = limitJson[ApiKey._API_DATA_KEY];
                setInternalTransferLimitBalance(limitData["memberInternalTransferLimitBalance"]);
            }
        }
        resetEnteredPin();
        if (responseJson[ApiKey._API_MESSAGE_KEY] === "TRANSFER_LIMIT_ERROR") {
            _dispatch(showResponseMessage(false, t("TRANSFER_LIMIT_EXCEEDED", { value: member["internalTransferLimit"] })));
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        _dispatch(setIdle());
    }

    function resetEnteredPin() {
        setPinNumberFinal("");
        setPinNumberFocused(0);
        setPinNumberFinalFocused(0);
        setEnteredPinNumberFinal("");
        setPinEntered(false);
    }

    async function sendPinCode() {
        if (countdownPeriod < 1) {
            let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_PHONE_VERIFIED);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    "phoneNumber": hpNum,
                    "isShareholder": "false"
                };
                let smsJson = await ApiEngine.post(ApiUrl._API_SEND_PIN_CODE, createFormBody(params));
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                }
            }
            else {
                setForgetPin(false);
                _dispatch(
                    showResponseMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        responseJson[ApiKey._API_MESSAGE_KEY]
                    )
                );
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT])
        }
    }

    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;
        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            if (key === "{enter}") {
                if (pinEntered) {
                    setShowPinEntry(false);
                }
            }
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                eleFinalPin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    eleFinalPin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberFinal(pinStr);
                    setPinEntered(true);
                }
            }
        }
        else {
            eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            eleFinalPin['current']['elements'][currentIndex].focus();
        }
    }

    function onCreatePinKeyPress(key) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (key !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                elePin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumber(pinStr);
                }
            }
            else {
                currentIndex = pinNumberConfirmFocused;
                eleConfirm['current']['elements'][currentIndex].state.value = key;
                pinConfirmStr += key;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex <= 3) {
                    eleConfirm['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberConfirm(pinConfirmStr);
                    submitPin(pinNumber, pinConfirmStr);
                }
            }
        }
        else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin['current']['elements'][currentIndex].focus();
        }
    }

    async function submitFullName(data) {
        let params = {
            "userFullName": data.userFullName,
        };
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
            createFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            setShowFullnamePopup(false);
            let params = {};
            let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
            if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                var theInterval = setInterval(() => {
                    if (elePin['current'] == undefined)
                        return;

                    if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                        elePin['current']['elements'][0].focus();
                    }
                    else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                        elePin['current']['elements'][1].focus();
                    }
                    else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                        elePin['current']['elements'][2].focus();
                    }
                    else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                        elePin['current']['elements'][3].focus();
                    } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                        eleConfirm['current']['elements'][0].focus();
                    }
                    else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                        eleConfirm['current']['elements'][1].focus();
                    }
                    else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                        eleConfirm['current']['elements'][2].focus();
                    }
                    else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                        eleConfirm['current']['elements'][3].focus();
                    }
                    else {

                        var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                        var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                        if (currpin != currconfpin) {
                            setInvalidPin(true);
                            elePin['current'].clear();
                            eleConfirm['current'].clear();
                            elePin['current'].focus();
                        }
                        else {
                            setInvalidPin(false);
                            clearInterval(theInterval);
                            submitPin(currpin, currconfpin);
                        }
                    }

                }, 50);
                setTheInterval(theInterval);
                setCreatePinEntry(true);
            }
        }
        else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
        }
        init();
    }

    return (
        <>
            <div className="container-btn-below">
                <div>
                    <Header
                        history={_history}
                        url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_MAIN}
                        title={t("TRANSFER")}
                        hasBackButton={true}
                        hasRightMenu={true}
                        rightMenuList={rightMenuList}
                    />
                    {internalTransferStatus ? <div className="transfer-header-tab-container">
                        {paymentList &&
                            paymentList.length &&
                            map(paymentList, (item, index) => {
                                return (
                                    <div key={index} className={
                                        "transfer-tab-list-item " +
                                        classnames({
                                            "active": item.id == paymentMethodActive,
                                            "full-width": paymentList.length == 1,
                                        })
                                    } onClick={() => setPaymentMethodActive(item.id)}>
                                        {t(item.title)}
                                    </div>
                                );
                            })
                        }
                      </div>
                    :
                      <></>
                    }
                    {paymentMethodActive == 1 &&
                        <form onSubmit={handleSubmitWalletTransfer(submitWalletTransferForm)}>
                            <div className="payment-form">
                                <div className="custom-input-container">
                                    <h4 className="mt-4 mb-3">{t("TRANSFER_FROM")}</h4>
                                    <div className="input-with-icon-container">
                                        <select className="transfer-select transfer-from" onChange={(e) => setTransfertoToption(e.target.value)}>
                                            <option value="" disabled selected hidden>{loadingWalletData ? t("GETTING_WALLET_DATA") + "..." : t("PLEASE_SELECT_WALLET")}</option>
                                            {fromOptions &&
                                                fromOptions.length &&
                                                !loadingWalletData &&
                                                map(fromOptions, (item, index) => {
                                                    return (
                                                        <option key={index} className={"transfer-select-option option-" + item.value} value={item.value}>{item.value === 0 ? t("MAIN_WALLET") + item.label.substring(11) : item.label}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        { }
                                    </div>
                                </div>
                                <div className="custom-input-container">
                                    <h4 className="mt-4 mb-3">{t("TRANSFER_TO")}</h4>
                                    <div className="input-with-icon-container">
                                        <select className="transfer-select transfer-to" disabled={stringIsNullOrEmpty(transferFrom)} onChange={(e) => handleTransferToOnChange(e.target.value)}>
                                            <option value="" disabled selected hidden>{loadingWalletData ? t("GETTING_WALLET_DATA") + "..." : t("PLEASE_SELECT_WALLET")}</option>
                                            {toOptions &&
                                                toOptions.length &&
                                                !loadingWalletData &&
                                                map(toOptions, (item, index) => {
                                                    return (
                                                        <option key={index} className={"transfer-select-option option-" + item.value} value={item.value}>{item.label}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                    </div>
                                    {conversionRate !== 0 && conversionRate !== 1 &&
                                        <p className="conversion-rate-text mt-2">{t("CONVERSION_RATE") + " : 1"}&#3647; {"(" + t("MAIN_WALLET") + ") "} <i className="fas fa-arrows-alt-h"></i>
                                            {" " + numberWithCurrencyFormat(conversionRate, 3, true) + " (" + ((transferToId === "" || transferToId === _MAIN_WALLET_ID) ? transferFrom.substring(0, transferFrom.indexOf(" -")) : transferTo.substring(0, transferTo.indexOf(" -"))) + ")"}
                                        </p>
                                    }
                                </div>
                                <div className="custom-input-container">
                                    <h4 className="mt-4 mb-3">{t("AMOUNT")}</h4>
                                    <div className="input-with-icon-container">
                                        <input
                                            type="number"
                                            readOnly
                                            placeholder={"0.00"}
                                            name="amount"
                                            onClick={(e) => {
                                                setCurrentInput(e.target);
                                            }}
                                            ref={registerWalletTransfer({
                                                required: t("PLEASE_ENTER_AMOUNT"),
                                                min: {
                                                    value: 1,
                                                    message: t('PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE', { amount: "1.00" })
                                                },
                                                max: {
                                                    value: transferFromOptions,
                                                    message: t('PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE')
                                                }
                                            })}
                                        />
                                        {errorsWalletTransfer.amount && <div className="invalid-feedback">{errorsWalletTransfer.amount.message}</div>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="submit" disabled={transferFrom === "" || transferTo === ""} className="btn custom-btn mt-4 mb-5 w-100" >{t("SUBMIT")}</button>
                            </div>
                        </form>
                    }
                    {paymentMethodActive == 2 && !isTestAccount &&
                        <form onSubmit={handleSubmitInternalTransfer(internalTransferPreSubmit)}>
                            <div className="payment-form ">
                                <div className="custom-input-container mt-4" id="select-member-id">
                                    <div className="form-group m-b-15">
                                        <label className="col-form-label mb-3"><b>{t("MEMBER")}</b></label>
                                        <div>
                                            <Controller
                                                className="internal-transfer-dropdown"
                                                control={controlInternalTransfer}
                                                errors={errorsInternalTransfer}
                                                name="userId"
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <AsyncPaginate
                                                            styles={_SELECT_STYLES}
                                                            placeholder={t("PLEASE_SELECT_MEMBER")}
                                                            debounceTimeout={250}
                                                            loadOptions={getMemberList}
                                                            additional={{
                                                                page: 1,
                                                            }}
                                                            value={selectedMember}
                                                            onChange={(e) => { onChange(e.value); setSelectedMember(e) }}
                                                            noOptionsMessage={() => t("NO_OPTIONS")}
                                                            loadingMessage={() => t("LOADING...")}
                                                        />
                                                    )
                                                }}
                                                rules={{ required: "PLEASE_SELECT_MEMBER" }} />
                                            {errorsInternalTransfer.userId && <div className="invalid-feedback">{t(errorsInternalTransfer.userId.message)}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-input-container mt-4">
                                    <h4 className=" mb-3">{t("AMOUNT")}</h4>
                                    <div className="input-with-icon-container">
                                        <input
                                            disabled={isFinalChecking}
                                            type="number"
                                            readOnly
                                            placeholder={"0.00"}
                                            name="amount"
                                            onClick={(e) => {
                                                setCurrentInput(e.target);
                                            }}
                                            ref={registerInternalTransfer({
                                                required: t("PLEASE_ENTER_AMOUNT"),
                                                min: {
                                                    value: 1,
                                                    message: t('PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE', { amount: "1.00" })
                                                },
                                                max: {
                                                    value: internalTransferLimit > 0 ? _userData.walletBalance > internalTransferLimitBalance ? internalTransferLimitBalance : _userData.walletBalance : _userData.walletBalance,
                                                    message: internalTransferLimit > 0 ? _userData.walletBalance > internalTransferLimitBalance ? t('PLEASE_ENTER_VALUE_NOT_MORE_THAN_VARIABLE', { amount: numberWithCurrencyFormat2(internalTransferLimitBalance, 2, true) }) : t('PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE') : t('PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE')
                                                }
                                            })}
                                        />
                                        {errorsInternalTransfer.amount && <div className="invalid-feedback">{errorsInternalTransfer.amount.message}</div>}
                                    </div>
                                    {internalTransferLimit !== 0 ?
                                        <div className="mt-1 mr-3 mb-3 float-right">
                                            <span style={{ color: "#FF334B", fontSize: "10px" }}>{t("AVAILABLE_TRANSFER_LIMIT")} :  {numberWithCurrencyFormat2(internalTransferLimitBalance, 2, true) + "/" + numberWithCurrencyFormat2(internalTransferLimit, 2, true)}  </span>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn custom-btn mt-4 mb-5 w-100">{t("SUBMIT")}</button>
                            </div>
                        </form>
                    }
                </div>
            </div>
            {currentInput &&
                <Numpad2
                    currentBalance={_userData.walletBalance}
                    currentInput={currentInput}
                    setCurrentInput={(e) => {
                        setCurrentInput(e);
                    }}
                    contentClassName="popup-transfer-numpad"
                />
            }
            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
                backdrop={true}
                isOpen={showPinEntry}
                centered
            >
                <ModalBody>
                    <form>
                        <div className="display-wrapper">
                            <button type="button" style={{
                                alignSelf: "flex-end",
                                backgroundColor: "transparent",
                                border: "none",
                                marginRight: "15px",
                                fontSize: "2rem",
                                color: "#002e6c",
                                marginTop: "-15px",
                                float: "right",
                                fontWeight: "bold"
                            }}
                                onClick={() => {
                                    clearInterval(theInterval);
                                    resetEnteredPin();
                                    setShowPinEntry(false);
                                }}
                                data-dismiss="modal"
                                aria-hidden="true">
                                <span>&times;</span>
                            </button>
                            <strong className="text-brand display-value" style={{ fontSize: "20px" }}>{t("ENTER_TRANSACTION_PIN")}</strong>
                            <PinInput style={{ marginTop: "37px" }}
                                ref={eleFinalPin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={showPinEntry}
                                onChange={(value, index) => {
                                    setPinNumberFinalFocused(index);
                                }}
                                type="numeric"
                                inputMode="number"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid white" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

                            <div className="text-brand text-center" style={{ marginTop: "10px" }} onClick={() => { setForgetPin(true); sendPinCode(); }}>{t("RESET_PIN")}</div>
                            {forgetPin && <p className="text-brand" style={{ textAlign: "center", marginTop: "5px" }}>{t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod} {t("SECOND")}</p>}
                            {showAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", left: "0", width: "100%" }}>
                                <div className="container">
                                    <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                                </div>
                            </div>}

                        </div>
                        <Keyboard
                            layout={{
                                'default': [
                                    "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                ]
                            }}
                            display={{
                                '{bksp}': "<i className='fas fa-backspace'></i>",
                            }}
                            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                            keyboardRef={r => (keyboard.current = r)}
                            onKeyPress={onVerifyPinKeyPress}
                            disableButtonHold
                            disableCaretPositioning
                        />

                        <button
                            type="button"
                            className="btn btn-block btn-brand-gradient btn-done"
                            onClick={() => submitInternalTransferForm()}>
                            {t("SUBMIT_TRANSACTION")}
                        </button>
                    </form>
                </ModalBody>
            </Modal>
            {
                //CREATE NEW PIN
            }
            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
                backdrop={true}
                isOpen={createPinEntry}
                centered
            >
                <ModalBody>
                    <form>
                        <div className="display-wrapper">
                            <button type="button" style={{
                                alignSelf: "flex-end",
                                backgroundColor: "transparent",
                                border: "none",
                                marginRight: "15px",
                                fontSize: "2rem",
                                color: "#002e6c",
                                marginTop: "-15px",
                                fontWeight: "bold",
                            }}
                                onClick={() => { setCreatePinEntry(false); }}
                                data-dismiss="modal"
                                aria-hidden="true">
                                <span>&times;</span>
                            </button>
                            <strong className="text-brand display-value" style={{ fontSize: "20px" }}>{t("CREATE_TRANSACTION_PIN")}</strong>
                            <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("ENTER_YOUR_PIN")}</div>
                            <PinInput
                                ref={elePin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid white" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("CONFIRM_YOUR_PIN")}</div>
                            <PinInput
                                ref={eleConfirm}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberConfirmFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid white" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>

                        </div>
                        <Keyboard
                            layout={{
                                'default': [
                                    "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                ]
                            }}
                            display={{
                                '{bksp}': "<i className='fas fa-backspace'></i>",
                            }}
                            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                            keyboardRef={r => (keyboard.current = r)}
                            onKeyPress={onCreatePinKeyPress}
                            disableButtonHold
                            disableCaretPositioning
                        />
                    </form>
                </ModalBody>
            </Modal>
            {
                //CREATE NEW USER FULL NAME
            }
            <Modal
                id="custom-modal"
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad"
                isOpen={showFullnamePopup}
                centered
            >
                <ModalBody>
                    <form onSubmit={handleSubmitFullname(submitFullName)}>
                        <div className="display-wrapper enter-fullname-modal">
                            <strong className="reload-title text-yellow d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_FULL_NAME")}</strong>
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{t("IN_YOUR_BANK_ACCOUNT")}</strong>
                            <input
                                type="text"
                                name="userFullName"
                                className="withdrawal-fullName-input"
                                ref={registerFullname({
                                    required: 'FIELD_REQUIRED',
                                    pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" }
                                })}
                                onChange={(e) => setValueFullname('userFullName', e.target.value)}
                            />
                            {errorsFullname.userFullName && <div className="invalid-feedback">{t(errorsFullname.userFullName.message)}</div>}
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "200px" }}>{t("SUBMIT")}</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    );

}

export default Transfer;