import React, { useState, useEffect, useContext } from "react";
import ApiEngine from "../../util/ApiEngine";
import { useTranslation } from "react-i18next";
import {
  ApiKey, ApiUrl, WebUrl, SessionKey,
  LanguageOption,
} from "../../util/Constant";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import Header from "../../components/custom/Header";
import { Spinner } from "react-bootstrap";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat2 } from "../../util/Util";
import {
  setBusy,
  setIdle,
  showResponseMessage,
  showResponseMessageWithoutT,
} from "../../redux/AppAction";
import classnames from "classnames";


//deposit platform
import NormalDeposit from "./NormalDeposit";

//other image
import InfoIcon from "../../assets/img/v3/icon.png";

/// <summary>
/// Author : 
/// </summary>
const Deposit = (props) => {
  const _location = useLocation();
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  let _userData = useSelector((state) => state.authState.userData);
  let _history = useHistory();

  const {
    register: registerFullname,
    handleSubmit: handleSubmitFullname,
    errors: errorsFullname,
    setValue: setValueFullname
  } = useForm();


  //arrange sequence
  const _INSTANT_DEPOSIT = 1;
  const _NORMAL_DEPOSIT = 2;

  //bonus deposit
  const [bonusModalVisible, setBonusModalVisible] = useState(false);
  const [bonusDepositList, setBonusDepositList] = useState([]);
  const [bonusDeposit, setBonusDeposit] = useState("");
  const [bonusDepositBonus, setBonusDepositBonus] = useState(0);
  const [bonusDepositMinDeposit, setBonusDepositMinDeposit] = useState(0);
  const [bonusDepositRollover, setBonusDepositRollover] = useState(0);
  const [bonusDepositMaxBonus, setBonusDepositMaxBonus] = useState(0);
  const [bonusDepositMaxLimit, setBonusDepositMaxLimit] = useState(0);
  const [bonusDepositDayLimit, setBonusDepositDayLimit] = useState(0);
  const [bonusDepositFreeSpinStatus, setBonusDepositFreeSpinStatus] = useState(false);
  const [bonusDepositName, setBonusDepositName] = useState("");
  const [bonusDepositImage, setBonusDepositImage] = useState("");
  const [bonusDepositPopImage, setBonusDepositPopImage] = useState("");
  const [bonusRemainingCount, setBonusRemainingCount] = useState("");
  const [bonusRefreshDateTime, setBonusRefreshDateTime] = useState("");
  const [gameCategory, setGameCategory] = useState([]);

  //member fullname
  const [showFullnamePopup, setShowFullnamePopup] = useState(false);

  //pending transaction
  const [hasPendingTxn, setHasPendingTxn] = useState(false);
  const [countdownStr, setCountdownStr] = useState("");
  const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
  const [countdownTime, setCountdownTime] = useState();
  const [depositCountdownPeriod, setDepositCountdownPeriod] = useState(0);
  const _SECONDS_IN_MS = 1000;

  //member transaction history
  const [memberTransactionHistory, setMemberTransactionHistory] = useState([]);

  //other
  const [headerMsg, setHeaderMsg] = useState("");
  const [paymentMethodActive, setPaymentMethodActive] = useState(_NORMAL_DEPOSIT);
  const [transType, setTransType] = useState("");
  const rightMenuList = [
    {
      title: "DEPOSIT_RECORD",
      url: WebUrl._URL_DEPOSIT_RECORD
    }
  ];

  const bonusSlick = {
    slidesToShow: 2.5,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: false,
    swipeToSlide: true,
    speed: 1000,
    autoplay: false,
  };

  //useEffect
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (depositCountdownPeriod > 0) {
      clearTimeout(countdownTime);
      setCountdownTime(
        setTimeout(() => {
          let tempPeriod = depositCountdownPeriod - 1;
          setDepositCountdownPeriod(tempPeriod);
          let tempX = moment.duration(tempPeriod);
          let tempMM = Math.floor(tempX["_milliseconds"] / 60);
          let tempSS = tempX["_milliseconds"] - tempMM * 60;
          setTimeDiffSeconds(tempX["_milliseconds"]);
          let tempStr =
            (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
            " " +
            t("MINUTES") +
            " " +
            (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
            " " +
            t("SECOND");
          setCountdownStr(tempStr);
        }, _SECONDS_IN_MS)
      );
    }
  }, [depositCountdownPeriod]);

  //init/call function
  const checkPendingTxn = async () => {
    setHeaderMsg("");
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PENDING_TRANSACTION
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      if (responseJson[ApiKey._API_DATA_KEY] != null) {
        clearTimeout(countdownTime);
        let tempS = parseInt(responseJson[ApiKey._API_DATA_KEY]["timeSpan"]);
        setTransType(responseJson[ApiKey._API_DATA_KEY]["transType"]);
        setTimeDiffSeconds(tempS);
        setDepositCountdownPeriod(tempS < 0 ? 0 : tempS);
        setHasPendingTxn(true);
        setHeaderMsg(t("NEW_TXN_PROHIBITED"));
      }
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  const getBonusDeposit = async () => {
    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT + "?memberId=" + _userData.userId + "&languageId=" + language);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let itemList = []

      let item = null;

      data.map((row, index) => {
        item =
        {
          name: row["bonusName"],
          bonusId: row["id"],
          info:
          {
            bonus: row["bonus"],
            minimumDeposit: row["minimumDeposit"],
            rollover: row["rollover"],
            maxBonus: row["maxBonus"],
            freeSpinStatus: row["freeSpinStatus"],
            maxLimitPerMember: row["maxLimitPerMember"],
            dayLimit: row["dayLimit"],
            image: row["bonusImage"],
            popImage: row["popupImage"],
            remainingCount: row["remainingCount"],
            refreshDateTime: row["refreshDateTime"] !== "" ? moment(row["refreshDateTime"], "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss") : "",
            gameCategory: row["gameCategory"],
          }
        }

        itemList.push(item);
        if (item.bonusId === 1) { updateBonusPackage(item); }
      })
      setBonusDepositList(itemList)

    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  };

  const getMemberFullName = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FULLNAME);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (stringIsNullOrEmpty(data["userFullName"])) {
        setShowFullnamePopup(true);
      }
    }
  }

  const getMemberTransactionHistory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_TRANSACTION_HISTORY);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setMemberTransactionHistory(data);
    }
  }

  const init = () => {
    checkPendingTxn();
    getMemberFullName();
    getBonusDeposit();
    getMemberTransactionHistory();
  }

  //other function
  function showBonus(item) {
    if (item.bonusId != -1) {
      setBonusModalVisible(true);
    }
  }

  function updateBonusPackage(item) {
    setBonusDepositBonus(item.info.bonus);
    setBonusDepositMinDeposit(item.info.minimumDeposit);
    setBonusDepositRollover(item.info.rollover);
    setBonusDepositMaxBonus(item.info.maxBonus);
    setBonusDepositFreeSpinStatus(item.info.freeSpinStatus);
    setBonusDepositName(item.name);
    setBonusDepositMaxLimit(item.info.maxLimitPerMember);
    setBonusDepositDayLimit(item.info.dayLimit);
    setBonusDepositImage(item.info.image);
    setBonusDepositPopImage(item.info.popImage);
    setBonusDeposit(item.bonusId);
    setBonusRemainingCount(item.info.remainingCount);
    setBonusRefreshDateTime(item.info.refreshDateTime);
    setGameCategory(String(item.info.gameCategory).replaceAll(",", ", "));
  }

  const togglePaymentMethod = (paymentMethodId) => {
    if (paymentMethodActive === paymentMethodId) {
      setPaymentMethodActive(0);
    }
    else {
      setPaymentMethodActive(paymentMethodId);
    }
  }


  async function submitFullName(data) {
    let params = {
      "userFullName": data.userFullName,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
      setShowFullnamePopup(false);
    }
    else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  //render
  return (
    <>
      <Header
        history={_history}
        url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_MAIN}
        title={t("RELOAD_METHOD")}
        hasBackButton={
          _history.location.state?.from == '/home' ||
            _history.location.state?.from == '/mainWallet' ||
            _history.location.state?.from == '/deposit-record' ||
            _history.location.state?.from == '/depositTutorial' ||
            _history.location.state?.from == '/' ? true : false

        }
        hasRightMenu={true}
        rightMenuList={rightMenuList}
        state={{ from: window.location.pathname }}
      />
      {hasPendingTxn && (
        <div className="alert alert-warning">
          {timeDiffSeconds > 0
            ? t("PLEASE_WAIT") +
            " " +
            countdownStr +
            " " +
            (t("FOR_DEPOSIT_APPROVAL"))
            : headerMsg}
        </div>
      )}
      <h4>{t("SELECT_A_PACKAGE")}</h4>
      <p className="color-gray font11 mb-0">
        {t("SELECT_A_PACKAGE_OPTION")}
      </p>
      <Slider {...bonusSlick} className="bonus-slick">
        {bonusDepositList &&
          bonusDepositList.length &&
          map(bonusDepositList, (item, index) => {
            return (
              <div className="bonus-items" key={index} onClick={(e) => { updateBonusPackage(item); }}>
                <div
                  className={
                    "bonus-img " +
                    classnames({
                      active:
                        bonusDeposit ===
                        item.bonusId,
                    })
                  }
                >
                  <img
                    src={item.info.image}
                    alt='bonus'
                    className={
                      "img-100 " +
                      classnames({
                        active:
                          bonusDeposit ===
                          item.bonusId,
                      })
                    }
                  />
                  <img
                    src={InfoIcon}
                    className="info-icon"
                    onClick={(e) => { showBonus(item); }}
                    alt='info-icon'
                  />
                  {bonusDeposit == item.bonusId ? <div className="normal-bank-tick-selected">
                    <img src={require("../../assets/img/v3/reload/tick.png")} alt="active" />
                  </div> : <></>}
                </div>
              </div>
            );
          })}
      </Slider>
      {bonusDeposit !== "" && (
        <div className="payment-container">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h4 className="mt-3 mb-3">{t("SELECT_A_PAYMENT")}</h4>
            </div>
            <div onClick={() => _history.push({ pathname: WebUrl._URL_DEPOSIT_TUTORIAL, state: { paymentMethodActive: paymentMethodActive } })}>
              <h4 style={{ fontSize: '12px', textDecoration: 'underline', color: 'rgba(51, 105, 255, 1)', cursor: "pointer" }} className="mt-3 mb-3">{t("HOW_TO_DEPOSIT")}</h4>
            </div>
          </div>
          <div className="provider-container">
            <div key={_NORMAL_DEPOSIT} className="payment-list-item">
              <label className="payment-custom-radio">
                <div className="payment-custom-radio-label">
                  <div>
                    <input type="radio" className="payment-custom-radio-input" name="paymentMethod" readOnly checked={paymentMethodActive === _NORMAL_DEPOSIT} onClick={() => togglePaymentMethod(_NORMAL_DEPOSIT)} />
                    <div className="checkmark-border">
                      <div className="checkmark-dot"></div>
                    </div>
                    <div className="checkmark-title">
                      {t("NORMAL_DEPOSIT")}
                    </div>
                  </div>
                </div>
                <div>
                  {_NORMAL_DEPOSIT === paymentMethodActive ?
                    (
                      <img src={require("../../assets/img/v3/arrow-up-blue.png")} alt="up" />
                    )
                    :
                    (
                      <img src={require("../../assets/img/v3/arrow-down.png")} alt="down" />
                    )
                  }
                </div>
              </label>


              {(paymentMethodActive === _NORMAL_DEPOSIT) && (
                <hr className="custom-hr" />
              )}
              {paymentMethodActive === _NORMAL_DEPOSIT && (
                <NormalDeposit
                  parentInit={init}
                  bonusDeposit={bonusDeposit}
                  checkPendingTxn={checkPendingTxn}
                />
              )}

            </div>
          </div>
        </div>
      )}

      <Modal
        id="custom-modal"
        className="bonus-modal"
        contentClassName="modal-brand modal-bottom"
        fade={true}
        isOpen={bonusModalVisible}
        toggle={() => {
          setBonusModalVisible(false);
        }}
        centered
      >
        <ModalBody>
          <div className="deposit-bonus-modal-container">
            <img src={bonusDepositPopImage} alt="bonus" className="img-100" loading="lazy" />
            <div className="deposit-bonus-modal-content">
              <div className="deposit-bonus-modal-title-box">
                <div className="first">
                  <img src={require("../../assets/img/v3/money-icon.png")} alt="bonus" className="img-responsive" />
                </div>
                <div className="second font20">
                  {bonusDepositName}
                </div>
              </div>
              <div className="deposit-bonus-modal-details mt-4">
                <p className="mb-1">{t("MINIMUM_DEPOSIT") + ": A$ " + numberWithCurrencyFormat2(bonusDepositMinDeposit, 2, true)}</p>
                <ul className="mb-1">
                  {<li> {t("BONUS") + " (%) : " + bonusDepositBonus}</li>}
                  {<li> {t("ROLLOVER") + " : x" + bonusDepositRollover}</li>}
                  {<li> {t("MAX_BONUS") + " : A$ " + numberWithCurrencyFormat2(bonusDepositMaxBonus, 2, true)}</li>}
                  {/*{<li> {t("ELIGIBLE_FOR_FREE_SPIN") + " : " + (bonusDepositFreeSpinStatus ? t("YES") : t("NO"))}</li>}*/}
                  {bonusDepositMaxLimit !== 0 && <li> {t("CLAIM_LIMIT") + " : " + bonusDepositMaxLimit + " " + t("TIMES") + (bonusRemainingCount > 0 ? " (" + bonusRemainingCount + " " + t("LEFT") + ")" : "")} </li>}
                  {bonusDepositMaxLimit !== 0 && bonusDepositDayLimit !== 0 &&
                    <>
                      {bonusDepositDayLimit === 1 ?
                        <span className="claim-limit-reminder">{"*" + t("LIMIT_REFRESHES_EVERY_DAY", { value: bonusDepositMaxLimit })}</span>
                        :
                        <span className="claim-limit-reminder">{"*" + t("LIMIT_REFRESHES_EVERY_FEW_DAYS", { value: bonusDepositDayLimit })}</span>
                      }
                    </>
                  }
                  {<li> {t("GAME_CATEGORY") + " : " + gameCategory}</li>}
                  <br />
                  {bonusDepositMaxLimit !== 0 && bonusDepositDayLimit !== 0 && bonusRefreshDateTime !== "" && <span className="claim-limit-reminder">{bonusRefreshDateTime !== "" && "*" + t("REFRESHES_ON") + " " + bonusRefreshDateTime}</span>}
                </ul>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {
        //CREATE NEW USER FULL NAME
      }
      <Modal
        id="custom-modal"
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={showFullnamePopup}
        centered
      >
        <ModalBody>
          <form onSubmit={handleSubmitFullname(submitFullName)}>
            <div className="display-wrapper enter-fullname-modal">
              <strong className="reload-title text-yellow d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_FULL_NAME")}</strong>
              <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{t("IN_YOUR_BANK_ACCOUNT")}</strong>
              <input
                type="text"
                name="userFullName"
                className="withdrawal-fullName-input"
                ref={registerFullname({
                  required: 'FIELD_REQUIRED',
                  pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" }
                })}
                onChange={(e) => setValueFullname('userFullName', e.target.value)}
              />
              {errorsFullname.userFullName && <div className="invalid-feedback">{t(errorsFullname.userFullName.message)}</div>}
              <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "200px" }}>{t("SUBMIT")}</button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );

}

export default Deposit;