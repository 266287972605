import React, { useState, useEffect, useContext } from "react";
import ApiEngine from "../../util/ApiEngine";
import { useTranslation } from "react-i18next";
import {
  ApiKey, ApiUrl, WebUrl, SessionKey,
  LanguageOption,
} from "../../util/Constant";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Header from "../../components/custom/Header";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import parse from 'react-html-parser';
import { size } from "lodash";

/// <summary>
/// Author : 
/// </summary>
const DepositTutorial = (props) => {
  const _location = useLocation();
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  let _userData = useSelector((state) => state.authState.userData);
  let _history = useHistory();
  const [depositTutorialData, setDepositTutorialData] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [apiData, setApiData] = useState([]);
  const META_COUNT = 10;
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  const { handleSubmit, register, control, watch, setValue, errors, reset } = useForm();
  const [defaultPaymentMethodActive, setDefaultPaymentMethodActive] = useState(null);

  useEffect(() => {

    getList();
  }, []);


  async function getList() {
    let reponseJ = await ApiEngine.get(ApiUrl._API_GET_DEPOSIT_TUTORIAL_CONTENT);

    const methodList = [];
    if (reponseJ[ApiKey._API_SUCCESS_KEY]) {
      reponseJ[ApiKey._API_DATA_KEY].forEach(function (method) {
        if(method.status){
          if (method.paymentMethod == 1) {
            methodList.push({
              label: t("INSTANT_PAYMENT"),
              value: method.paymentMethod,
            });
          } else if (method.paymentMethod == 2) {
            methodList.push({
              label: t("QR_PAYMENT"),
              value: method.paymentMethod,
            });
          } else {
            methodList.push({
              label: t("NORMAL_DEPOSIT"),
              value: method.paymentMethod,
            });
          }
        }
      })
    }
    if (methodList.length > 0) {
      const paymentMethodActiveState = _location.state.paymentMethodActive;
      const value = paymentMethodActiveState == 0 ? methodList[0].value : paymentMethodActiveState;
      
      // if in BO disable certain deposit tutorial and cannot found, will get back the list to show
      const paymentMethodListArr = methodList.filter(x => x.value == value);

      if(size(paymentMethodListArr) === 0) {
        setDefaultPaymentMethodActive(methodList[0].value);
        init(methodList[0].value);
      }
      else {
        setDefaultPaymentMethodActive(value);
        init(value);
      } 

      setPaymentMethodList(methodList);
    }
  }

  async function init(paymentMethod) {

    var id = paymentMethod;

    var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_DEPOSIT_TUTORIAL_CONTENT_LANGUAGE + "?LanguageId=" + language + "&depositTutorialId=" + id);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var data = responseJson[ApiKey._API_DATA_KEY][0];
      //var newData = [];
      //for (var i = 0; i < META_COUNT; i++) {
      //  newData[i] = {
      //    name: (i + 1) + ".",
      //    key: data["parameter_" + (i + 1)],
      //    value: data["parameter_" + (i + 1)],
      //  };
      //}
      /* setApiData([]);*/
      //setApiData(newData);
      if(data != undefined){
        var content = JSON.parse(data["content"]);
        var contentLength = Object.keys(content).length;

        var newData = [];
        for (var i = 0; i < contentLength; i++) {
          newData[i] = {
            name: (i + 1) + ".",
            key: content["parameter_" + (i + 1)],
            value: content["parameter_" + (i + 1)],
          };
        }

        setApiData([]);
        setApiData(newData);
      }
      else {
        setApiData([]);
      }
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_DEPOSIT}
        title={t("HOW_TO_DEPOSIT")}
        hasBackButton={true}
        state={{ from: window.location.pathname }}
      />
      <div className="deposit-tutorial-container" style={{ "marginBottom": "10px" }}>
        <div className="form-group m-b-15">
          <div>
            <Controller
              control={control}
              name="paymentMethodId"
              render={({ onChange, value }) => {
                return (
                  <StyledSelect
                    options={paymentMethodList}
                    placeholder={paymentMethodList[0] !== undefined ? 
                      t(paymentMethodList[0].label) : 
                      paymentMethodList.filter(option => option.value == value).label}
                    value={value === undefined && paymentMethodList[0] !== undefined ? 
                      paymentMethodList.filter(x => x.value == defaultPaymentMethodActive)[0].value :
                       value}
                    onChange={(e) => {
                      onChange(e.value)
                      init(e.value);
                    }} />
                )
              }} />
          </div>
        </div>
        <div className="tutorial-content-wrapper">
          {Object.keys(apiData).map((key, value) => {
            if (!stringIsNullOrEmpty(apiData[key].value)) {
              return (
                <div key={key} className="tutorial-row">
                  <div>{parse(apiData[key].value)}</div>
                </div>
              )
            }
          })}
        </div>
      </div>

    </>
  );

}

export default DepositTutorial;