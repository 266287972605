import { _IDLE, _BUSY, _ALERT_TYPES, _API_CALLED, _DOWNLINE_SEARCH, _DOWNLINE_QUERY, _SET_Y, _SET_PAGESIZE, _SET_ACTIVE_TAB, _IS_KEEPED_LOGIN, _GAME_SEARCH, _GAME_QUERY, _BIND_MEMBER } from './AppAction';
import { stringIsNullOrEmpty } from '../util/Util';
import i18n from "../localization/i18n";
import { AlertTypes } from '../util/Constant';

const _INITIAL_STATE = {
    isBusy: false,
    isKeepedLogin: false,
    message: i18n.t("PLEASE_WAIT"),
    alert: {
    },
    iconEnabled: true,
    apiCalledResponse: {},
    savedText: "",
    gameSavedText: "",
    savedQuery: "/Member/GetUserMemberList?directUserOnly=true",
    gameQuery: "/Game/GetMasterProductGroupedGame",
    y: 0,
    pageSize: 10,
    activeTab: 0,
    walletBalance: 0,
    member: [],
};

var _busyCount = 0;

/// <summary>
/// Author : 
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _BUSY:
            _busyCount++;
            return {
                ...state,
                isBusy: true,
                message: stringIsNullOrEmpty(action.message) ? i18n.t("PLEASE_WAIT") : action.message,
                iconEnabled: action.iconEnabled == null ? _INITIAL_STATE.iconEnabled : action.iconEnabled,
            }
            break;
        case _IDLE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                isBusy: (_busyCount != 0),
            }
            break;
        case _ALERT_TYPES.SET_ALERT:
            state.alert.custom = true;
            state.alert.customIcon = action.alert.type == AlertTypes._INFO ? require("../assets/img/sweetalert_info.png") : action.alert.type == AlertTypes._SUCCESS ? require("../assets/img/sweetalert_success.png") : require("../assets/img/sweetalert_error.png");
            state.alert.content = action.alert.content;
            state.alert.onConfirm = action.alert.onConfirm;
            state.alert.onCancel = action.alert.onCancel;
            state.alert.cancelBtnBsStyle = action.alert.cancelBtnBsStyle;
            state.alert.title = action.alert.title;
            return {
                ...state,
                alert: Object.keys(state.alert) == 0 ? action.alert : state.alert
            };
            break;
        case _ALERT_TYPES.NONE:
            return {
                ...state,
                alert: {}
            };
            break;
        case _API_CALLED:
            return {
                ...state,
                apiCalledResponse: action.data
            };
            break;
        case _DOWNLINE_SEARCH:
            return {
                ...state,
                savedText: action.data
            };
            break;
        case _DOWNLINE_QUERY:
            return {
                ...state,
                savedQuery: action.data
            };
            break;
        case _GAME_SEARCH:
            return {
                ...state,
                gameSavedText: action.data
            };
            break;
        case _GAME_QUERY:
            return {
                ...state,
                gameQuery: action.data
            };
            break;
        case _SET_Y:
            return {
                ...state,
                y: action.y,
            };
        case _SET_PAGESIZE:
            return {
                ...state,
                pageSize: action.data,
            };
            break;
        case _SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.data,
            };
            break;
        case _IS_KEEPED_LOGIN:
            return {
                ...state,
                isKeepedLogin: action.data,
            };
            break;
        case _BIND_MEMBER:
            return {
                ...state,
                member: action.member,
                walletBalance: action.walletBalance,
            };
            break;
        default:
            return state;
            break;
    }
};