import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { WebUrl } from "../../util/Constant";

/// <summary>
/// Author: -
/// </summary>
const TransactionHistory = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();

  const _MENU_OPTION = [
    {
      name: "DEPOSIT_RECORD",
      imageUrl: require("../../assets/img/v3/reports_deposit-record.svg"),
      redirectUrl: WebUrl._URL_DEPOSIT_RECORD,
    },
    {
      name: "WITHDRAWAL_RECORD",
      imageUrl: require("../../assets/img/v3/reports_withdrawal-record.svg"),
      redirectUrl: WebUrl._URL_WITHDRAWAL_RECORD,
    },
    {
      name: "WALLET_TRANSFER",
      imageUrl: require("../../assets/img/v3/reports_wallet-transfer.svg"),
      redirectUrl: WebUrl._URL_WALLET_TRANSFER_HISTORY,
    },
    {
      name: "INTERNAL_TRANSFER",
      imageUrl: require("../../assets/img/v3/reports_internal-transfer.svg"),
      redirectUrl: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
    },
  ];

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN_WALLET}
        title={t("REPORTS")}
      />
      <div className="container mt-3">
        <div className="report-menu">
          {_MENU_OPTION.map((menu, index) => {

            return (
              <div key={index} className="card-panel m-b-10">
                <Link to={{
                  pathname: menu["redirectUrl"],
                  state: {
                    url: window.location.pathname,
                  }
                }}>
                  <div className="report-menu-wrapper">
                    <div>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          style={{ objectFit: "contain" }}
                          src={menu["imageUrl"]}
                        />
                        <div>{t(menu["name"])}</div>
                      </div>
                    </div>
                    <div><i className="fas fa-chevron-right"></i></div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;