import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl, TransactionType, BankingChannel, PanelType, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody, createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import ReactHtmlParser from "react-html-parser";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import Numpad from "../../components/custom/Numpad";
import PinInput from 'react-pin-input';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Alert, Modal, ModalBody } from 'reactstrap';
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author: -
/// </summary>
const InstantTransfer = props => {
    const { t, i18n } = useTranslation();
    let _userData = useSelector(state => state.authState.userData);
    let _dispatch = useDispatch();
    const { handleSubmit, register, control, watch, setValue, errors, reset } = useForm();
    const {
        handleSubmit: handleFullNameSubmit,
        register: registerFullName,
        getValues: getValuesFullName,
        errors: errorsFullName,
        setValue: setValueFullName
    } = useForm();
    const [userOption, setUserOption] = useState([]);
    const [currentInput, setCurrentInput] = useState();
    const [selectedMember, setSelectedMember] = useState();
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [pinEntered, setPinEntered] = useState(false);
    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
    const [submitData, setSubmitData] = useState({});
    const [submitEvent, setSubmitEvent] = useState({});
    const [forgetPin, setForgetPin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [showFullnamePopup, setShowFullnamePopup] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [createPinEntry, setCreatePinEntry] = useState(false);
    const [member, setMember] = useState("");
    const [internalTransferLimit, setInternalTransferLimit] = useState(0);
    const [internalTransferLimitBalance, setInternalTransferLimitBalance] = useState(0);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const _COUNTDOWN_PERIOD = 120;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 3000;

    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);

    const keyboard = useRef();

    const _SELECT_STYLES = {
        menu: (styles, state) => ({
            ...styles,
        }),
        control: (styles, state) => ({
            ...styles,
            borderRadius: '50px',
            boxShadow: '0px 3px 17px 0px rgba(0,0,0,.1)',
            height: '35px',
            fontSize: '14px'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles
            };
        },
        singleValue: styles => ({
            ...styles,
            paddingLeft: '1rem',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#002e6c'
        }),
        placeholder: styles => ({
            ...styles,
            paddingLeft: '1rem',
            fontSize: '14px',
            color: '#000000'
        })
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, [])

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        try {
            let tempMemberData = {};
            var memberJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);

          if (memberJson[ApiKey._API_SUCCESS_KEY]) {
                setMember(memberJson[ApiKey._API_DATA_KEY]);
                setInternalTransferLimit(memberJson[ApiKey._API_DATA_KEY].internalTransferLimit)
                tempMemberData = memberJson[ApiKey._API_DATA_KEY];
                //setphoneNumber(memberJson[ApiKey._API_DATA_KEY]['phoneNumber']);
            }
            else {
                throw memberJson[ApiKey._API_MESSAGE_KEY];
            }

            var limitJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE);
            if (limitJson[ApiKey._API_SUCCESS_KEY]){
              let limitData = limitJson[ApiKey._API_DATA_KEY];
                setInternalTransferLimitBalance(limitData["memberInternalTransferLimitBalance"]);
            }

            if (stringIsNullOrEmpty(tempMemberData['userFullName'])) {
                setShowFullnamePopup(true);
            }
            else {
                let params = {};
                let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
                if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                    var theInterval = setInterval(() => {
                        if (elePin['current'] == undefined)
                            return;

                        if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                            elePin['current']['elements'][0].focus();
                        }
                        else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                            elePin['current']['elements'][1].focus();
                        }
                        else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                            elePin['current']['elements'][2].focus();
                        }
                        else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                            elePin['current']['elements'][3].focus();
                        } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                            eleConfirm['current']['elements'][0].focus();
                        }
                        else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                            eleConfirm['current']['elements'][1].focus();
                        }
                        else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                            eleConfirm['current']['elements'][2].focus();
                        }
                        else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                            eleConfirm['current']['elements'][3].focus();
                        }
                        else {

                            var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                            var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                            if (currpin != currconfpin) {
                                setInvalidPin(true);
                                elePin['current'].clear();
                                eleConfirm['current'].clear();
                                elePin['current'].focus();
                            }
                            else {
                                setInvalidPin(false);
                                clearInterval(theInterval);
                                submitPin(currpin, currconfpin);
                            }
                        }

                    }, 50);
                    setTheInterval(theInterval);
                    setCreatePinEntry(true);
                }
            }
            _dispatch(checkIsLoggedIn());
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author: 
    /// </summary>
    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;
        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            if (key === "{enter}") {
                if (pinEntered) {
                    setShowPinEntry(false);
                }
            }
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                eleFinalPin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    eleFinalPin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberFinal(pinStr);
                    setPinEntered(true);
                }
            }
        }
        else {
            eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            eleFinalPin['current']['elements'][currentIndex].focus();
        }
    }

    function onCreatePinKeyPress(key) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (key !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                elePin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumber(pinStr);
                }
            }
            else {
                currentIndex = pinNumberConfirmFocused;
                eleConfirm['current']['elements'][currentIndex].state.value = key;
                pinConfirmStr += key;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex <= 3) {
                    eleConfirm['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberConfirm(pinConfirmStr);
                    submitPin(pinNumber, pinConfirmStr);
                }
            }
        }
        else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin['current']['elements'][currentIndex].focus();
        }
    }

    async function submitPin(pin, conf) {
        try {
            let params = {
                "pinNumber": pin,
                "confirmPinNumber": conf
            };
            let responseJson = await ApiEngine.post(ApiUrl._API_SET_MEMBER_PIN_NUMBER, createFormBody(params));
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setCreatePinEntry(false);
            }
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(0);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(0);
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    async function sendPinCode() {
        if (countdownPeriod < 1) {
            let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_PHONE_VERIFIED);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    "phoneNumber": hpNum,
                    "isShareholder": "false"
                };
                let smsJson = await ApiEngine.post(ApiUrl._API_SEND_PIN_CODE, createFormBody(params));
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setCountdownPeriod(_COUNTDOWN_PERIOD);
                }
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT])
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function onSubmit(data, e) {
        if (!pinEntered) {
            setShowPinEntry(true);
        }
        else {
            setShowPinEntry(false);
        }
        if (pinEntered && pinNumberFinal !== '') {
            submitTransfer(data, e, pinNumberFinal);
            resetEnteredPin();
        }
    }

    async function submitTransfer(data, e, pin) {
        try {
            _dispatch(setBusy());

            let params = {
                "transactionTypeId": TransactionType._TRANSFER,
                "pinNumber": pin,
                "amount": data.amount,
                "targetMember": selectedMember.value,
                "channel": "",
                "panel": PanelType._MEMBERSITE,
            };

            let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_TRANSACTION, createMultiPartFormBody(params));

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(checkIsLoggedIn());
                e.target.reset();
                setSelectedMember(null);
                setValue("userId", "");
                setShowPinEntry(false);

                var limitJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_INTERNAL_TRANSFER_LIMIT_BALANCE);
                if (limitJson[ApiKey._API_SUCCESS_KEY]) {
                    let limitData = limitJson[ApiKey._API_DATA_KEY];
                    setInternalTransferLimitBalance(limitData["memberInternalTransferLimitBalance"]);
                }
            }
            if (responseJson[ApiKey._API_MESSAGE_KEY] === "TRANSFER_LIMIT_ERROR"){
                _dispatch(showResponseMessage(false, t("TRANSFER_LIMIT_EXCEEDED", {value: member["internalTransferLimit"]})));
            }
            else {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
        _dispatch(setIdle());

    }
    function resetEnteredPin() {
        setPinNumberFinal("");
        setPinNumberFocused(0);
        setPinNumberFinalFocused(0);
        setEnteredPinNumberFinal("");
        setPinEntered(false);
    }



    async function submitFullName(data) {
        let params = {
            "userFullName": data.userFullName,
        };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
      createFormBody(params)
    );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            setShowFullnamePopup(false);
            let params = {};
            let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
            if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                var theInterval = setInterval(() => {
                    if (elePin['current'] == undefined)
                        return;

                    if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                        elePin['current']['elements'][0].focus();
                    }
                    else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                        elePin['current']['elements'][1].focus();
                    }
                    else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                        elePin['current']['elements'][2].focus();
                    }
                    else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                        elePin['current']['elements'][3].focus();
                    } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                        eleConfirm['current']['elements'][0].focus();
                    }
                    else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                        eleConfirm['current']['elements'][1].focus();
                    }
                    else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                        eleConfirm['current']['elements'][2].focus();
                    }
                    else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                        eleConfirm['current']['elements'][3].focus();
                    }
                    else {

                        var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                        var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                        if (currpin != currconfpin) {
                            setInvalidPin(true);
                            elePin['current'].clear();
                            eleConfirm['current'].clear();
                            elePin['current'].focus();
                        }
                        else {
                            setInvalidPin(false);
                            clearInterval(theInterval);
                            submitPin(currpin, currconfpin);
                        }
                    }

                }, 50);
                setTheInterval(theInterval);
                setCreatePinEntry(true);
            }
      }
        else {
          _dispatch(
            showResponseMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              responseJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
        init();
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY].data;
          var formattedData;
          //if (data.length > 1) 
          {
            formattedData = data.filter(x => x.id !== member.id).map((m) => { return { label: m.username, value: m.id } });
          }
          //else {
          //  formattedData = data.map((m) => { return { label: m.username, value: m.id } });
          //}

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1
                }
            };
        }
    }

    return (
        <div id={_userData.selectedTheme === 0 ? "internal-transfer" : "internal-transfer-modern"} className="row internal-transfer">
            <div className="col-xs-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group m-b-15">
                        <label className="col-form-label"><b>{t("MEMBER")}</b></label>
                        <div>
                            <Controller
                                className="internal-transfer-dropdown"
                                control={control}
                                name="userId"
                                render={({ onChange, value }) => {
                                    return (
                                        <AsyncPaginate
                                            styles={_SELECT_STYLES}
                                            placeholder={t("PLEASE_SELECT_MEMBER")}
                                            debounceTimeout={250}
                                            isDisabled={!stringIsNullOrEmpty(props.id)}
                                            loadOptions={getMemberList}
                                            additional={{
                                                page: 1,
                                            }}
                                            value={selectedMember}
                                            onChange={(e) => { onChange(e.value); setSelectedMember(e) }}
                                        />
                                    )
                                }}
                                rules={{ required: "PLEASE_SELECT_MEMBER" }} />
                            {errors.userId && <div className="invalid-feedback">{t(errors.userId.message)}</div>}
                        </div>
                        {internalTransferLimit !== 0 ?  
                            <div className="mt-1 mr-3 float-right">
                                <span style={{ color: "#ffc159", fontSize: "10px"}}>{t("AVAILABLE_TRANSFER_LIMIT")} :  { numberWithCurrencyFormat(internalTransferLimitBalance, 2) + "/" + numberWithCurrencyFormat(internalTransferLimit, 2)}  </span>
                            </div>
                          :
                            ""
                        }
                    </div>
                    <div className="form-group m-b-15">
                        <label className="col-form-label"><b>{t("AMOUNT")}</b></label>
                        <div>
                            <input type="number" step="0.01" className="form-control bg-white" readOnly placeholder={t("PLEASE_ENTER_AMOUNT")}
                                name="amount"
                                style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c" }}
                                onClick={(e) => { setCurrentInput(e.target) }}
                                ref={register({
                                    required: "PLEASE_ENTER_AMOUNT",
                                    min: {
                                        value: 1,
                                        message: ReactHtmlParser(t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_VARIABLE", { amount: 1 }))
                                    },
                                    max: {
                                        value: _userData.walletBalance,
                                        message: ReactHtmlParser(t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_VARIABLE", { amount: _userData.walletBalance }))
                                    },
                                })}
                            />
                            {errors.amount && <div className="invalid-feedback">{t(errors.amount.message)}</div>}
                        </div>
                    </div>
                    <div style={{ margin: "1rem 0px" }}>
                        <button type="button" onClick={() => { if (!pinEntered) { setShowPinEntry(true); } }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT")}</button>
                    </div>
                    {showPinEntry &&
                        <div>
                            <div className="pin-wrapper">
                            </div>
                            <div className="pin-number">
                                <button type="button" style={{
                                    alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", float: "right", fontWeight: "bold"
                                }} onClick={() => {
                                    clearInterval(theInterval); resetEnteredPin(); setShowPinEntry(false);
                                }} data-dismiss="modal" aria-hidden="true">&times;</button>
                                <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px", marginLeft: "40px" }}>{t("ENTER_YOUR_PIN")}</strong>
                                <PinInput style={{ marginBottom: "35px" }}
                                    ref={eleFinalPin}
                                    length={4}
                                    initialValue=""
                                    secret={true}
                                    focus={true}
                                    autoSelect={true}
                                    disabled={showPinEntry}
                                    onChange={(value, index) => {
                                        setPinNumberFinalFocused(index);
                                    }}
                                    type="numeric"
                                    inputMode="number"
                                    inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                    inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                />
                                {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

                                <strong className="text-brand display-value" onClick={() => { setForgetPin(true); sendPinCode(); }}>{t("RESET_PIN")}</strong>
                                <Keyboard
                                    layout={{
                                        'default': [
                                            "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                        ]
                                    }}
                                    display={{
                                        '{bksp}': "<i class='fas fa-backspace'></i>",
                                    }}
                                    theme={`keyboard keyboard-numpad`}
                                    keyboardRef={r => (keyboard.current = r)}
                                    onKeyPress={onVerifyPinKeyPress}
                                    disableButtonHold
                                    disableCaretPositioning
                                />
                                {forgetPin && <p className="text-brand" style={{ textAlign: "center", marginTop: "5px" }}>{t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod} {t("SECOND")}</p>}
                                {showAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", left: "0", width: "100%" }}>
                                    <div className="container">
                                        <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                                    </div>
                                </div>}
                                <button type="submit" style={{ position: "relative", marginTop: "30px", width: "250px" }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT_TRANSACTION")}</button>
                            </div>
                        </div>
                    }
                </form>
            </div>
            {
                //CREATE NEW PIN
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={createPinEntry} centered>
                <ModalBody>
                    <form>
                        <div className="display-wrapper">
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_PIN")}</strong>
                            <strong className="reload-title mb-3 d-block" style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c" }}>{t("ENTER_YOUR_PIN")}</strong>
                            <PinInput
                                ref={elePin}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <strong className="reload-title mb-3 d-block" style={{ fontSize: "14px", fontWeight: "bold", color: "#002e6c", marginTop: "30px" }}>{t("CONFIRM_YOUR_PIN")}</strong>
                            <PinInput
                                ref={eleConfirm}
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                disabled={createPinEntry}
                                type="numeric"
                                onChange={(value, index) => {
                                    setPinNumberConfirmFocused(index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            <div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>
                            <Keyboard
                                layout={{
                                    'default': [
                                        "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                    ]
                                }}
                                display={{
                                    '{bksp}': "<i class='fas fa-backspace'></i>",
                                }}
                                theme={`keyboard keyboard-numpad`}
                                keyboardRef={r => (keyboard.current = r)}
                                onKeyPress={onCreatePinKeyPress}
                                disableButtonHold
                                disableCaretPositioning
                            />
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {
                //CREATE NEW USER FULL NAME
            }
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showFullnamePopup} centered>
                <ModalBody>
                    <form onSubmit={handleFullNameSubmit(submitFullName)}>
                        <div className="display-wrapper">
                            <strong className="reload-title text-yellow d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_FULL_NAME")}</strong>
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{t("IN_YOUR_BANK_ACCOUNT")}</strong>
                            <input
                                type="text"
                                name="userFullName"
                                className="withdrawal-fullName-input"
                                ref={registerFullName({
                                    required: 'FIELD_REQUIRED',
                                    pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" }
                                })}
                                onChange={(e) => setValueFullName('userFullName', e.target.value)}
                            />
                            {errorsFullName.userFullName && <div className="invalid-feedback">{t(errorsFullName.userFullName.message)}</div>}
                            <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "200px" }}>{t("SUBMIT")}</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            <Numpad currentBalance={_userData.walletBalance} currentInput={currentInput} setCurrentInput={(e) => { setCurrentInput(e) }} />
        </div>
    );
}

export default InstantTransfer;
