import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DateTime from "react-datetime";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  TransactionType,
  TransactionStatus,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

const BonusHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const { handleSubmit, register, control, watch, setValue, errors, reset } =
    useForm();
  const [products, setProducts] = useState([]);
  const [bonusUrl, setBonusUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [bonusData, setBonusData] = useState([]);
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    getBonusData(startDate, endDate);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`
    );

    const productList = [
      {
        label: t("ALL"),
        value: null,
      },
    ];

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].products.forEach(function (product) {
        productList.push({
          label: product.productName,
          value: product.productId,
        });
      });
    }

    setProducts(productList);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBonusData(startDate, endDate, selected = "") {
    // var apiUrl =
    //   ApiUrl._API_GET_BONUS_HISTORY +
    //   "?StartDate=" +
    //   moment(startDate).format("YYYY-MM-DD") +
    //   "&EndDate=" +
    //   moment(endDate).format("YYYY-MM-DD") +
    //   "&v=" +
    //   Date.now();

    var apiUrl =
      ApiUrl._API_GET_BONUS_HISTORY +
      "?StartDate=" +
      // moment(startDate).format("YYYY-MM-DD HH:mm:ss") +
      moment(startDate).startOf('day').format("YYYY-MM-DD") +
      "&EndDate=" +
      // moment(endDate).format("YYYY-MM-DD HH:mm:ss") +
      moment(endDate).endOf('day').format("YYYY-MM-DD") +
      "&v=" + moment(startDate).startOf('day').format("YYYY-MM-DD");

    if (!stringIsNullOrEmpty(queryProduct)) {
      apiUrl += "&ProductId=" + queryProduct;
    }

    if (!stringIsNullOrEmpty(selected)) {
      // apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }

    setValue(
      "productId",
      stringIsNullOrEmpty(queryProduct) ? null : queryProduct
    );
    setBonusUrl(apiUrl);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getBonusData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getBonusData(startDate, endDate);
  }, [searchTrigger]);

  /// <summary>
  /// Author : -
  /// </summary>
  function getRecordFromDynamicLoad(data) {
    setBonusData(data);
  }

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
        title={t("BONUS_STATEMENT")}
      />
      <div
        id={activeTheme === 0 ? "bonus-history" : "bonus-history-modern"}
        className="bonus-history-container"
        style={{ marginBottom: "10px" }}
      >
        <DateRangeFilter2
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSave={(start, end) => {
            setQueryProduct(watch()["productId"]);
            setSelectedDate("");
            setSearchTrigger(Date.now());
          }}
          options={true}
        />
        <div className="hr-right mb-3 mt-3"></div>
        <div className="form-group">
          <div className="custom-select-box">
            <Controller
              control={control}
              name="productId"
              render={({ onChange, value }) => {
                return (
                  <StyledSelect
                    options={products}
                    value={value}
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="hr-left mb-3 mt-3"></div>
        <div className="card card-package">
          <div className="card-body p-0">
            <div className="card-table table-responsive">
              <table className="table table-kpi-summary ">
                <thead>
                  <tr>
                    <th>{t("DATE")}</th>
                    <th>{t("USERNAME")}</th>
                    <th>{t("GAME")}</th>
                    <th>{t("AMOUNT")}</th>
                  </tr>
                </thead>
                <tbody>
                  <DynamicLoadTable
                    returnRecord={getRecordFromDynamicLoad}
                    pageSize={20}
                    apiQuery={bonusUrl}
                    isTable={true}
                    searchEnabled={true}
                    render={(item, i) => {
                      let isGrouped =
                        moment(endDate, "YYYY-MM-DD").diff(
                          moment(startDate, "YYYY-MM-DD"),
                          "days"
                        ) >= 1 && stringIsNullOrEmpty(selectedDate);
                      return (
                        <tr key={i}>
                          <td
                            className={isGrouped ? "hoverable" : ""}
                            onClick={() => {
                              if (isGrouped) {
                                // setSelectedDate(item["parsedTime"]);
                                setSelectedDate(moment(item["parsedTime"], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                              }
                            }}
                          >
                            {item["parsedTime"]}
                          </td>
                          <td>{item["username"]}</td>
                          <td>{item["remark"]}</td>
                          <td>
                            {numberWithCurrencyFormat(
                              parseFloat(item["amount"]),
                              2
                            )}
                          </td>
                        </tr>
                      );
                    }}
                  />
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <b>{t("TOTAL")}</b>
                    </td>
                    <td>
                      <b>
                        {numberWithCurrencyFormat(
                          parseFloat(
                            bonusData.reduce(
                              (total, data) => total + data.amount,
                              0
                            )
                          ),
                          2
                        )}
                      </b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BonusHistory;
