import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { WebUrl } from "../../util/Constant";
import { useSelector } from 'react-redux';

const RewardsTnC = (props) => {
  let _history = useHistory();
  const { t } = useTranslation();
  const activeTheme = useSelector(state => state.authState.userData.selectedTheme);

  return (
    <>
      <Header history={_history} url={WebUrl._URL_REWARDS} title={t('TNC')} />
      <div className={"tnc-container" + (activeTheme === 0 ? " classic" : " modern")} style={{ paddingTop: "0" }}>
        <div className="tnc-content">
          <div className="tnc-content-body">
            <div id="tnc-rewards">
              <div className="tnc-list"> 1.<div className="col-12">{t("REWARDS_1")}</div></div>
              <div className="tnc-list"> 2.<div className="col-12">{t("REWARDS_2")}</div></div>
              <div className="tnc-list"> 3.<div className="col-12">{t("REWARDS_3")}</div></div>
              <div className="tnc-list"> 4.<div className="col-12">{t("REWARDS_4")}</div></div>
              <div className="tnc-list"> 5.<div className="col-12">{t("REWARDS_5")}</div></div>
              <div className="tnc-list"> 6.<div className="col-12">{t("REWARDS_6")}</div></div>
              <div className="tnc-list"> 7.<div className="col-12">{t("REWARDS_7")}</div></div>
              <div className="tnc-list"> 8.<div className="col-12">{t("REWARDS_8")}</div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RewardsTnC;