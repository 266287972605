import React, { useState, useEffect } from "react";
import Header from "../../components/custom/Header";
import { useHistory } from "react-router-dom";
import { WebUrl, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import moment from "moment";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useSelector } from "react-redux";

const LoyaltyRedemptionHistory = (props) => {
  var _history = useHistory();
  var { t } = useTranslation(LanguageKey._PRIMARY);
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [redeemUrl, setRedeemUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const selectedTheme = useSelector(state => state.authState.userData.selectedTheme);

  useEffect(() => {
    getRedemptionRecord(startDate, endDate, selectedDate);
  }, [])

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getRedemptionRecord(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getRedemptionRecord(start, end, selected = "") {
    console.log(selected);
    let apiUrl =
      ApiUrl._API_GET_LOYALTY_REDEMPTION_HISTORY +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&v=" +
      Date.now();
    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setRedeemUrl(apiUrl);
  }

  return (
    <>
      <Header
        history={_history}
        url={!window.ReactNativeWebView ? WebUrl._URL_LOYALTY_REDEMPTION : null}
        title={t("REDEMPTIONS_RECORD")}
      />
      <div className="container" style={{ marginBottom: "10px", padding: "0" }}>
        <div className={"date-filter-title" + (selectedTheme === 1 ? " modern" : "")}>{t("REDEEMED_DATE")}</div>
        <DateRangeFilter2
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSave={(start, end) => {
            getRedemptionRecord(start, end)
            setSelectedDate("");
          }}
          options={true}
        />
        <div className="card card-package">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table reportTable">
                <thead>
                  <tr>
                    <th>{t("ITEM_NAME")}</th>
                    <th>{t("POINTS_NEEDED")}</th>
                    <th>{t("POINTS_BEFORE_REDEEM")}</th>
                    <th>{t("POINTS_AFTER_REDEEM")}</th>
                    <th>{t("REDEEM_DATE")}</th>
                    <th>{t("RECEIVE")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  </tr>
                  {<DynamicLoadTable pageSize={20} apiQuery={redeemUrl} searchEnabled={true} isTable={true} render={(item, i) => {
                    return (<tr key={i}>
                      <td>{item["itemName"]}</td>
                      <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["pointsNeeded"]), 2)}</td>
                      <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["pointsBeforeRedeem"]), 2)}</td>
                      <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["pointsAfterRedeem"]), 2)}</td>
                      <td>{moment(item["redeemTime"]).format("YYYY-MM-DD HH:mm:ss")}</td>
                      {item["itemReceived"] == true ?
                        <td>
                          <span style={{ color: "#03BB3A" }}>{t("RECEIVED")}</span>
                        </td>
                        :
                        <td></td>
                      }
                    </tr>)
                  }} />}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoyaltyRedemptionHistory;
