import React, { useEffect, useState } from 'react';
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";

const SummaryDownlineSales = props => {
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();
    var _history = useHistory();

    const _SELECTED_MEMBER_KEY = "selectedMemberKey";

    const [selectedMember, setSelectedMember] = useState("");
    const [nodePathMembers, setNodePathMembers] = useState([]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    const tempUsername = params.get('username');
    const hideSummary = params.get('GAzobE73Vse4nr8WpufA');

    const [allData, setAllData] = useState({});
    const [memberDepthData, setMemberDepthData] = useState({});

    const [processedData, setProcessedData] = useState({});
    const [processedUserTotal, setProcessedUserTotal] = useState({});
    const [processedCategoryTotal, setProcessedCategoryTotal] = useState({});
    const [processedSubTotal, setProcessedSubTotal] = useState({});
    const [processedGrandTotal, setProcessedGrandTotal] = useState({});
    const [processedUsernameRows, setProcessedUsernameRows] = useState({});
    const [currentUsername, setCurrentUsername] = useState(tempUsername);
    const [nodePathData, setNodePathData] = useState([]);

    var _username = useSelector(
        (state) => state["authState"]["userData"]["username"]
    );

    const settlementToggle = (username) => {
        let tempObj = processedUserTotal;
        tempObj[username].showset = !tempObj[username].showset;
        setProcessedUserTotal({ ...tempObj });
    }

    const settlementCatToggle = (username, category) => {
        let tempObj = processedCategoryTotal;
        tempObj[username][category].showset = !tempObj[username][category].showset;
        setProcessedCategoryTotal({ ...tempObj });
    }

    const usernameToggle = (username) => {
        let tempObj = processedUserTotal;
        tempObj[username].show = !tempObj[username].show;
        setProcessedUserTotal({ ...tempObj });
    }
    const categoryToggle = (username, category) => {
        let tempObj = processedCategoryTotal;
        tempObj[username][category].show = !tempObj[username][category].show;
        setProcessedCategoryTotal({ ...tempObj });
    }

    useEffect(() => {
        let nodePath = [];
        if (nodePathData.memberNodePath) {
            if (nodePathData.memberNodePath.length > 0) {
                nodePath = nodePathData.memberNodePath;
            }
        }

        setNodePathMembers(nodePath);
    }, [allData]);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        getDownlineSales(tempUsername);
    }, []);

    async function getDownlineSales(username) {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${startDate}&enddate=${endDate}&username=${username}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];
            setAllData(data);
            let memberData = responseJson[ApiKey._API_DATA_KEY]["depthData"];
            setMemberDepthData(memberData);
            setNodePathData(responseJson[ApiKey._API_DATA_KEY]["nodePathData"]);
            setNodePathMembers(responseJson[ApiKey._API_DATA_KEY]["nodePathData"]["memberNodePath"]);
            // setCurrentUsername(memberData["username"]);
            setCurrentUsername(username);

            let tempRows = {};
            let dataRows = {};
            let userTotal = {};
            let categoryTotal = {};
            let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
            let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };

            data["downlineData"].map((e, i) => {
                if (!dataRows[e.username]) {
                    dataRows[e.username] = {};
                    tempRows[e.username] = { memberGuid: e.memberId, hasDownline: e.usernameHasDownlineData };
                    userTotal[e.username] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                    categoryTotal[e.username] = {};
                }

                if (!dataRows[e.username][e.categoryName]) {
                    dataRows[e.username][e.categoryName] = {};
                    categoryTotal[e.username][e.categoryName] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                }

                if (!dataRows[e.username][e.categoryName][e.productName]) {
                    dataRows[e.username][e.categoryName][e.productName] = { grossSales: e.grossSales, validTurnover: e.validTurnover, netProfit: e.netProfit, profit: e.profit, settlement: e.settlement, share: e.share };
                }

                userTotal[e.username].show = false;
                userTotal[e.username].showset = false;
                userTotal[e.username].grossSales += e.grossSales;
                userTotal[e.username].validTurnover += e.validTurnover;
                userTotal[e.username].netProfit += e.netProfit;
                userTotal[e.username].profit += e.profit;
                userTotal[e.username].settlement += e.settlement;
                userTotal[e.username].share = e.share;


                categoryTotal[e.username][e.categoryName].show = false;
                categoryTotal[e.username][e.categoryName].showset = false;
                categoryTotal[e.username][e.categoryName].grossSales += e.grossSales;
                categoryTotal[e.username][e.categoryName].validTurnover += e.validTurnover;
                categoryTotal[e.username][e.categoryName].netProfit += e.netProfit;
                categoryTotal[e.username][e.categoryName].profit += e.profit;
                categoryTotal[e.username][e.categoryName].settlement += e.settlement;
                categoryTotal[e.username][e.categoryName].share = e.share;

                subTotal.sales += e.grossSales;
                subTotal.vsales += e.validTurnover;
                subTotal.netProfit += e.profit;

                grandTotal.sales += e.grossSales;
                grandTotal.vsales += e.validTurnover;
                grandTotal.netProfit += e.profit;
                grandTotal.netProfit += e.settlement;

            });
            setProcessedUserTotal(userTotal);
            setProcessedCategoryTotal(categoryTotal);
            setProcessedData(dataRows);
            setProcessedUsernameRows(tempRows);
            setProcessedSubTotal(subTotal);
            setProcessedGrandTotal(grandTotal);

        }
        else {
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        _dispatch(setIdle());
    }

    return (<>
        <Header history={_history} url={WebUrl._URL_SHAREHOLDER + "?GAzobE73Vse4nr8WpufA=" + hideSummary + "&startDate=" + startDate + "&endDate=" + endDate} title={t('SUMMARY_DOWNLINE')} />
        <div className='custom-tables'>
            <div>
                <div className='d-flex' style={{ marginBottom: "10px" }}>
                    <b>{t("HIERARCHY") + " : "}</b>
                    <div className="hierarchy-line" style={{ marginLeft: "5px" }}>
                        <a
                            href="javascript:;"
                            onClick={() => {
                                getDownlineSales(_username);
                                setSelectedMember("");
                                sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                            }}
                        >
                        {_username}
                        </a>
                        {nodePathMembers.map((m, index) => {
                            return (
                                <>
                                {index == 0 && ">"}
                                    <a
                                        href="javascript:;"
                                        onClick={() => getDownlineSales(m.username)}
                                    >
                                    {m.username}
                                    </a>
                                    {index != nodePathMembers.length - 1 && ">"}
                                </>
                            );
                        })}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
                    <div style={{ marginBottom: "10px" }}><b>{t("USERNAME") + " : "}</b>{currentUsername}</div>
                    <div><b>{t("DATE") + " : "}</b>{startDate + " " + t("TO") + " " + endDate}</div>
                </div>
            </div>
            <div className='hr-right mb-3 mt-3'></div>
            {Object.keys(processedData).length > 0 && <>
                <div className="shareholder-my-income">
                    <div className="card-wrapper"><div className="card my-income-card">
                        <div className="my-income-card-content">
                            <div className="content-title">{t("DIRECT_DOWNLINE")}</div>
                            <div className="content-value">{memberDepthData["totalDirectDownlines"]}</div>
                        </div>
                    </div></div>
                    <div className="card-wrapper"><div className="card my-income-card">
                        <div className="my-income-card-content">
                            <div className="content-title">{t("NET_PROFIT")}</div>
                            <div className={"content-value" + (processedGrandTotal.netProfit < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</div>
                        </div>
                    </div></div>
                    <div className="card-wrapper"><div className="card my-income-card">
                        <div className="my-income-card-content">
                            <div className="content-title">{t("VALID_TURNOVER")}</div>
                            <div className={"content-value" + (processedGrandTotal.vsales < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.vsales), 2, true)}</div>
                        </div>
                    </div></div>
                </div>
                <div className="card-wrapper"><div className="card card-package">
                    <div className="card-body p-0">
                        <div className="card-table table-responsive">
                            <table className="table table-kpi-summary">
                                <thead style={{ borderBottom: "2px solid" }}>
                                    <tr>
                                        <th style={{ textAlign: "left", paddingLeft: "20px" }}>{t("USERNAME")}</th>
                                        <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                        <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                        <th style={{ textAlign: "right" }}>{t("%")}</th>
                                        <th style={{ textAlign: "right", paddingRight: "20px" }}>{t("NET_PROFIT")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                            if (typeof (processedUserTotal[_username]) !== "undefined") {
                                                return (
                                                    <>
                                                        <tr key={"username_" + _username}>
                                                            <td className={"has-downline" + ((Object.keys(processedUsernameRows).length > 0 && processedUsernameRows[_username] && processedUsernameRows[_username]["hasDownline"] && _username !== currentUsername) ? "" : "-false")} style={{ textAlign: "left", paddingLeft: "20px" }} onClick={() => { if (Object.keys(processedUsernameRows).length > 0 && processedUsernameRows[_username] && processedUsernameRows[_username]["hasDownline"]) { getDownlineSales(_username); } }}>{_username}</td>
                                                            <td style={{ textAlign: "left" }} onClick={() => usernameToggle(_username)}>{t("ALL")} <i className={processedUserTotal[_username].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].validTurnover), 2, true)}</td>
                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true)}/130</td>
                                                            <td className={processedUserTotal[_username].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].profit), 2, true)}</td>
                                                        </tr>
                                                        {
                                                            processedUserTotal[_username].show && Object.keys(processedData[_username]).map((_category, j) => {
                                                                return (
                                                                    <>
                                                                        <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                                            <td style={{ textAlign: "left" }}></td>
                                                                            <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => categoryToggle(_username, _category)}>- {t(_category)} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].validTurnover), 2, true)}</td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                                            <td className={processedCategoryTotal[_username][_category].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].profit), 2, true)}</td>
                                                                        </tr>
                                                                        {
                                                                            processedCategoryTotal[_username][_category].show && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                                                            <td style={{ textAlign: "left" }}></td>
                                                                                            <td style={{ textAlign: "left" }} className="cat-prod">-- {_product}</td>
                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].validTurnover), 2, true)}</td>
                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                                                            <td className={processedData[_username][_category][_product].profit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].profit), 2, true)}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        })
                                    }

                                    <tr className="subtotal" key={"subtotal"}>
                                        <td style={{ textAlign: "left", paddingLeft: "20px" }} colSpan="2">{t("SUB_TOTAL")}</td>
                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.vsales), 2, true)}</td>
                                        <td style={{ textAlign: "right" }}></td>
                                        <td className={processedSubTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.netProfit), 2, true)}</td>
                                    </tr>

                                    {
                                        Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                            if (typeof (processedUserTotal[_username]) !== "undefined") {
                                                return (
                                                    <>
                                                        <tr key={"settlement_username_" + _username}>
                                                            <td style={{ textAlign: "left", paddingLeft: "20px" }}>{_username}</td>
                                                            <td style={{ textAlign: "left" }} colSpan="2" onClick={() => settlementToggle(_username)}>{t("SETTLEMENT")} <i className={processedUserTotal[_username].showset ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true)}/130</td>
                                                            <td className={processedUserTotal[_username].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].settlement), 2, true)}</td>
                                                        </tr>
                                                        {
                                                            processedUserTotal[_username].showset && Object.keys(processedData[_username]).map((_category, j) => {
                                                                return (
                                                                    <>
                                                                        <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                                            <td style={{ textAlign: "left" }}></td>
                                                                            <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => settlementCatToggle(_username, _category)}>-{t(_category)} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                            <td style={{ textAlign: "right" }}></td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                                            <td className={processedCategoryTotal[_username][_category].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].settlement), 2, true)}</td>
                                                                        </tr>
                                                                        {
                                                                            processedCategoryTotal[_username][_category].showset && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                                                            <td style={{ textAlign: "left" }}></td>
                                                                                            <td style={{ textAlign: "left" }} className="cat-prod">--{_product}</td>
                                                                                            <td style={{ textAlign: "right" }}></td>
                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                                                            <td className={processedData[_username][_category][_product].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].settlement), 2, true)}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        })
                                    }

                                    <tr className="grandtotal" key={"grandtotal"}>
                                        <td style={{ textAlign: "left", paddingLeft: "20px" }} colSpan="2">{t("GRAND_TOTAL")}</td>
                                        <td style={{ textAlign: "right" }}></td>
                                        <td style={{ textAlign: "right" }}></td>
                                        <td className={processedGrandTotal.netProfit < 0 && "text-red"} style={{ textAlign: "right", paddingRight: "20px" }}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 2, true)}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div></div>
            </>
            }</div>
    </>)
}

export default SummaryDownlineSales;